function MyvercoController(){
	this.className			= "MyvercoController";
	this.view				= false;
	this.glob				= ["/myverco**", "/myverco/?**"];
	// this.glob			= ["/", "/?**"];
	this.viewData			= {};
}
// MyvercoController.inheritsFrom( PageController );
MyvercoController.inheritsFrom( StandardController );
MyvercoController.prototype.swap = function(onComplete){
	this.loadStyles();

	this.$body				= $('body');
	this.$page				= this.$body.find('.js-page');
	this.$header			= this.$body.find('.js-page-header');
	this.$sections			= this.$page.find('.js-page-section');
	this.$bgs				= this.$page.find('.js-bg');
	this.$wrapText 			= this.$page.find('.js-wrap-with-spans');

	this.$wrapText.each((i, el) => {
		wrapTextWithSpans($(el));
	});

	const sections = [];
	this.$bgs.each((index, element) =>{
		const s = {};
		s.$section = $(element);

		const rgb = Igloo.getRGB(s.$section.css("background-color"));
		const hex = Igloo.RGB2hex(rgb[0], rgb[1], rgb[2]);

		const matches = s.$section.attr("class").match(/(bg--[a-z-]+)/);
		s.bg = matches ? matches[1] : "bg--purple";
		s.color = hex;
		s.id = s.$section.attr("id");

		sections.push(s);
	});
	console.log(sections);
	this.sections = sections;

	gsap.registerPlugin(ScrollTrigger);


	this.measureSections();
	this.setupFooterAnimation();
	this.checkScroll(true);

	this.resizeDelegate = jstage.addListener("resize", (e) => this.onResize(e));
	this.scrollDelegate = jstage.addListener("scroll", (e) => this.checkScroll());
	this.baseDelegate = Aviator.get("baseController").addListener("resizedHeader",() => this.onResize());

	return true;
}

MyvercoController.prototype.setupFooterAnimation = function(){
	if(this.footerAnimation) return;
	const footerElement = document.querySelector('.js-page-footer');
	const shapesElement = document.querySelector('.js-page-footer--shapes');
	if (footerElement === null || shapesElement === null) return;

	console.log("Setup footer animation");

	if(this.matterAnimation) {
		this.matterAnimation.destroy();
		this.matterAnimation = false;
	}

	this.matterAnimation = animateMatter({
		element: shapesElement,
		settings: {
			friction: 100,
			frictionStatic: 100,
			restitution: 0.9,
			wallThickness: 100,
		},
		objects: [
			{ url: "/assets/images/myverco/shapes/shape_15.svg", color: '#FF6478' },
			{ url: "/assets/images/myverco/shapes/shape_13.svg", color: '#FF8500' },
			{ url: "/assets/images/myverco/shapes/shape_14.svg", color: '#FFE632' },
			{ url: "/assets/images/myverco/shapes/shape_12.svg", color: '#FFE632' },
			{ url: "/assets/images/myverco/shapes/shape_10.svg", color: '#14FFC8' },
			{ url: "/assets/images/myverco/shapes/shape_11.svg", color: '#8CFF50' },
		],
		colors: ['#14FFC8', '#8CFF50', '#FFE632', '#FF8500', '#FF6478', '#FFE632' ]
	});

	if(this.footerAnimation) this.footerAnimation.kill();

	this.footerAnimation = gsap.timeline({
		scrollTrigger: {
			trigger: '.js-page-footer',
			start: "top 70%",
			end: "top top",
			scrub: true,
			markers: false,
			toggleActions: "play restart play reverse",

			onEnter: (() => {
				console.log("Play animation");
				this.triggered = true;

				if (this.matterAnimation) {
					this.matterAnimation.start();
				}
			}),
			onLeaveBack: (() => {
				console.log("Left animation");
				if (this.matterAnimation) {
					this.matterAnimation.stop();
				}
			}),
		}
	});
}
MyvercoController.prototype.measureSections = function(){
	const headerHeight = this.$header.height();
	this.headerHeight = headerHeight;

	for (let i = 0; i < this.sections.length; i++) {
		const section = this.sections[i];
		const $section = section.$section;
		section.position = $section.position();
		section.top = section.position.top - headerHeight;
	}
}
MyvercoController.prototype.checkScroll = function(instant){
	instant = (instant === true);

	const s = jstage.getScroll();
	const h = jstage.getHeight();

	let best = false;
	for (let i = 0; i < this.sections.length; i++) {
		const section = this.sections[i];
		if(section.top <= s){
			best = section;
		}
	}
	if(!best) best = this.sections[0]

	if(instant) this.$header.addClass("instant");

	if(this.currentBg) this.$header.removeClass(this.currentBg);

	this.currentBg = best?.bg;
	if(this.currentBg) this.$header.addClass(this.currentBg);

	if(this.currentBest !== best){
		this.currentBest = best;
	}

	if(instant) setTimeout((() => { this.$header.removeClass("instant"); }), 1);
}
MyvercoController.prototype.onResize = function(e){
	this.$wrapText.each((i, el) => {
		wrapTextWithSpans($(el));
	});
	this.measureSections();
	this.setupFooterAnimation();
	this.checkScroll();
	// console.log("Reszing", e);
}
MyvercoController.prototype.loadStyles = function(){
	if(!document.getElementById('myverco-css')){
		const tag = document.createElement('link');
		tag.id = "myverco-css";
		tag.rel = "stylesheet";
		tag.href = this.getMeta().myvercoStylesheet;
		const vercoStylesheet = document.getElementById('vercoStylesheet');
		const nextElement = vercoStylesheet.nextSibling;
		if(nextElement) {
			document.head.insertBefore(tag, nextElement);
		} else {
			document.head.appendChild(tag);
		}
	}
}
MyvercoController.prototype.removeStyles = function(){
	const stylesheet = document.getElementById('myverco-css')
	if(stylesheet !== null) stylesheet.remove();
}
MyvercoController.prototype.off = function(){
	if(this.resizeDelegate) jstage.removeListener("resize",this.resizeDelegate);
	if(this.scrollDelegate) jstage.removeListener("scroll",this.scrollDelegate);
	if(this.baseDelegate) Aviator.get("baseController").removeListener("resizedHeader",this.baseDelegate);

	this.footerAnimation = false;
	if(this.matterAnimation) this.matterAnimation.destroy();
	ScrollTrigger.killAll();

	const state = nav.getState();
	if(state.nextParts[0] !== "myverco") this.removeStyles();

}