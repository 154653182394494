function CookiesController(){
    this.className	                    = "CookiesController";
    this.glob				            = "/**";
    this.cookieName                     = 'cookiesAccepted';
    this.analyticsScriptsLoaded         = false;
    this.debug                          = false;
};

CookiesController.inheritsFrom( StandardController );

CookiesController.prototype.swap = function(){
    if (this.isSetup) {
        if (this.cookiesAccepted()) {
            if(!this.analyticsScriptsLoaded) this.loadAnalyticsScripts();
        }
    } else {
        this.setup();
        if (this.cookiesAccepted()) {
            if(!this.analyticsScriptsLoaded) this.loadAnalyticsScripts();
        } else {
            this.showCookiesPopUp();
        }
    }
};

CookiesController.prototype.setup = function(state){

    if(this.isSetup) return false;

    this.$cookiesPopUp              = $('.cookies');
    this.$acceptButton 				= this.$cookiesPopUp.find(".js-accept-cookies-button");
    this.$declineButton 			= this.$cookiesPopUp.find(".js-decline-cookies-button");
    this.$closeButton               = this.$cookiesPopUp.find('.js-close-button');

    this.$acceptButton.on('click touchstart', () => this.acceptCookies())
    this.$declineButton.on('click touchstart', () => this.declineCookies());
    this.$closeButton.on('click touchstart', () => this.declineCookies());

    // Presetup Google Analytics anyway
    window.dataLayer = window.dataLayer || [];
    window.gtag = function(){dataLayer.push(arguments);}

	this.isSetup = true;
}

CookiesController.prototype.cookiesAccepted = function() {
    return this.getCookie(this.cookieName);
}

CookiesController.prototype.showCookiesPopUp = function() {
    this.$cookiesPopUp.show();
}

CookiesController.prototype.acceptCookies = function() {
    this.setCookie(this.cookieName, 1, 365);
    this.loadAnalyticsScripts();
    this.$cookiesPopUp.hide();
}

CookiesController.prototype.setCookie = function(cookieName, cookieValue, cookieExpirationDays) {
    const date = new Date();
    date.setTime( date.getTime() + (cookieExpirationDays * 24 * 60 * 60 * 1000) );
    const expires = "expires="+ date.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

CookiesController.prototype.getCookie = function(cookieName) {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookiesArray = decodedCookie.split(';');
    for(var i = 0; i <cookiesArray.length; i++) {
      var cookie = cookiesArray[i];
      while (cookie.charAt(0) == ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) == 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return "";
}

CookiesController.prototype.declineCookies = function() {
    this.$cookiesPopUp.hide();
}

/**
 * Google Analytics
 * The function below needs to be public.
 * It throws errors and doesn't work when added to CookiesController
 * To be investigated later when we have time.
 */
function runGoogleAnalytics() {
    const pageSlug = nav.getState().nextUrl;

    // Google Analytics: Track page view
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

    ga('create', 	googleAnalyticsId, 'auto');
    ga('send', 		'pageview');
    if(this.debug) console.log('%c[Google Analytics] send pageview', 'background: yellow', pageSlug)


    if(googleAnalytics4Id){

        const s = document.createElement( 'script' );
        s.async = 1;
        s.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalytics4Id}`;
        document.body.appendChild( s );

        gtag('js', new Date());
        gtag('config', googleAnalytics4Id);

    }


}

/**
 * HubSpot Analytics
 */
function runHubspotAnalytics() {
    const pageSlug = nav.getState().nextUrl;

    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', pageSlug]);

    // Load the HubSpot tracking code
    if (typeof hubspotAnalyticsId !== 'undefined' && hubspotAnalyticsId) {
        var s = document.createElement('script');
        s.setAttribute('type', 'text/javascript');
        s.setAttribute('id', 'hs-script-loader');
        s.setAttribute('async', '');
        s.setAttribute('defer', '');
        s.setAttribute('src', `//js.hs-scripts.com/${hubspotAnalyticsId}.js`);
        document.body.appendChild(s);
    }

    // _hsq.push(['trackPageView']);

    if(this.debug) console.log('%c[HubSpot Analytics] send pageview', 'background: yellow', pageSlug)
}
CookiesController.prototype.loadAnalyticsScripts = function() {
    runGoogleAnalytics();
    runHubspotAnalytics();
    this.analyticsScriptsLoaded = true;
}
/*
CookiesController.prototype.sendAnalytics = function() {
    this.sendGoogleAnalytics();
    this.sendHubspotAnalytics();
}
*/