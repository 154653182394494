function ClientsController(){
	this.className	= "ClientsController";
	this.glob		= ["/clients/","/clients/?*","/clients/**"];
};
ClientsController.inheritsFrom( StandardController );
ClientsController.prototype.swap = function(){

	var state = nav.getState();

	// this.$pageHeader = $('.js-page-header');
	this.$serviceClient = $('.js-service-client');
	this.$serviceClientLink = this.$serviceClient.find('.js-service-link');
	this.$clientReadMore = this.$serviceClient.find('.js-read-more');
	this.$popupOverlay = $(".js-page-popup");

	// Keep service open and scroll to
	if(state.nextParts[1] && this.$serviceClient.length) {
		var service = state.nextParts[1];
		var $activeService = this.$serviceClient.filter(`[data-service="${service}"]`);
		this.openServiceDropdown($activeService);
		var scrollDiv = document.getElementById(service).offsetTop - 100;
		window.scrollTo({ top: scrollDiv, behaviour: 'instant' });
	}


	/**
	 * Show popup if client is present in url
	 *
	if(state.nextParts[1] && state.nextParts[1] !== "popup") {
		var name = state.nextParts[1];
		var url = "/clients/" + name + "/";

		var caseStudyPopup = new ClientPopup({
			$overlay: this.$popupOverlay,
			name: name,
			analytics: {
				category: 'Case Study',
				label: url,
			}
		});
	}
	*/

	/*
	 * Read more popup
	 */
	/*
	this.$clientReadMore.on("click", Igloo.$d(this,function($this){

		var caseStudyPopup = new ClientPopup({
			$overlay: this.$popupOverlay,
			name: $this.attr("data-name"),
			analytics: {
				category: 'Case Study',
				label: $this.attr("href"),
			}
		});

	}));
	*/


	/*
	 * Reveal service menu clients.
	 */
	this.$serviceClientLink.on("click", Igloo.$d(this,function($this){
		var $service = $this.parents('.js-service-client');
		this.openServiceDropdown($service);
	}))

};

ClientsController.prototype.openServiceDropdown = function($service) {
	var $open = this.$serviceClient.filter(".open");
	var isOpen = $service.hasClass("open");
	var $dropdown = $service.find(".js-reveal");

	var d = 500;

	if($open.size()){
		var $openDropdown = $open.find(".js-reveal");
		var o1 = $openDropdown.height();
		$open.removeClass("open");
		$openDropdown.css("height","");
		var o2 = $openDropdown.height();
		$openDropdown.height(o1).animate({height:o2},{duration:d,complete:function(){ $(this).css("height","");}});
	}

	if(!isOpen){
		$service.addClass("open");
		var h1 = $dropdown.height();
		$dropdown.css("height","auto");
		var h2 = $dropdown.height();
		$dropdown.height(h1).animate({height:h2},{duration:d,complete:function(){ /*$(this).css("height","");*/ }});
	}
}

/*
 * Copy text to clipboard with fallback.
 */
ClientsController.prototype.copyTextToClipboard = function(text){
	if (!navigator.clipboard) {
		this.fallbackCopyTextToClipboard(text);
		return;
	}

	navigator.clipboard.writeText(text).then(function() {
		console.log('Async: Copying to clipboard was successful!');
	}, function(err) {
		console.error('Async: Could not copy text: ', err);
	});
}
ClientsController.prototype.fallbackCopyTextToClipboard = function(text){
	var textArea = document.createElement("textarea");
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = "0";
	textArea.style.left = "0";
	textArea.style.position = "fixed";

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		var successful = document.execCommand('copy');
		var msg = successful ? 'successful' : 'unsuccessful';
		console.log('Fallback: Copying text command was ' + msg);
	} catch (err) {
		console.error('Fallback: Oops, unable to copy', err);
	}

	document.body.removeChild(textArea);
}