const animateMatter = (options) => {
    options.element = options?.element || document.body;
    options.width = options?.width || options?.element.clientWidth || 800;
    options.height = options?.height || options?.element.clientHeight || 600;
    options.objects = options?.objects || [];
    options.settings = options?.settings || {};
    options.colors = options?.colors || [];
    options.wallOptions = options?.wallOptions || {};

    const friction = 1;
    const frictionStatic = 10;
    const restitution = 0.9;
    const wallThickness = options.settings?.wallThickness || 1;

    const colors = options.colors;

    let loadedSvgs = false;

    const objectSettings = {
        friction: options.settings?.friction || friction,
        frictionStatic: options.settings?.frictionStatic || frictionStatic,
        restitution: options.settings?.restitution || restitution,
    }

    const {
        Engine,
        Render,
        Runner,
        World,
        Bodies,
        Common,
        Mouse,
        MouseConstraint,
        Svg,
        Bounds,
    } = Matter;

    const engine = Engine.create();
    const world = engine.world;

    // const render = false;
    // const runner = false

    const render = Render.create({
        element: options.element,
        engine: engine,
        options: {
            width: options.width,
            height: options.height,
            showAngleIndicator: false,
            wireframes: false,
            background: 'transparent',
        }
    });

    const runner = Runner.create();

    const select = (root, selector) => {
        return Array.prototype.slice.call(root.querySelectorAll(selector));
    };

    const loadSvg = (url) => {
        return fetch(url)
            .then(function(response) { return response.text(); })
            .then(function(raw) {
                return (new window.DOMParser()).parseFromString(raw, 'image/svg+xml');
            });
    };

    const scaleVertices = (vertices, scaleFactor) => {
        return vertices.map(vertex => ({
            x: vertex.x * scaleFactor,
            y: vertex.y * scaleFactor
        }));
    };

    options.objects.map((object) => {
        const url = object?.url;
        loadSvg(url).then(function(root) {
            object.vertexSet = select(root, 'path').map(function(path) {
                return Svg.pathToVertices(path, 5);
            });
        });
    });

    // Function to load all SVGs
    const loadAllSvgObjects = () => {
        if(loadedSvgs) return Promise.resolve(); // Return a resolved promise if SVGs are already loaded

        // Return an array of promises for loading each SVG
        const svgLoadPromises = options.objects.map((object) => {
            const url = object?.url;
            return loadSvg(url).then(function (root) {
                object.vertexSet = select(root, 'path').map(function (path) {
                    return Svg.pathToVertices(path, 5);
                });
            });
        });
        return Promise.all(svgLoadPromises); // Return a promise that resolves when all SVGs are loaded
    };

    const renderCanvas = () => {
        stop();

        const width = options?.element.clientWidth || 800;
        const height = options?.element.clientHeight || 600;
        const objects = options?.objects || [];

        render.options.width = width;
        render.options.height = height;
        Render.setSize(render, width, height);

        let startY = 0;
        let availableColors = [...colors];
        objects.forEach(({ vertexSet, color = false }, index) => {
            let colorIndex = Math.floor(Math.random() * availableColors.length);
            if (color) colorIndex = availableColors.findIndex(c => c === color);
            color = availableColors[colorIndex];

            // Remove the chosen option using splice
            availableColors.splice(colorIndex, 1); // Removes 1 element at the random index

            const scaleFactor = ((width / 2) / 100) * 0.16;

            const bounds = Bounds.create(vertexSet.flat());
            const shapeWidth = bounds.max.x - bounds.min.x;
            const shapeHeight = bounds.max.y - bounds.min.y;
            // Calculate scaling factor based on canvas height and desired height factor
            // let scaleFactor = (width * shapeHeightFactor) / shapeHeight;

            // Apply scaling to each set of vertices
            const scaledVertexSet = vertexSet.map(vertices => scaleVertices(vertices, scaleFactor));

            const startX = width - shapeWidth * scaleFactor / 2 - Common.random(0, (shapeWidth * scaleFactor)); //(width * 3.2 / 4) + Common.random(0, (width / 4));
            startY = (startY - shapeHeight * scaleFactor);

            World.add(world, Bodies.fromVertices(startX , startY, scaledVertexSet, {
                render: {
                    fillStyle: color,
                    strokeStyle: color,
                    lineWidth: 0,
                    ...objectSettings
                }
            }, true));
        });

        //World.add(world, polygons);
        const wallHeight = (startY * -1) + 1000;
        const wallWidth = width;

        const leftWallX = wallThickness / 2 * -1;
        const leftWallY = height - wallHeight / 2;

        const rightWallX = wallWidth + wallThickness / 2;
        const rightWallY = height - wallHeight / 2;

        const topWallX = wallWidth / 2;
        const topWallY = height - wallHeight;

        const bottomWallX = wallWidth / 2;
        const bottomWallY = height + wallThickness / 2;

        const wallOptions = { isStatic: true, render: { fillStyle: 'red' }}

        World.add(world, [
            Bodies.rectangle(topWallX, topWallY, wallWidth, wallThickness, wallOptions), // Top wall
            Bodies.rectangle(bottomWallX, bottomWallY, wallWidth, wallThickness, { ...wallOptions, ...objectSettings }), // Bottom wall
            Bodies.rectangle(leftWallX, leftWallY, wallThickness, wallHeight, wallOptions), // Left wall
            Bodies.rectangle(rightWallX, rightWallY, wallThickness, wallHeight, wallOptions), // Right wall
        ]);

        // body.render.sprite.xScale = box.render.sprite.xScale * scaleX;
        // body.render.sprite.yScale = box.render.sprite.yScale * scaleY;
        //Body.scale( body, scaleX, scaleY);

        const mouse = Mouse.create(render.canvas);
        const mouseConstraint = MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.2,
                render: {
                    visible: false
                }
            }
        });
        mouseConstraint.mouse.element.removeEventListener("wheel", mouseConstraint.mouse.mousewheel);
        World.add(world, mouseConstraint);
        render.mouse = mouse;
    }

    const start = () => {
        // Load all SVGs first, then run renderCanvas
        loadAllSvgObjects().then(() => {
            loadedSvgs = true;
            console.log("trigger renderCanvas");
            renderCanvas(); // Run only when SVGs are loaded
            Render.run(render);
            Runner.run(runner, engine);
        }).catch(error => {
            console.error("Error loading SVGs: ", error);
        });
    }

    const stop = () => {
        Render.stop(render);
        Runner.stop(runner);
        World.clear(world, false); // Remove all bodies and constraints without freeing the engine
        Engine.clear(engine); // Clear the engine state
    }

    const destroy = () => {
        Render.stop(render);
        Runner.stop(runner);
        World.clear(world, true); // Remove all bodies and constraints without freeing the engine
        Engine.clear(engine); // Clear the engine state
        if(render?.canvas) render.canvas.remove();
        render.canvas = null;
        render.context = null;
        render.textures = {};
    }

    return {
        start: () => start(),
        stop: () => stop(),
        destroy: () => destroy()
    };
};
