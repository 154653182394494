function MyvercoModulesController(){
	this.className			= "MyvercoModulesController";
	this.view				= false;
	this.glob				= ["/myverco/modules/**", "/myverco/modules/?**"];
	// this.glob			= ["/", "/?**"];
	this.viewData			= {};
}
// MyvercoModulesController.inheritsFrom( PageController );
MyvercoModulesController.inheritsFrom( StandardController );

MyvercoModulesController.prototype.swap = function(onComplete){
	this.$body				= $('body');
	this.$page				= this.$body.find('.js-page');

	this.setupAnimations();
	this.setupFeatureInfo();

	return true;
}

MyvercoModulesController.prototype.setupFeatureInfo = function(){
	const $featureInfo = this.$page.find('.js-feature-info');
	const $featureLinks = $featureInfo.find('.nav__link');
	const $featureVector = this.$page.find('.js-feature-vector svg');
	const $featureContent = this.$page.find('.js-feature-content');

	const sections = [
		'all',
		'.myprojects path, .mypathways path, .path-1',
		'.myprojects path, .mypathways path, .path-1',
		'.myprojects path',
		'.myperformance path',
	];

	$featureLinks.on('click', (e) => {
		e.preventDefault();
		const $target = $(e.currentTarget);
		const index = $target.attr('data-index');

		$featureLinks.removeClass('active');
		$target.addClass('active');

		$featureContent.removeClass('active');
		$featureContent.eq(index).addClass('active');

		let selector = sections[index]; // sections.map((item) => sections[item]);
		if(selector === 'all') selector = "path";
		$featureVector.find(`path:not(${selector})`).css({fill: "#80A7AC"});
		$featureVector.find(selector).css('fill', '');
	});
}

MyvercoModulesController.prototype.setupAnimations = function(){
	const sectionContainers = ["#mypathways", "#myproject", "#myperformance"];
	sectionContainers.forEach((container, index) => {
		const mod = index % 2;
		const x = mod === 0 ? 50 : -50;

		const selector = container  + ' .js-feature-item .js-image--feature';
		new AnimationController({
			containerSelector: selector,
			triggerStart: 'top 80%',
			triggerEnd: 'top top',
			showMarkers: false,
			once: true,
			onScroll: false,
			scrub: true,
			items: [{
				selector: selector,
				start: { x: x, opacity: 0 },
				end: { x: 0, opacity: 1 },
				ease: 'power4.out',
				duration: 1
			}]
		});

		new AnimationController({
			containerSelector: container + ' .js-feature2-item',
			triggerStart: 'top 80%',
			triggerEnd: 'top 50%',
			showMarkers: false,
			once: true,
			onScroll: false,
			scrub: true,
			items: [{
				selector: container  + ' .js-feature2-item',
				start: { opacity: 0 },
				end: { opacity: 1 },
				options: {
					duration: 1,
					stagger: 0.25
				}
			}]
		});
	});

	new AnimationController({
		containerSelector: '#mypathways',
		triggerStart: 'top 50%',
		triggerEnd: 'top top',
		showMarkers: false,
		once: true,
		onScroll: false,
		items: [{
			selector: '#mypathways .js-image--shape path:nth-child(1)',
			start: { x: -1.5, y: 1.5 },
			end: { x: 0, y: 0 },
		},{
			selector: '#mypathways .js-image--shape path:nth-child(2)',
			start: { x: -1.5, y: -1.5 },
			end: { x: 0, y: 0 },
		},{
			selector: '#mypathways .js-image--shape path:nth-child(3)',
			start: { x: 1.5, y: -1.5 },
			end: { x: 0, y: 0 },
		},{
			selector: '#mypathways .js-image--shape path:nth-child(4)',
			start: { x: 1.5, y: 1.5 },
			end: { x: 0, y: 0 },
		}],
	});

	new AnimationController({
		containerSelector: '#myproject',
		triggerStart: 'top 50%',
		triggerEnd: 'top top',
		showMarkers: false,
		once: true,
		onScroll: false,
		items: [{
			selector: '#myproject .js-image--shape path:nth-child(1)',
			start: { x: -1.5, y: -1.5 },
			end: { x: 0, y: 0 },
		},{
			selector: '#myproject .js-image--shape path:nth-child(2)',
			start: { x: -1.5, y: 1.5 },
			end: { x: 0, y: 0 },
		},{
			selector: '#myproject .js-image--shape path:nth-child(3)',
			start: { x: 1.5, y: 1.5 },
			end: { x: 0, y: 0 },
		},{
			selector: '#myproject .js-image--shape path:nth-child(4)',
			start: { x: 1.5, y: -1.5 },
			end: { x: 0, y: 0 },
		}],
	});


	new AnimationController({
		containerSelector: '#myperformance',
		triggerStart: 'top 50%',
		triggerEnd: 'top top',
		showMarkers: false,
		once: true,
		onScroll: false,
		items: [{
			selector: '#myperformance .js-image--shape path:nth-child(1)',
			start: { x: -1.5, y: -1.5 },
			end: { x: 0, y: 0 },
		},{
			selector: '#myperformance .js-image--shape path:nth-child(2)',
			start: { x: -1.5, y: 1.5 },
			end: { x: 0, y: 0 },
		},{
			selector: '#myperformance .js-image--shape path:nth-child(3)',
			start: { x: 1.5, y: 1.5 },
			end: { x: 0, y: 0 },
		},{
			selector: '#myperformance .js-image--shape path:nth-child(4)',
			start: { x: 1.5, y: -1.5 },
			end: { x: 0, y: 0 },
		}],
	});
}

MyvercoModulesController.prototype.off = function(){
	return true;
}