// JavaScript Document


function StandardController(){
	this.className	= "StandardController";
	this.state		= null;
	this.handler	= null;
	this.debug		= false;
	this.info		= null;
	this.glob		= false;

	this.priority	= 500; //Default

	this.preloadsLookup = true;

	if(!StandardController.registeredClasses) StandardController.registeredClasses = [];
}
StandardController.abstract = true;
StandardController.inheritsFrom( BaseClass );

StandardController.register = function( c ){
	StandardController.registeredClasses.push( c );
}
StandardController.getRegister = function( ){
	return StandardController.registeredClasses;
}

/*** STANDARDS ***/
StandardController.prototype.on = function( onComplete ){
	if(this.debug) Igloo.log(this.className + ", on: Using standard function")
	//this.setState(true);
	return true;
}
StandardController.prototype.off = function( onComplete ){
	if(this.debug) Igloo.log(this.className + ", off: Using standard function")
	//this.setState(true);
	return true;
}
StandardController.prototype.swap = function( onComplete ){
	if(this.debug) Igloo.log(this.className + ", swap: Using standard function")
	//this.setState(true);
	return true;
}
StandardController.prototype.swapped = function( onComplete ){
	if(this.debug) Igloo.log(this.className + ", swapped: Using standard function")
	//this.setState(true);
	return true;
}
StandardController.prototype.finished = function( onComplete ){
	if(this.debug) Igloo.log(this.className + ", finished: Using standard function")
	//this.setState(true);
	return true;
}
/*** REQUESTS / ASKING ****/
StandardController.prototype.getRequests = function(){

	if(this.abstract || (!this.ask && !this.glob)) return {};

	var requestsPre = ["precheck"];
	var requestsOn = ["on","swap","swapped","main","finished"];
	var requestsOff = ["off"];

	var requests = requestsOn.concat(requestsOff).concat(requestsPre);

	var foundGlobMatches = false;
	this.globMatches = [];
	var ask = {};

	if(this.glob){
		var state = nav.getState();
		if(typeof(this.glob) == "string") this.glob = [this.glob];
		if(typeof(this.globNot) == "string") this.globNot = [this.globNot];


		var foundNextNot = false, foundPrevNot = false;
		if(this.globNot) for(var i = 0, ii = this.globNot.length; i < ii; i++){

			var regex		= Igloo.glob2regex(this.globNot[i]);
			var resultNext  = Igloo.regexCached(state.nextUrl,regex);
			var resultPrev  = Igloo.regexCached(state.previousUrl,regex);

			if(resultNext) foundNextNot = true;
			if(resultPrev) foundPrevNot = true;
		}


		var foundNext = false, foundPrev = false;
		for(var i = 0, ii = this.glob.length; i < ii; i++){

			var regex	= Igloo.glob2regex(this.glob[i]);
			var resultNext  = Igloo.regexCached(state.nextUrl,regex);
			var resultPrev  = Igloo.regexCached(state.previousUrl,regex);
			this.globMatches[i] = {index:i, glob: this.glob[i], next: !!resultNext, prev: !!resultPrev, both: resultNext&&resultPrev};

			if(resultNext && !foundNextNot) foundNext = true;
			if(resultPrev && !foundPrevNot) foundPrev = true;
		}

		ask = jQuery.extend(ask,this.standardRequests(requestsPre));

		if(foundNext) ask = jQuery.extend(ask,this.standardRequests(requestsOn));
		if(foundPrev) ask = jQuery.extend(ask,this.standardRequests(requestsOff));
		if(foundNext && (foundPrev || (state.initial && this.glob[0] == "/**" )) && this.globMain){
			ask.main = this.mainPriority ? {priority:this.mainPriority} : true;
		}

	}else{
		if(this.ask){
			ask = this.ask();
		}else{
			ask = {};
		}
	}


	for(var i in requests){
		this.completeRequest(ask, requests[i]);
	}
	//Igloo.log(this.className)
	//Igloo.log(ask);

	this.info = ask;

	return this.info;
}
StandardController.prototype.standardRequests = function(requests){
	var priority = this.priority;

	var r = {};
	for(var i in requests){
		if(this[requests[i]] || this[requests[i]+"Main"]){
			r[requests[i]] = true;
		}
	}
	return r;
}
StandardController.prototype.completeRequest = function(request, type){

	if(!request) request = {};

	var r = request[type];

	if(Igloo.defined(r)){
		if(r === false){
			delete request[type];
			return request;
		}

		//Requests should be an array.
		if(!Igloo.isArray(r)) r = [r];

		for(var i = 0; i < r.length; i++) {

			var ri = r[i];
			if (ri === true) ri = {priority: this.priority};
			else {
				if (ri && !Igloo.defined(ri.priority)) ri.priority = this.priority;
			}
			r[i] = ri;

		}

	}
	request[type] = r;

	return request;
}
/*** PRELOADING ****/
StandardController.prototype.checkGlobMatch = function( url ){
	if(!this.matchGlob) return false;
	var regex	= Igloo.glob2regex(this.matchGlob);
	return Igloo.regexCached(url,regex);
}
StandardController.prototype.checkPreloadGlobMatch = function( url ){
	if(!this.preloadGlob || this.hasPreloaded) return false;
	var regex	= Igloo.glob2regex(this.preloadGlob);
	return Igloo.regexCached(url,regex);
}
StandardController.prototype.runPreload = function(cache){
	if(!this.hasPreloaded){
		if(!cache) cache = jcache.getCache(nav.getState().nextUrl);
		if(cache.status != "loaded") return false;
		this.hasPreloaded = true;
		this.preload(cache);
		return true;
	}
}
StandardController.prototype.checkPreload = function(cache){

	if(this.preload && !this.hasPreloaded){
		if(this.checkPreloadGlobMatch(cache.url)){
			this.runPreload(cache);
			return true;
		}
	}
	return false;

}
StandardController.prototype.preload = function(cache){
	this.preloadImages();
}
StandardController.prototype.preloadImages = function(images){

	if(!Igloo.defined(images)){
		images = [];
		if(this.preloads) images = this.preloads;
		//if(this.preloadsLookup) images = images.concat(EIP.getCssPreloads(this.preloadsLookup === true ? this.baseName : this.preloadsLookup));
	}

	if(!images.length) return;

	var mc = MultipleOnComplete.create(Igloo.delegate(this,function(){
		//Igloo.log("preloaded images", images)
	}));
	for(var i = 0; i < images.length; i++){
		Preloader.add(images[i],mc(),{priority:3});
	}


}
StandardController.prototype.$getPreloadArea = function(name, fullscreen){

	var $area = StandardController.$preloadArea;
	if(!$area || !$area.length){
		$area = $(".wrapper").after("<div class='assets' />").parent().find(".assets");
		StandardController.$preloadArea = $area;
	}

	//alert("made asset area " + $area.length)


	if(!name) return $area;

	var $sub = $area.find("." + name);
	if(!$sub || !$sub.length){
		$sub = $area.append("<div class='"+name+"' />").find("."+name);
		if(fullscreen){
			$sub.addClass("bg").height(jstage.getHeight());

			jstage.addListener("resize",function(e){$sub.height(e.height)}); //Doesn't need to be removed...
		}
	}


	return $sub;

}
StandardController.prototype.$getAsset = function(asset, area, fullscreen){
	var $area = this.$getPreloadArea(area, fullscreen);

	var $asset = $area.find("." + asset.replace(" "," ."));

	if(!$asset.size()) $asset = $area.append("<div class='"+asset+"' />").children().last();

	return $asset;
}
/**** HANDLER ****/
StandardController.prototype.setHandler = function(handler){
	this.handler = handler;
}
StandardController.prototype.getHandler = function(){
	return this.handler;
}
/*** STATE ***/


StandardController.prototype.getState = function(){
	if(!this.handler) throw(this.className + " Handler not defined in getState");
	return this.handler.status;
}
StandardController.prototype.setState = function(state){
	if(!this.handler) throw(this.className + " Handler not defined in getState");
	if(state === true) state = "complete";
	if(state === false) state = "waiting";
	this.handler.status = state;
}
StandardController.prototype.clearState = function(){
	if(this.handler) this.setState(false);
}

/*** META ***/
StandardController.prototype.getMeta = function( url, save ){

	//if(!this.meta){
	//if(this.meta) Igloo.log("repeated meta call") //TODO - check for repeated meta calls and perhaps prevent.
	var state 	= nav.getState();
	var cache	= jcache.getCache(url ? url : state.nextUrl);
	var meta	= $.extend(true,{},cache.meta);
	if(save !== false) this.meta = meta;
	//}
	return meta;
}
StandardController.prototype.getTemplates = function(url){
	//if(!this.templates){  //Todo- sort out when to reset
	var state 		= nav.getState();
	var cache		= jcache.getCache(url ? url : state.nextUrl);
	this.templates 	= cache.templates;
	//}

}
/**
 * Returns the shared data object for a page saved in the cache
 * @param url The url of the page cache to load. If left blank will use the nextUrl
 * @returns {*} Data object, to allow sharing of data between controllers accessing a page
 */
StandardController.prototype.getData = function( url ){
	var cache	= jcache.getCache(url ? url : nav.getState().nextUrl);
	return cache.data;
}
StandardController.prototype.getTemplate = function(template, url){
	this.getTemplates(url);

	return template&&this.templates[template]?this.templates[template].html:"";
};