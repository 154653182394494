var Loader = function(){

	const 	expectedTime = 500,
		hope = 10, //The higher this is the quicker we're going to start out...
		maxVelocity = 20,
		acceleration = 1.1,
		cap = 0.8;

	let startTime,
		animationFrame,
		elapsed,
		timeProgress = 0,
		loadProgress = 0,
		velocity = 0,
		isComplete = false,
		isFinished = false,
		timeAtCompletion = 0,
		loadProgressAtCompletion = 0;


	var start = () => {
		isComplete = false;
		isFinished = isComplete
		startTime = +(new Date());
		update();
		if(this.onStart) this.onStart();
	}

	var update = () => {

		elapsed = (new Date()) - startTime;
		//console.log(elapsed)
		if(!isComplete){
			timeProgress = elapsed / expectedTime;
			loadProgress = f(timeProgress) * cap;
			velocity = g(timeProgress) * cap;
		}else{
			velocity *= acceleration;
			if(velocity > maxVelocity) velocity = maxVelocity;
			loadProgress = loadProgressAtCompletion + (elapsed - timeAtCompletion) / expectedTime * velocity;
			if(loadProgress >= 1){
				loadProgress = 1;
				finished();
			}
		}
//			console.log(loadProgress,velocity)


		if(this.onUpdate) this.onUpdate({percent:loadProgress * 100, complete: isComplete});


		if(!isFinished) animationFrame = requestAnimationFrame( update );

	}

	var complete = () => {

		isComplete = true;
		timeAtCompletion = elapsed;
		loadProgressAtCompletion = loadProgress;

		if(this.onComplete) this.onComplete({complete: true});
		//console.log("complete",loadProgress)
	}

	var finished = () => {
		//cancelAnimationFrame(animationFrame);
		isFinished = true;
		if(this.onFinished) this.onFinished();
	}

	f = (x) => {
		//https://www.desmos.com/calculator/sucassvrt2
		return 2 / (1 + Math.exp(-x * 2.5)) - 1;
	}

	g = (x) => {
		//https://www.integral-calculator.com/
		return 5 * (Math.exp(-2.5 * x))  / Math.pow(( Math.exp(-2.5*x)  + 1 ),2);
	}

	this.complete = () => complete();
	this.start = () => start();
}