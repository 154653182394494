function StoryController(){
	this.className	= "StoryController";
	this.glob		= ["/about/**"];
	this.globMain 	= true;
};
StoryController.inheritsFrom( StandardController );
StoryController.prototype.swap = function(){

	var state = nav.getState();

	if(!this.$secondaryMenuA) this.$secondaryMenuA = $(".js-secondary-menu a");
	if(state.nextHash){
		this.$secondaryMenuA.parent().removeClass("selected")
		this.$secondaryMenuA.filter("[href='"+state.nextUriSimple+"']").parent().addClass("selected");
	}


	if(!state.changes[0] && !state.initial) return;


	this.$secondaryMenuA = $(".js-secondary-menu a");

	this.$body 		= $('body');
	this.$header	= this.$body.find(".js-page-header");
	this.$sections  = this.$body.find("section");

	this.$timeline = this.$body.find(".js-timeline");
	this.timelineItems = [];
	this.$timeline.find(".js-item").each(Igloo.$delegate(this,function($this){
		var i = {};
		i.$item = $this;
		i.$circle = $this.find(".js-circle");
		i.$title = $this.find(".js-title");
		i.$content = $this.find(".js-content");
		i.index = this.timelineItems.length;
		this.timelineItems.push(i);
	}))

	this.lineAc = new AcceleratePositionToggle({
		a: 750,
		x: 0,
		onChange: Igloo.delegate(this,function(x){
			this.$path.css({strokeDashoffset:Math.round(x)+"px"});
			this.$path.attr("d",this.$path.attr("d"));
		})
	});

	this.buildTimeline();

	this.checkShowTimelineItemsDebounce = Igloo.debounce(this.checkShowTimelineItems,1,this);

	this.$secondaryMenuA.on("click touchstart",Igloo.$delegate(this,function($this){

		var parts = $this.attr("href").split("#")
		if(parts.length > 1){
			var hash = parts[1];
			var $goto = this.$body.find("#"+hash);


			Aviator.get("baseController").forceSmallHeader();
			this.measureSections();
			var position = $goto.position();

			$("html, body").animate({scrollTop:position.top - this.headerHeight + 2},{duration:1000});
			Aviator.get("baseController").scroll(true);

		}

	}));



	var sections = [];
	this.$sections.each(Igloo.$delegate(this,function($this){
		var s = {};
		s.$section = $this;

		//var cls = s.$section.attr("class");
		//var matches = cls.match(/(bg-[a-z]+)/);
		s.bg = s.$section.attr("data-colour"); //matches ? matches[1] : "bg-red";
		s.id = s.$section.attr("id");


		sections.push(s);
	}))

	this.sections = sections;

	this.measureSections();
	this.checkScroll(true);

	this.resizeDelegate 	= jstage.addListener("resize",Igloo.delegate(this,this.resize))
	this.scrollDelegate 	= jstage.addListener("scroll",Igloo.delegate(this,this.checkScroll))
	this.baseDelegate 		= Aviator.get("baseController").addListener("resizedHeader",Igloo.delegate(this,this.resize))


	//this.baseDelegate 		= Aviator.get("baseController").addListener("resizedHeader",Igloo.delegate(this,function(){Igloo.log("resize deleheader")}))


	this.$timelineOption = this.$body.find(".js-timeline-option");

	this.$timelineOption.addClass("js");
	this.$timelineOption.on("click",Igloo.$delegate(this,function($this){

		var $open = this.$timelineOption.filter(".open");

		var isOpen = $this.hasClass("open")

		var $dropdown = $this.find(".js-timeline-dropdown");

		var h1 = $dropdown.height();

		var d = 500;

		//Bit repeated here... but it'll do for the moment!

		if($open.size()){

			var $openDropdown = $open.find(".js-timeline-dropdown");
			var o1 = $openDropdown.height();
			$open.removeClass("open");
			$openDropdown.css("height","");
			var o2 = $openDropdown.height();
			$openDropdown.height(o1).animate({height:o2},{duration:d,complete:function(){ $(this).css("height","");}});
		}

		if(!isOpen){
			$this.addClass("open");
			$dropdown.css("height","");

			var h2 = $dropdown.height();
			$dropdown.height(h1).animate({height:h2},{duration:d,complete:function(){ $(this).css("height","");}});
		}

	}))

};

StoryController.prototype.measureSections = function(){

	var headerHeight = this.$header.height();
	this.headerHeight = headerHeight;

	for (var i = 0; i < this.sections.length; i++) {
		var section = this.sections[i];
		var $section = section.$section;
		var position = $section.position();
		section.position = position;
		section.top = section.position.top - headerHeight;
	}



}
StoryController.prototype.checkScroll = function(instant){

	instant = instant === true; //Incase it's called with an event

	var s = jstage.getScroll();
	var h = jstage.getHeight();
	var best = false;
	for (var i = 0; i < this.sections.length; i++) {
		var section = this.sections[i];
		if(section.top <= s){
			best = section;
		}
	}
	if(!best) best = this.sections[0]

	if(instant)   this.$header.addClass("instant");

	if(this.currentBg) this.$header.removeClass(this.currentBg);

	this.currentBg = best.bg;
	this.$header.addClass(this.currentBg);

	if(this.currentBest !== best){
		this.$secondaryMenuA.parent().removeClass("selected");
		this.$secondaryMenuA.filter("[href$=\""+best.id+"\"]").parent().addClass("selected");
		this.currentBest = best;
	}

	if(instant) setTimeout(Igloo.delegate(this,function(){this.$header.removeClass("instant");}),1);


	//Timeline
	for (var i = 0; i < this.timelineItems.length; i++) {
		var item = this.timelineItems[i];
		var factor = h / 4;

		if(!item.show && item.top < s + h - factor){
			this.showTimelineItem(item);
		}

	}

}
StoryController.prototype.resize = function(){

	this.measureSections();
	this.buildTimeline();
	this.checkScroll();
}

StoryController.prototype.buildTimeline = function(){


	this.isMobile = jstage.getWidth() < 650;

	var width = 0;
	var row = -1, col = 0;
	var currentTop = false;
	var rowGap = 0;
	var rowGaps = [];
	var previousItem = false;
	for (var i = 0; i < this.timelineItems.length; i++){
		var item = this.timelineItems[i];
		item.shown = false;
		item.show = false;
		var offset = item.$item.offset();
		item.top = offset.top;
		item.bottom = offset.top + item.$item.height();
		var newTop = offset.top;

		if(newTop !== currentTop){
			if(col) width = col;
			row++;
			col = 0;
			currentTop = newTop;

		}
		item.row = row;
		item.col = col++;
		item.dir = row%2 == 0 ? "left" : "right"


		if(item.row > 0 && item.col == 0){
			rowGaps[item.row - 1] = item.top - previousItem.top;
		}


		item.$item.css("float",this.isMobile ? "" :item.dir);
		this.resetTimelineItem(item);

		previousItem = item;
	}

	if(!rowGap) rowGap = this.timelineItems[0].$item.height();

	for (var i = 0; i < this.timelineItems.length; i++){
		var item = this.timelineItems[i];
		item.reindex = item.dir == "left" ? item.index : item.index - item.col + width - item.col;
	}

	/*
	var start = 0;
	this.drawInt = setInterval(Igloo.delegate(this,function(){
		var item = Igloo.searchArray(this.timelineItems,"index",start)
		if(item){
			item.$circle.addClass("animate-in")
			item.$title.animate({opacity:1},{duration:500});
			item.$content.delay(200).animate({opacity:1},{duration:500});

		}else{
			clearInterval(this.drawInt);
		}
		start++;
	}),250);

	*/


	this.$line = this.$body.find(".js-line");
	this.$path = this.$line.find("path")

	if(this.isMobile) this.$line.hide();
	else this.$line.show();


	var w = jstage.getWidth();
	var $pc = this.$body.find(".page-content");
	var w_inner = $pc.width() - Text.toNumber($pc.css("padding-left"));
	var margin = (w - w_inner) / 2;

	var offsetY = 60;


	var c2 = this.timelineItems[0].$circle.width()/2; //jstage.getWidth()
	var c1 = c2 * 0.552284749831;//c2 * 3 / 5;
	var h = rowGap - c2 * 2;

	var path = "";
	path = 	"M0 "+offsetY 													//Move to start point

	var curveLength = Math.PI * c2 / 2;

	var segment = function(direction,type, h){

		var length = 0;

		var path = "";
		if(direction == "right"){

			if(type == "start" || type == "both"){
				path += "h" + margin;
				length += margin;
			}else{
				path +=	"c0 " + c1 + ","+ (c2-c1) + " " + c2 + "," + c2 + " " + c2 			//Curve round to right
				length += curveLength;
			}

			path += "h" + w_inner														//Horizontal accross to the right
			length += w_inner;

			if(type == "end" || type == "both"){
				path 	+= "h" + (margin + 20);
				length += margin + 20;
			}else{
				path 	+= 	"c" + c1 + " 0,"+ c2 + " " + (c2-c1) + "," + c2 + " " + c2 			//Curve round to face downwards
						+ 	"v " + h;															//Vertical line down
				length += curveLength + h;
			}



		}

		if(direction == "left"){

			path 	+=	"c0 " + c1 + ",-"+ (c2-c1) + " " + c2 + ",-" + c2 + " " + c2 		//Curve round to left
					+ 	"h-" + w_inner;  													//Line to the left
			length += curveLength + w_inner;

			if(type == "end" || type == "both"){
				path += 	"h-" + (margin  + 20);  													//Line to the left
				length += margin  + 20;
			}else{
				path += 	"c-" + c1 + " 0,-"+ c2 + " " + (c2-c1) + ",-" + c2 + " " + c2 		//Curve round to face downwards
					 + 	"v " + h;																//Vertical line down
				length += curveLength + h;
			}

		}


		return {path:path, length: length};

	}

	var rows = row + 1;

	var pathLength = 0;
	var segmentLengths = [];




	for (var i = 0; i < rows; i++) {
		var type = false;
		if(rows == 1) type = "both";
		else if(i == 0) type = "start";
		else if(i == rows-1) type = "end";

		var h = rowGaps[i] - c2 * 2;

		var details = segment(i%2?"left":"right",type,h);
		path += details.path;
		segmentLengths[i] = details.length;
		pathLength += details.length;
	}

	var totalHeight = this.timelineItems[this.timelineItems.length-1].top - this.timelineItems[0].top + this.timelineItems[this.timelineItems.length-1].$item.height();


	var dashoffsets = [];
	for (var i = 0; i < segmentLengths.length; i++) {
		dashoffsets[i] = (i == 0 ? pathLength : dashoffsets[i-1]) - segmentLengths[i];
	}

	this.dashoffsets = dashoffsets;
	var pathLengthRound = Math.round(pathLength);
	this.$path.css({strokeDasharray:pathLengthRound+"px",strokeDashoffset:pathLengthRound+"px"});


	this.$path.attr("d",path);


	this.$line.css("width",w).css("left",0).css("top",this.timelineItems[0].top)
	this.$line.css("height",totalHeight)
	//c64.9,0,117.5-52.61,117.5-117.5
	//this.$lineWrapper.html(this.$lineWrapper.html())

	this.lineAc.instant(pathLength);

}
StoryController.prototype.showTimelineItem = function(item){

	item.show = true;

	//Loop through items and show them
	this.checkShowTimelineItemsDebounce();


}
StoryController.prototype.checkShowTimelineItems = function(){
	//First check to see what's been shown

	clearTimeout(this.currentTimelineTimeout);

	var latest = false;
	for (var i = 0; i < this.timelineItems.length; i++) {
		var item = this.timelineItems[i];
		if(item.show && !item.shown) latest = item;

	}

	if(latest !== false){

		for (var i = 0; i < this.timelineItems.length; i++) {
			var item = this.timelineItems[i];
			if(item.shown) continue;

			if(item.row < latest.row){
				this.showTimelineItemHard(item);
			}else{
				this.showTimelineItemHard(item);
				this.currentTimelineTimeout = setTimeout(Igloo.delegate(this,this.checkShowTimelineItemsDebounce),150);
				return;
			}

		}

		var item = this.timelineItems[latest];


	}

}
StoryController.prototype.showTimelineItemHard = function(item){

	var slowDown = 300 + item.col * 100;

	item.$circle.addClass("animate-in")
	item.$title.delay(1+slowDown).animate({opacity:1},{duration:1500});
	item.$content.delay(500+slowDown).animate({opacity:1},{duration:1500});
	item.shown = true;

	this.lineAc.change(this.dashoffsets[item.row]);
	//this.lineAc.change(1000000);


}
StoryController.prototype.resetTimelineItem = function(item){
	item.$circle.removeClass("animate-in")
	item.$title.stop();
	item.$content.stop();
	item.$title.css("opacity",0);
	item.$content.css("opacity",0);
}
StoryController.prototype.offMain = function() {
	var state = nav.getState();
	if (!state.changes[0]) return;
	this.off();
}
StoryController.prototype.off = function(){
	jstage.removeListener("resize",this.resizeDelegate);
	jstage.removeListener("scroll",this.scrollDelegate);
	Aviator.get("baseController").removeListener("resizedHeader",this.baseDelegate);

	if(this.currentBg){
		this.$header.addClass("instant").removeClass(this.currentBg);
		setTimeout(Igloo.delegate(this,function(){this.$header.removeClass("instant");}),1);
	}

	clearTimeout(this.currentTimelineTimeout);
};
