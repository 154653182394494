function CareersController(){
	this.className	= "CareersController";
	this.glob		= ["/careers/","/careers/careers/"];
	// this.globMain 	= true;
};

CareersController.inheritsFrom( StandardController );

CareersController.prototype.swap = function(){

	const state = nav.getState();
	this.$body 		= $('body');


	// Intro video
	if(!state.nextParts[1]){

		this.$videoHolder = this.$body.find(".js-sizer div");

		// just in case, give it an extra play!
		try{
			const iframe = document.querySelector('iframe');
			const player = new Vimeo.Player(iframe);
			player.play();
		}catch(e){}


		// Resize the video to fit nicely

		this.resizeVideo();
		this.resizeVideoDelegate = jstage.addListener("resize",() => this.resizeVideo());

		// Click takes you to careers page
		this.$videoHolder.parent().on("click touchstart",(e) => {
			e.preventDefault();
			nav.change(`/${state.nextParts[0]}/careers/`);
		})

		return true;
	}

	// Careers page

	this.$header	= this.$body.find(".js-page-header");
	this.$sections  = this.$body.find(".js-section");


	// if(!this.$secondaryMenuA) this.$secondaryMenuA = $(".js-secondary-menu a");
	// if(!state.changes[0] && !state.initial) return;
	// this.$secondaryMenuA = $(".js-secondary-menu a");

	var sections = [];
	this.$sections.each(Igloo.$delegate(this,function($this){
		var s = {};
		s.$section = $this;
		s.bg = s.$section.attr("data-colour"); //matches ? matches[1] : "bg-red";
		s.theme = s.$section.attr("data-theme-colour");
		s.id = s.$section.attr("id");
		sections.push(s);
	}));

	this.sections = sections;
	// console.log(this.sections, ' this.sections');

	this.measureSections();
	this.checkScroll(true);

	this.resizeDelegate 	= jstage.addListener("resize",Igloo.delegate(this,this.resize));
	this.scrollDelegate 	= jstage.addListener("scroll",Igloo.delegate(this,this.checkScroll));
	this.baseDelegate 		= Aviator.get("baseController").addListener("resizedHeader",Igloo.delegate(this,this.resize));
	// console.log('event setup');
	return true;
};

CareersController.prototype.resizeVideo = function(){
	const w 			= jstage.getWidth();
	const h 			= jstage.getHeight();

	const currentRatio 	= w/h;
	const ratio 		= 16/9;
	const maxRatio 		= 2.433333333333333;
	const minRatio 		= 1.55707;


	let constrain;
	const newSizes 		= {}
	let scaleFactor 	= 1;

	if(currentRatio < ratio){
		constrain = "height";
		if(currentRatio < minRatio){
			scaleFactor = currentRatio / minRatio;
		}
	}else {
		constrain = "width";
		if(currentRatio > maxRatio){
			scaleFactor = maxRatio / currentRatio;
		}
	}

	if(constrain == "width"){
		newSizes.width = w * scaleFactor;
		newSizes.height = w * scaleFactor / ratio;
	} else {
		newSizes.height = h * scaleFactor;
		newSizes.width = h * scaleFactor * ratio;
	}

	newSizes.left = (w-newSizes.width)/2;
	newSizes.top = (h-newSizes.height)/2;

	this.$videoHolder.css(newSizes);
}
CareersController.prototype.measureSections = function(){
	var headerHeight = this.$header.height();
	this.headerHeight = headerHeight;

	for (var i = 0; i < this.sections.length; i++) {
		var section = this.sections[i];
		var $section = section.$section;
		var position = $section.position();
		section.position = position;
		section.top = section.position.top - headerHeight;
	}
};

// header colour change on scroll
CareersController.prototype.checkScroll = function(instant){
	instant = instant === true; //Incase it's called with an event

	var s = jstage.getScroll();
	// var h = jstage.getHeight();
	var best = false;
	for (var i = 0; i < this.sections.length; i++) {
		var section = this.sections[i];
		if(section.top <= s){
			best = section;
		}
	}
	if(!best) best = this.sections[0];
	if(instant)   this.$header.addClass("instant");



	if(this.currentBg) this.$header[0].classList.remove(this.currentBg);
	if(this.currentTheme) this.$body[0].classList.remove(this.currentTheme);
	// console.log(this.currentBg, ' this.currentBg');
	this.currentBg = best.bg;
	this.currentTheme = best.theme;
	this.$header[0].classList.add(this.currentBg);
	this.$body[0].classList.add(this.currentTheme);

	if(this.currentBest !== best){
		// this.$secondaryMenuA.parent().removeClass("selected");
		// this.$secondaryMenuA.filter("[href$=\""+best.id+"\"]").parent().addClass("selected");
		this.currentBest = best;
	}

	if(instant) setTimeout(Igloo.delegate(this,function(){this.$header.removeClass("instant");}),1);
};

CareersController.prototype.resize = function(){
	this.measureSections();
	this.checkScroll();
};

// CareersController.prototype.offMain = function() {
// 	var state = nav.getState();
// 	if (!state.changes[0]) return;
// 	this.off();
// };

CareersController.prototype.off = function(){
	if(this.resizeDelegate) jstage.removeListener("resize",this.resizeDelegate);
	if(this.scrollDelegate) jstage.removeListener("scroll",this.scrollDelegate);
	if(this.baseDelegate) Aviator.get("baseController").removeListener("resizedHeader",this.baseDelegate);

	if(this.resizeVideoDelegate)	jstage.removeListener("resize",this.resizeVideoDelegate);

	if(this.currentBg){
		// this.currentBg = false;
		// this.currentBest = false;
		this.$header.addClass("instant").removeClass(this.currentBg);
		setTimeout(Igloo.delegate(this,function(){this.$header.removeClass("instant");}),1);
	}
};
