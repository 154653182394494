function HomeController(){
	this.className	= "JamesController";
	this.debug		= false;
	this.glob		= ["/", "/tests/james/"];

	this.currentSlides 	= [];
	this.zindex   		= 1;

	this.nav 		= false;
};
HomeController.inheritsFrom( SlideController );


HomeController.prototype.setupPage = function(){

	this.$paragraphs = $(".js-wrapper");
	this.readMores = ReadMore.build({
		$wrapper: 	this.$paragraphs,
		maxHeight:	100,
		content: 	".js-reduce",
		text: 		".js-reduce",
		readmore:	".js-readmore",
		dynamic: 	true,
		inline:		false,
	});

	this.$pagination = $('.js-pagination');


};

////*** SPECIFIC SLIDE SETUPS *******/////


HomeController.prototype.setupSlideHome = function(details){
	this.$pagination.addClass('hidden').fadeOut();
}
HomeController.prototype.destroySlideHome = function(details){
	this.$pagination.removeClass('hidden').fadeIn();
}

HomeController.prototype.setupSlideOutro = function(details){
	var $mainWrapper = details.$scene.find(".js-zero-title");
	var $items = $mainWrapper.find("p");

	details.$scene.show();


	nav.addToLinks(details.$scene.find("a.button").off("click"),true);

	var words = [];
	$items.each(function(i){
		var $wrapper = $(this);
		var $span = $wrapper.find("span");
		words.push({$wrapper: $wrapper, $span: $span});
	});


	var position = function(x){
		var first = x === true;
		var max = 0;
		var maxrhs = 0;
		var wrapperWidth = $mainWrapper.width();
		var spanWidth = words[0].$span.width();
		for (var i = 0; i < words.length; i++) {
			var word 		= words[i];
			var $wrapper 	= word.$wrapper;
			var $span 		= word.$span;
			if(first) $wrapper.css("opacity",0);
			$wrapper.css("margin-left","");

			word.spanOffset = $span.position();
			word.offset = word.spanOffset.left;

		}

		for (var i = 0; i < words.length; i++) {
			var word = words[i];
			word.$wrapper.css("margin-left",Math.round(-word.offset + wrapperWidth / 2 - spanWidth / 2));
		}

	}

	position(true);
	details.resizeDelegate = jstage.addListener("resize",position);

	var t = 400;
	var d = 500;

	var index = 0;
	var previous;
	var showNext = function(){
		words[index].$wrapper.animate({opacity:0.99},{duration:d, ease:"easeInOutCubic"});
		if(Igloo.defined(previous)){
			words[previous].$wrapper.animate({opacity:0},{duration:d, ease:"easeInOutCubic"});
			//words[previous].$wrapper.addClass("on")
		}

		previous = index;
		index = (index + 1); // % words.length;

		if(index < words.length) details.timeout = setTimeout(showNext,t+d);
	}

	details.timeout = setTimeout(showNext,t);
	words[0].$wrapper.css("opacity",0.95);
	//words[previous].$wrapper.removeClass("on")
	//showNext();


}
HomeController.prototype.destroySlideOutro = function(details){
	jstage.removeListener("resize",details.resizeDelegate);
	clearTimeout(details.timeout);
}

HomeController.prototype.setupSlideOverview = function(details){
	var worms = new Worms({
		$holder: details.$scene.find(".js-canvas-wrapper"),
		colour: details.lineColour
	});
	details.controller = worms;
}
HomeController.prototype.destroySlideOverview = function(details){
	if(details.controller){
		details.controller.destroy();
		delete details.controller;
	}
}

HomeController.prototype.setupSlidePolicy = function(details){
	var worms = new Worms({
		$holder: details.$scene.find(".js-canvas-wrapper"),
		type:2,
		colour: details.lineColour
	});
	details.controller = worms;
}
HomeController.prototype.destroySlidePolicy = function(details){
	if(details.controller){
		details.controller.destroy();
		delete details.controller;
	}
}


HomeController.prototype.buildSlides = function(){
	this.slides = [];
	$(".js-section").each(Igloo.$delegate(this,function($this,i){
		$s = $this;


		var x = {
			$scene: $s,
			colourClass: $s.attr("data-colour"),
			lineColour: $s.attr("data-line-colour")
		};

		this.slides.push(x);



		var sectionName = $s.attr("class").match(/js-section-([a-z]+)/); sectionName = sectionName ? sectionName[1] : false;

		if(sectionName){
			var n = "setupSlide" + sectionName.charAt(0).toUpperCase() + sectionName.slice(1);
			var nd = "destroySlide" + sectionName.charAt(0).toUpperCase() + sectionName.slice(1);
			var rs = "resizeSlide" + sectionName.charAt(0).toUpperCase() + sectionName.slice(1);
			var f = this[n];
			var d = this[nd];
			var r = this[rs];
			if(f) x.setupFunction = Igloo.delegate(this,f,x);
			if(d) x.destroyFunction = Igloo.delegate(this,d,x);
			if(r) x.resizeFunction = Igloo.delegate(this,r,x);
		}

		$s.hide();

	}));

}
HomeController.prototype.onResize = function(stage){
	this.next
}
HomeController.prototype.slideBringOn = function(nextSlide){


	var currentSlide = this.getSlide(this.currentSlide);

	if(currentSlide) this.$body.removeClass(currentSlide.colourClass);
	this.$body.addClass(nextSlide.colourClass);

	nextSlide.$scene.addClass("active").addClass("transitioning-on").addClass(nextSlide.colourClass);

	nextSlide.adding = true;

	if(nextSlide.removing){
		nextSlide.removing = false;
	}
	if(nextSlide.setupFunction) nextSlide.setupFunction();

	if(currentSlide) {


		this.currentSlides.push(currentSlide);
		this.cancelTransitionOn(currentSlide.$scene)
		currentSlide.$scene.addClass("transitioning-off");
		currentSlide.removing = true;


		currentSlide.adding = false;

		var t = 1000;

		nextSlide.$scene.css("z-index", this.zindex++).stop().css("opacity",0).show().animate({"opacity":1},{
			duration: t, easing: "easeInOutCubic", complete: Igloo.delegate(this,function () {

				for(var i = 0; i < this.slides.length; i++){
					var s = this.slides[i];
					if(s.adding) continue;

					s.removing = false;
				}
				nextSlide.adding = false;
				this.transitionOffComplete();
			})
		});

	}else{
		nextSlide.$scene.css("z-index", this.zindex++).css("opacity",1).show();
		this.transitionOffComplete();
	}



}
HomeController.prototype.cancelTransitionOn = function($slide, cancel){
	$slide.stop().removeClass("active").removeClass("transitioning-off").removeClass("transitioning-on");

	if(cancel) for(var i = 0; i < this.currentSlides.length; i++) {
		var $s = this.currentSlides[i].$scene;
		if ($s[0] === $slide[0]) this.currentSlides.splice(i, 1);
	}

}

HomeController.prototype.transitionOffComplete = function(){

	var skip = 0;
	while (this.currentSlides.length > skip){

		var s = this.currentSlides[skip];
		if(this.currentSlide == s.index){
			skip++;
			continue;
		}
		s.removing = false;
		s.active = false;
		s.main = false;
		if(s.destroyFunction) s.destroyFunction();
		var $s = s.$scene;
		this.cancelTransitionOn($s,true);
		$s.hide();


	}

	this.nextSlide.$scene.removeClass("transitioning-on");

	this.showSlideComplete(this.nextSlide.index);

}
HomeController.prototype.off = function(){

	this.runParent("off",arguments);

	for(var i = 0; i < this.slides.length; i++){

	}

	this.readMores.destroy();

};
