function MyvercoHomeController(){
	this.className			= "MyvercoHomeController";
	this.view				= false;
	this.glob				= ["/myverco/", "/myverco/?**"];
	// this.glob			= ["/", "/?**"];
	this.viewData			= {};
}
// MyvercoHomeController.inheritsFrom( PageController );
MyvercoHomeController.inheritsFrom( StandardController );

MyvercoHomeController.prototype.swap = function(onComplete){
	this.$body				= $('body');
	this.$page				= this.$body.find('.js-page');
	this.$header			= this.$body.find('.js-page-header');

	// this.checkScroll(true);

	// this.resizeDelegate = jstage.addListener("resize", (e) => this.onResize(e));
	// this.scrollDelegate = jstage.addListener("scroll", (e) => this.checkScroll());
	// this.baseDelegate = Aviator.get("baseController").addListener("resizedHeader",Igloo.delegate(this,this.onResize));

	this.setupAnimations();

	return true;
}

MyvercoHomeController.prototype.setupAnimations = function(){
	new AnimationController({
		containerSelector: ".y-shape",
		triggerStart: 'top 80%',
		triggerEnd: 'top top',
		showMarkers: false,
		once: true,
		onScroll: false,
		scrub: true,
		items: [{
			selector: '.y-shape path',
			start: { opacity: 0.5 },
			end: { opacity: 1 },
			ease: 'power4.out',
			options: {
				duration: 1,
				stagger: (i) => {
					const staggerDelay = 0.3;
					const staggerDelays = [
						staggerDelay,     // Path 0
						staggerDelay * 5, // Path 1
						staggerDelay * 3, // Path 2
						staggerDelay * 4, // Path 3
						staggerDelay * 5, // Path 4
						staggerDelay * 2, // Path 5
						staggerDelay * 4, // Path 6
						staggerDelay * 5, // Path 7
					];

					return staggerDelays[i] || 0;
				}
			},
		}]
	});

	const features = document.querySelectorAll('#features .js-feature-item');
	features.forEach((element, index) => {
		const mod = index % 2;
		const x = mod === 0 ? -50 : 50;
		const selector = '#features .js-feature-item:nth-child('+ (index + 1) +') .js-image--feature';
		new AnimationController({
			containerSelector: selector,
			triggerStart: 'top 80%',
			triggerEnd: 'top top',
			showMarkers: false,
			once: true,
			onScroll: false,
			scrub: true,
			items: [{
				selector: selector,
				start: { x: x, opacity: 0 },
				end: { x: 0, opacity: 1 },
				ease: 'power4.out',
				duration: 1
			}]
		});
	});

	new AnimationController({
		containerSelector: '#features .js-feature2-item',
		triggerStart: 'top 75%',
		triggerEnd: 'top 50%',
		showMarkers: false,
		once: true,
		onScroll: false,
		scrub: true,
		items: [{
			selector: '#features .js-feature2-item',
			start: { opacity: 0 },
			end: { opacity: 1 },
			options: {
				duration: 0.5,
				stagger: 0.25
			}
		}]
	});

	/*
	new AnimationController({
		containerSelector: '#purpose',
		triggerStart: 'top 50%',
		triggerEnd: 'top top',
		showMarkers: false,
		// once: true,
		onScroll: false,
		items: [{
			selector: '#purpose .js-image--shape-1 path:nth-child(1)',
			start: { x: -1.5, y: -1.5 },
			end: { x: 0, y: 0 },
		},{
			selector: '#purpose .js-image--shape-2 path:nth-child(2)',
			start: { x: -1.5, y: 1.5 },
			end: { x: 0, y: 0 },
		}]
	});

	new AnimationController({
		containerSelector: '#purpose',
		triggerStart: 'top 50%',
		triggerEnd: 'top top',
		showMarkers: false,
		// once: true,
		onScroll: false,
		items: [{
			selector: '#purpose .js-image--shape-2 path:nth-child(1)',
			start: { x: -1.5, y: -1.5 },
			end: { x: 0, y: 0 },
		},{
			selector: '#purpose .js-image--shape-2 path:nth-child(2)',
			start: { x: -1.5, y: 1.5 },
			end: { x: 0, y: 0 },
		},{
			selector: '#purpose .js-image--shape-2 path:nth-child(3)',
			start: { x: 1.5, y: 1.5 },
			end: { x: 0, y: 0 },
		}],
	});
	*/
}
MyvercoHomeController.prototype.checkScroll = function(instant){
	instant = (instant === true);

	if(instant) this.$header.addClass("instant");

	if(instant) setTimeout((() => { this.$header.removeClass("instant"); }), 1);
}
MyvercoHomeController.prototype.onResize = function(){
	this.setupAnimations();
	this.checkScroll();
}
MyvercoHomeController.prototype.off = function(){
	if(this.resizeDelegate) jstage.removeListener("resize",this.resizeDelegate);
	if(this.scrollDelegate) jstage.removeListener("scroll",this.scrollDelegate);
	if(this.baseDelegate) Aviator.get("baseController").removeListener("resizedHeader",this.baseDelegate);

	ScrollTrigger.killAll();
}