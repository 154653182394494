
	function SearchController(){
		
		this.className			= "SearchController";
		this.ignoreWords		= ["a","and","the","an","of","mr","mrs","miss"];
		this.searchIndexes		= {};
		this.searchData			= false;
		
	};

	SearchController.abstract = true;
	//SearchController.inheritsFrom( StandardController );
	SearchController.prototype.setSearchData = function( data ){
		this.searchData = data;
	}
	SearchController.prototype.createSearchIndex = function( label, keys, options ){
		var ignore = false;
		var index = {};
		if(!options) options = {};

		var splitWords = Igloo.isDefined(options.words) ? options.words : false;

		var data = this.searchData;
		for(var i = 0 ; i < data.length; i++){
			var d = data[i];

			var combined = [];

			for(var j = 0; j < keys.length; j++){

				var cs = data[i][keys[j]];
				if(!cs) continue;
				if(typeof(cs) != "object") cs = [cs];

				for (var k = 0; k < cs.length; k++) {
					var words = this.strToWords(cs[k], splitWords);
					if(words) for (var l = 0; l < words.length; l++) combined.push(words[l]);
				}
			}

			for(var j = 0; j < combined.length; j++){
				var word = combined[j].toString(); //Make sure as we can save non-strings
				if(!index[word]) index[word] = [];
				if(word && word != " ") index[word].push(i);
			}
		}
		//Sort the index array
		var keys = [];
		for(var i in index){
			keys.push(i);
		}
		keys.sort();
		var index_sorted = {};
		for(var i in keys) if(keys.hasOwnProperty(i)){
			index_sorted[keys[i]] = index[keys[i]];
		}

		this.searchIndexes[label] 	= index_sorted;

	}

	SearchController.prototype.strToWords = function(str, split){
		var ignore = false;
		var list = [];

		if(!str) return list;

		str = str.toString();

		var words =Text.basic_text(str.toLowerCase(),"-").replace(/  /g, " ");
		words = split ? words.split('-') : [words] ;

		for(var j = 0 ; j < words.length ; j++){
			var word = words[j];
			if(word == '' || word == ' ') continue;
			for(var k in this.ignoreWords) if(this.ignoreWords[k] == word){ ignore = true; break;}
			if(ignore){ ignore = false; continue; }
			list.push(word);
		}
		return list
	}

	SearchController.prototype.performSearch = function(str,label){
		return this.performFilter({label:label, filters:[str]});
	}

	SearchController.prototype.performFilter = function(queries){

		//Queries should have the form: [{label:"location", filters: ["uk","france"]}, ... ]

		//Looks like an empty queries - worth doing a bit better trhough, because second might not be.
		if(!queries || queries.length == 0 || (queries.length == 1 && (!queries[0].filters || !queries[0].filters[0]))) return this.searchData;



		var matches = []; //This is going to be a 3D array of queries (filters) vs peopleIDs
		for(var i = 0 ; i < queries.length; i++) {
			var query 	= queries[i];
			var label 	= query.label;
			var filters = query.filters;
			var type 	= query.type ? query.type : "OR";
			var partial	= query.partial ? query.partial : false;
			var split	= query.words ? query.words : false;

			var filterWords = [];
			for (var j = 0; j < filters.length; j++) {
				var words = this.strToWords(filters[j], split);
				for (var k = 0; k < words.length; k++) {
					filterWords.push(words[k]);
				}
			}

			//Generate a 3D array showing how many of each word matches there are
			var m = [];
			for (var j = 0; j < filterWords.length; j++) {
				var filter = filterWords[j];
				m[j] = {};
				for(var k in this.searchIndexes[label]) if(this.searchIndexes[label].hasOwnProperty(k)){
					var r = this.searchIndexes[label][k];
					var w = 1;
					var kShortened = partial ? k.substr(0, filter.length) : k;
					if(filter == kShortened){
						for (var l in r){
							m[j][r[l]] = m[j][r[l]] ? m[j][r[l]] + w : w;
						}
					}
				}
			}

			//We've now how many times each word has matched each person
			//We combine this because we only care about one match per person.
			var m = this.arrayUnions(m);

			//If it's and, then take the ones that equal the total, if it's OR take any.
			for (var j in m) if (m.hasOwnProperty(j)) {
				if(type == "AND") {
					if (m[j] !== filterWords.length) delete m[j];
					else m[j] = 1;
				}
				if(type == "OR"){
					if (m[j]) m[j] = 1;
				}
			}

			matches.push(m);

		}
		var m = this.arrayUnions(matches);

		var results = [];
		for (var i in m) if (m.hasOwnProperty(i)) {
			if (m[i] == queries.length) {
				results.push(this.searchData[i]);
			}
		}

		return results;


	}
	SearchController.prototype.arrayUnions = function(source){
		var r = {};
		for (var j = 0; j < source.length; j++) {
			var w = 0;
			for(var k in source[j]) if(source[j].hasOwnProperty(k)){
				if(source[j][k]) r[k] = r[k] ? r[k] + 1 : 1;
			}
		}
		return r;
	}
