function LatestController(){
	this.className	= "LatestController";
	this.glob		= ["/latest/*"];
};
LatestController.inheritsFrom( StandardController );
LatestController.prototype.swap = function(){


	$(".js-updates-start a").off("click").on("click",Igloo.$d(this,function(){
		new SignupForm();
	}))

	var state = nav.getState();

	this.isIndex = !state.nextParts[1] || state.nextParts[1].match(/^\d+$/); //match a uri with just digits - assume it's a page number, not an article

	this.$articles = $(".js-article");

	//if(this.isIndex){

		this.$articles.on("click",'.js-linkup',Igloo.$d(this,function($this){
			var link = $this.parents().find("a:last").attr("href");
			nav.change(link);
		})).css("cursor","pointer");

		var readMore = Igloo.delegate(this,function(){
			this.readMores = ReadMore.build({
				$wrapper: 	this.$articles,
				content: 	".js-content",
				text: 		".js-reduce",
				dynamic:	true,
			});
		});

		readMore();
		setTimeout(readMore,10);
		setTimeout(readMore,100);
		setTimeout(readMore,500);

	//}

	if(!this.isIndex) {

		this.$webinarLink = $('.article-main a[href*="gotowebinar.com/register"]:not([target="_blank"]').first();

		if(this.$webinarLink.length > 0) {
			//only run this on articles that actually have webinar links in them!
			this.webinarLinkText = this.$webinarLink.text();
			var webinarLinkHref = this.$webinarLink.attr('href').split("/");
			this.webinarLinkID = webinarLinkHref[webinarLinkHref.length-1];

			this.$webinarLink.wrap("<div class='webinar-form'></div>");
			this.$webinarLink.hide();
			this.$webinarForm = $('.webinar-form');

			this.$webinarForm.append(Igloo.delegate(this, function() {
				return this.getTemplate("webinar")
			}));

			this.$webinarTitle = this.$webinarForm.find('.form-title');
			this.$webinarTitle.text(this.webinarLinkText);
			this.$webinarFormSubmitButton = this.$webinarForm.find('.js-submit');

			this.$webinarFormSubmitButton.on('click', Igloo.delegate(this,function(e){
				e.preventDefault();
				var $thisBookingForm = this.$webinarForm.find('.booking-form');
				var $thisBookingThanks = this.$webinarForm.find('.booking-thanks');
				var $errorMessage = $thisBookingForm.find('.webinar-errors');
				var $alreadyRegisteredMessage = $thisBookingForm.find('.webinar-already-registered');

				var firstName = $thisBookingForm.find('input[name*="firstname"]').val();
				var lastName = $thisBookingForm.find('input[name*="lastname"]').val();
				var email = $thisBookingForm.find('input[name*="email"]').val();

				//error checking
				var checkEmail = function(email){
					var ecr = /^([\w-]+(?:[\w-.+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
					return ecr.test(email);
				}

				var hasErrors = false;
				if(firstName=='') { hasErrors = true; }
				if(lastName=='') { hasErrors = true; }
				if(!checkEmail(email)) { hasErrors = true; }

				//Igloo.log('errors',hasErrors);

				if(!hasErrors) {
					$errorMessage.hide();
					$alreadyRegisteredMessage.hide();

					var formData = { "firstName":firstName, "lastName":lastName, "email":email };
					var jsonData = JSON.stringify(formData);

					//TODO - populate with sensible data for API
					//var oranizerKey = '!123verco123!';
					var webinarKey = this.webinarLinkID; //"1351712513460697102" // ??
					//var apiURL = '/organizers/'+oranizerKey+'/webinars/'+webinarKey+'/registrants';
					var apiURL = '/api/register/'+webinarKey;
				//	Igloo.log(jsonData);
				//	Igloo.log(apiURL);



					this.$webinarFormSubmitButton.text("Submitting...")
					var submission = $.ajax({
							url: apiURL,
							data: formData,
							method: "post"
						}).then(Igloo.delegate(this,function(e){
							if(e.success){
								$thisBookingForm.hide();
								$thisBookingThanks.show();
							}else{
								//alert("Do something with this: " + e.message);
								if(e.message=='The user is already registered.') {
									$alreadyRegisteredMessage.show();
								} else {
									$errorMessage.show();
								}
								this.$webinarFormSubmitButton.text("Submit");
							};
						}));


					// var submission = $.ajax({
					// 	url: apiURL,
					// 	data: jsonData,
					// 	method: "post"
					// })
					// submission.success(function() {
						//$thisBookingForm.hide();
						//$thisBookingThanks.show();
					// });

				} else {
					$alreadyRegisteredMessage.hide();
					$errorMessage.show();
				}



			}));

		}
	}

}
LatestController.prototype.off = function(){
	if(this.readMores) this.readMores.destroy();
}
