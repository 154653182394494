// JavaScript Document

Function.prototype.inheritsFrom = function( parentClassOrObject ){
        if ( parentClassOrObject.constructor == Function ) 
        {

			//Normal Inheritance

			if(!this.__scopes) this.__scopes = {};




			for(var i in parentClassOrObject.__parents){

				var nn =  new parentClassOrObject.__parents[i]; //Not 100% sure if we should do this or not.
				for(var j in nn) this.prototype[j] = nn[j];

				for(var k in parentClassOrObject.__parents[i]) if(k == "register"){
					this[k] = parentClassOrObject.__parents[i][k];

				}
			}

			var n = new parentClassOrObject;
			for(var i in n){
				this.prototype[i] = n[i];
				this.__scopes[i] = parentClassOrObject;
			}
			for(var k in parentClassOrObject) if(k == "register") this[k] = parentClassOrObject[k];




			this.prototype.__constructor = this;
			this.prototype.__parent = parentClassOrObject.prototype;
			this.prototype.__parentInstance = n;
			if(!this.__parents) this.__parents = [];
			this.__parents.push(parentClassOrObject);

        } 
        else 
        {
                //Pure Virtual Inheritance
                this.prototype = parentClassOrObject;
                this.prototype.__constructor = this;
                this.prototype.__parent = parentClassOrObject;
        }
		
		//Standard register static methods
		if(this.abstract){
			if(parentClassOrObject.register && !this.register) this.register = parentClassOrObject.register;	
		}
		
		if(typeof(parentClassOrObject.register) == "function" && !this.abstract) parentClassOrObject.register(this);
		
        return this;
}
Function.prototype.isLive = function(){
	//Igloo.log(this.__constructor)
}
function BaseClass(){}
BaseClass.prototype.destroy = function(){};
BaseClass.prototype.runParent = function(func,options){ //To do - think and test this. Not sure if nested ones will work.

	//If this is going wrong, make sure the function exists in all levels of the class.

	//Igloo.log("Run parent")
	//Igloo.log(this)


	var backup = this.parentScopeCurrent;
	var currentParent = this.parentScopeCurrent;
	var parentScope = currentParent ? currentParent.__parentInstance : this.__parentInstance;

	if(window.debugRunParent){
		Igloo.log("==========")
		Igloo.log(this);
		Igloo.log(currentParent);
		Igloo.log(parentScope);


		Igloo.log(arguments.callee.caller.name)
		Igloo.log(this.__scopes)
		Igloo.log(eval(this.className).__scopes[func])
		Igloo.log(backup)
		Igloo.log(currentParent)
		Igloo.log(parentScope)
		Igloo.log("==========")
	}


	this.parentScopeCurrent = parentScope;
	var r = parentScope[func] ? parentScope[func].apply(this,options) : null;
	this.parentScopeCurrent = backup;

	return r;
};