Preloader2.inlineTrigger = function(onComplete, delay){
    var index = this.inlineTriggers.length
    if(!Igloo.defined(delay)) delay = 10;

    this.inlineTriggers[index] = {onComplete:onComplete,delay:delay};
    return " onload=\"console.log('here-----');Preloader2.rit("+index+")\" ";
}

function AnimationController({
    containerSelector,
    triggerStart = 'top bottom' ,
    triggerEnd = 'top top',
    items,
    showMarkers = false,
    once = false,
    onScroll = true
}) {

    this.log = false;

    // if (this.log) console.log(`%c[animationController]`, 'background:pink', `New instance`, containerSelector);

    this.containerSelector = containerSelector;
    this.triggerStart = triggerStart;
    this.triggerEnd = triggerEnd;
    this.items = items;
    this.showMarkers = showMarkers;
    this.once = once;
    this.onScroll = onScroll;

    this.container = document.querySelector(containerSelector);

    if (!this.items) {
        if (this.log) console.log(`%c[animationController]`, 'background:pink', `No items for ${containerSelector}`);
        return;
    }

    this.resizeDelegate = jstage.addListener("resize", Igloo.delegate(this, this.onResize));
    this.onResize();

    this.animationLabel = `animation${Date.now()}`;

    gsap.registerPlugin(ScrollTrigger);
    this.timeline = gsap.timeline();

    this.calculateContainerBaseDimensions();

    this.animateItemsTimeline();

    this.createAnimation();

}

AnimationController.prototype.calculateContainerBaseDimensions = function() {
    if (this.log) console.log('%c[animationController]', 'background: pink', 'calculateContainerBaseDimensions');

    if (this.container) {
        const newContainerBaseX = this.container.offsetWidth / 2 / 100;
        const newContainerBaseY = this.container.offsetHeight / 2 / 100;
        if (newContainerBaseX !== this.containerBaseX || newContainerBaseY != this.containerBaseY) {
            if (this.log) console.log(this.container.offsetWidth, this.container.offsetHeight)
            this.containerBaseX = newContainerBaseX;
            this.containerBaseY = newContainerBaseY;
            this.setItemsInitialPosition();
            ScrollTrigger.refresh();
        }
    }

}

AnimationController.prototype.setItemsInitialPosition = function() {
    // console.log('setItemsInitialPosition')
    this.items.forEach(({selector, start}) => {
        const item = document.querySelector(selector);
        if(item && start?.x && start?.y) item.style.transform = `translate(${this.containerBaseX * start.x}px, ${this.containerBaseY * start.y}px)`;
    })
}

AnimationController.prototype.createAnimation = function() {
    if (this.container) {

        if(!this.onScroll) {
            this.timeline.pause();
        }

        const settings = {
            trigger: this.containerSelector,
            markers: this.showMarkers,
            start: this.triggerStart,
            end: this.triggerEnd,
            // once: this.once,
            scrub: .5,
            invalidateOnRefresh: true
        }

        if(this.onScroll) {
            settings.animation = this.timeline;
        } else {
            settings.onEnter = () => this.timeline.play();
            if(!this.once) settings.onLeaveBack = () => this.timeline.reverse();
        }

        // this.timeline = gsap.timeline({paused: true});
        this.animation = ScrollTrigger.create(settings);
    }
}

AnimationController.prototype.refresh = function() {
    this.calculateContainerBaseDimensions();
    ScrollTrigger.refresh();
}
AnimationController.prototype.onResize = function() {
    // if (this.log) console.log('%c[animationController]', 'background: pink', 'onResize');
    this.refresh();
}

AnimationController.prototype.animateItemsTimeline = function() {
    if (this.container) {
        this.items.forEach(item => {
            this.animateItemTimeline(item);
        })
    }
}
AnimationController.prototype.animateItemTimeline = function({
    selector,
    start = {},
    end = {},
    delay = 0,
    duration = 1,               // uses the largest number and makes others relative to it (if using ScrollTrigger)
    ease = "sine.inOut",
    options = {},
    toggleClass = {},
    onEnter = () => {},
}) {

    // Eases: https://greensock.com/docs/v3/Eases

    const item = document.querySelector(selector);

    const from = {};
    const to = {};

    options.duration = options.duration || duration;
    options.ease = options.ease || ease;
    options.delay = options.delay || delay;

    // console.log(start, end);

    if ('opacity' in start) from.opacity = () => start.opacity;
    if ('x' in start) from.x = () => this.containerBaseX * start.x;
    if ('y' in start) from.y = () => this.containerBaseY * start.y;

    if ('opacity' in end) to.opacity = () => end.opacity;
    if ('x' in end) to.x = () => this.containerBaseX * end.x;
    if ('y' in end) to.y = () => this.containerBaseY * end.y;


    // console.log('from:', from, 'to:', to);

    this.timeline.fromTo(
        selector,
        from,
        {
            ...to,
            ...options,
            ...toggleClass,
            onEnter,
        },
        this.animationLabel
    );
}
