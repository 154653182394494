
	function ClientPopup(settings){
		this.className			= "ClientPopup";

		this.templateBase = "/clients/popup";

		this.data = {
			pages: [
				{
					name: settings.name,
					onRender: Igloo.delegate(this, function($this) {

						var ClientsController = Aviator.get('clientsController');

						// Copy link to clipboard
						var $clipboardButton = $(".js-share-link");
						$clipboardButton.on('click', Igloo.$d(this, function($this){
							const clipboardText = $this.attr('data-link');
							if(clipboardText){
								ClientsController.copyTextToClipboard(clipboardText);
								$this.next('p').remove();
								$this.after("<p style='font-size:14px; color: #666; margin:5px 0 0;' />");
								$this.next('p').text("Case study link has been copied to your clipboard.").delay(4000).fadeOut(2000);
							}
						}));

					}),
				}
			]
		}

		this.setup(settings);
	};
	ClientPopup.inheritsFrom( PopupForm );

