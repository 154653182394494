function ServiceController(){
	this.className	= "ServiceController";
	this.glob		= ["/services/*/**"];
};
ServiceController.inheritsFrom( StandardController );
ServiceController.prototype.swap = function(){

	var $popupOverlay = $(".js-page-popup");



	if(this.cachedScrollPosition){
		$("html, body").scrollTop(this.cachedScrollPosition);
	}

	//Preload tabs on rollover
	$(".js-sections-tab a").on("mouseover",Igloo.$delegate(this,function($this){
		var href = $this.attr("href");
		jcache.getCache(href);
	}));

	$(".js-booking").off("click touchstart").on("click",Igloo.$d(this,function($this) {
		var meta = this.getMeta();
		this.booking = new BookingForm({values: {Service: meta.service, Section: meta.section}}); // for testing
	}));

	// Articles - Related & Latest
	this.$articles = $(".js-article");

	this.$articles.on("click",'.js-linkup',Igloo.$d(this,function($this){
		var link = $this.parents().find("a:last").attr("href");
		nav.change(link);
	})).css("cursor","pointer");

	this.$articleReadMores = this.$articles.find(".js-article-height");
	

	var readMore = Igloo.delegate(this,function(){
		this.readMores = ReadMore.build({
			$wrapper: 	this.$articleReadMores,
			content: 	".js-content",
			text: 		".js-reduce",
			// readmore: 	".js-readmore",
			dynamic:	true,
		});
	});
	readMore();
	setTimeout(readMore,10);
	setTimeout(readMore,100);
	setTimeout(readMore,500);

	// Read more popup
	this.$clientReadMore = $('.js-read-more');
	this.$clientReadMore.on("click", Igloo.$d(this,function($this){

		var caseStudyPopup = new ClientPopup({
			$overlay: $popupOverlay,
			name: $this.attr("data-name"),
			analytics: {
				category: 'Case Study',
				label: $this.attr("href"),
			}
		});

	}));

	// Read more reveal
	this.$clientRevealMore = $('.js-reveal-more');
	this.$clientRevealMore.on("click", Igloo.$d(this,function($this){

		var $wrapper = $this.parents('.content');
		var $open = $wrapper.filter(".open");
		var isOpen = $wrapper.hasClass("open");
		var $dropdown = $wrapper.find(".js-more-content");

		var d = 500;

		if($open.size()){
			var $openDropdown = $open.find(".js-more-content");
			var o1 = $openDropdown.height();
			$open.removeClass("open");
			$openDropdown.css("height","");
			var o2 = $openDropdown.height();
			$openDropdown.height(o1).animate({height:o2},{duration:d,complete:function(){ $(this).css("height","");}});
			this.$clientRevealMore.text("Read more");
		}

		if(!isOpen){
			$wrapper.addClass("open");
			var h1 = $dropdown.height();
			$dropdown.css("height","auto");
			var h2 = $dropdown.height();
			$dropdown.height(h1).animate({height:h2},{duration:d,complete:function(){ /*$(this).css("height","");*/ }});
			this.$clientRevealMore.text("Close");
		}

	}))

};
ServiceController.prototype.off = function(){

	var csp = false;
	if(!nav.getState().changes[0] && !nav.getState().changes[1]) csp = jstage.getScroll();
	this.cachedScrollPosition = csp;

	this.readMores.destroy();
};
