// was SiteMakeController
function SearchMakeController(){
	this.className		= "SearchMakeController";
	this.debug			= false;
	this.glob		    = '**';
};
SearchMakeController.inheritsFrom( StandardController );

SearchMakeController.prototype.swap = function(  ){
	this.setup();
	// this.active = this.$body.hasClass("search-active");

	// if(this.$headerMenu.hasClass('search-has-input')) {
	//     this.menuLockOverlayOpen();
	// }

	// Hides Header Search for Services pages
	const nextParts = nav.getState().nextParts;
	// const headerSearchNonActive = nextParts[0] === 'services';
	const headerSearchNonActive = (nextParts.length === 3) && nextParts[0] === 'services';
	this.$body.toggleClass("no-header-search", headerSearchNonActive).trigger('classChange');

	return true;
};

SearchMakeController.prototype.menuLockOverlayOpen = function($elem) {

	this.backupScrollPosition = $(window).scrollTop();
	const bodyWidth = this.$jsBody.width();

	this.$body.addClass('search-input-active');
	this.$body.addClass('active-scroll-lock');
	// this.$html.addClass('active-scroll-lock');

	const newBodyWidth 		= this.$jsBody.width();
	const scrollBarWidth 	= newBodyWidth-bodyWidth;

	this.$jsBody.css({
		paddingRight: 	scrollBarWidth, // this makes up for the lack of scroll bar
		top:			-this.backupScrollPosition
	});
	this.$header.css({
		paddingRight:	scrollBarWidth
	});
};

SearchMakeController.prototype.menuLockOverlayClose = function($elem) {

	this.$body.removeClass('search-input-active');
	this.$body.removeClass('active-scroll-lock');

	this.$jsBody.css({
		paddingRight: 	0, // this makes up for the lack of scroll bar
		top:			0
	});
	this.$header.css({
		paddingRight: 0
	});

	// this.backupScrollPosition !== 0 added so page scroll position kept when input has no value
	if(this.backupScrollPosition !== false && this.backupScrollPosition !== 0) {
		$(window).scrollTop(this.backupScrollPosition);
	}
	// reset this.backupScrollPosition
	this.backupScrollPosition = 0;
};

SearchMakeController.prototype.off = function(  ){
	console.log('Off');
	console.log(nav.getState());
	if(!this.isSetup) return;

	// if changing page we need to reset backupScrollPosition as not relevant on new page
	this.backupScrollPosition = false;

	if(this.currentSearchType === "header") this.deactivateSearch(); // the menu is triggered by the menu closing instead

	this.$body.removeClass('search-has-value');
};

SearchMakeController.prototype.setup = function(  ) {

	this.backupScrollPosition = $(window).scrollTop();

	if(this.isSetup) return true;

	this.isSetup 	                = true;
	this.$html 		                = $('html');
	this.$body                      = $('body');
	this.$jsBody	                = this.$body.find('.js-body');
	this.$header                    = this.$body.find(".js-page-header");
	this.$headerMenu                = this.$body.find(".js-header-menu");
	this.$headerSearch              = this.$body.find(".js-header-search");
	this.$holder 					= this.$body.find(".js-site-search:first");
	this.$document                  = $(document);

	this.$input						= this.$holder.find("input");
	// this.$button 			= this.$holder.find("button");

	// NEW HEADER SEARCH
	this.$menuSearchHolder 	        = this.$body.find(".js-site-search");
	this.$menuSearchInput			= this.$menuSearchHolder.find("input");
	this.$menuSearchButton 			= this.$menuSearchHolder.find("button");
	this.$menuOverlay				= this.$body.find(".js-side-nav .js-search-overlay");

	this.$headerSearchWrapper       = this.$body.find('.js-header-search');
	this.$headerSearchHolder 	    = this.$body.find(".js-header-site-search");
	this.$headerSearchInput			= this.$headerSearchHolder.find("input");
	this.$headerSearchButton 		= this.$headerSearchHolder.find("button");
	this.$headerOverlay				= this.$headerSearchWrapper.find(".js-search-overlay");
	// NEW HEADER SEARCH ENDS

	this.$results 			        = this.$body.find(".js-search-results");
	this.$headerResults 			= this.$headerSearchWrapper.find(".js-search-results");

	this.active 			= false;
	this.lastSize 			= false;

	this.menuSearchActive	        = false;
	this.headerSearchActive	        = false;


	// lookup all the search templates
	const $templates = $(`script[type="text/template"][id^="search"]`);
	this.resultTemplates = [];
	$templates.each((i,el) => {
		const id = el.getAttribute("id");
		const name = id.split("/")[1];
		this.resultTemplates[name] = Handlebars.compile(this.getTemplate(id));
		// Handlebars.registerPartial(`search-result-${name}`,  this.templates[id]); // not used?
	});


	this.previousSearch 	= "";
	this.request 			= 0;
	this.latest 			= 0;
	this.cache 				= {};


	// EVENTS

	this.generalKeyDownDelegate = (e) => {

		if(!this.currentSearchType) return;

		// escape key
		if(e.which == 27){
			this.deactivateSearch();
		}
	};

	this.inputDelegate = Igloo.debounce(() => {
		this.update();
	},400);

	this.inputKeyDownDelegate = (e) => {
		// enter / return
		if(e.which == 13){
			e.preventDefault();
			this.update();
		}
	};


	this.$document.on("keydown", this.generalKeyDownDelegate);

	// MENU SEARCH
	this.$menuSearchInput.on("keyup",this.inputDelegate);
	this.$menuSearchInput.on("input",this.inputDelegate);
	this.$menuSearchInput.on("keydown",this.inputKeyDownDelegate);
	this.$menuSearchInput.on("focus", () => {

		// Remove input value in menu search if previous result chosen was from header search
		if(this.previousSearchType === 'header') {
			this.$currentInput.val('');
			this.update();
			// this.$headerMenu.removeClass('search-has-input');
			// this.$menuSearchHolder.removeClass('has-input');
			// this.$menuSearchButton.removeClass('has-input');
		}

		this.activateSearch("menu"); // this doesn't do anything if it's already active
	});


	// HEADER SEARCH
	this.$headerSearchInput.on("keyup",this.inputDelegate);
	this.$headerSearchInput.on("input",this.inputDelegate);
	this.$headerSearchInput.on("keydown",this.inputKeyDownDelegate);
	this.$headerSearchInput.on("focus", () => {
		this.activateSearch("header"); // this doesn't do anything if it's already active
	});


	this.resizeDelegate = Aviator.get('jstage').addListener('resize', (e) => {
		// console.log('resize');
		// Close header search results overlay at < 1001
		const desktopWidth = 1001;
		const w = window.innerWidth;
		if(w < desktopWidth) { // breaks at 1000 if set to < rather than <=
			this.deactivateSearch("header");
		}
	});

	// Header search button
	this.$headerSearchButton.on('click touchstart', (e) => {
		if(e.cancelable){
			e.preventDefault();
			e.stopPropagation();
		}
		this.toggleSearch("header");

	}).on('touchend', e => e.preventDefault() ); //Do not remove

	// listener for menu click in baseController
	Aviator.get("baseController").addListener("menu-toggled", (e) => {

		if(e.state === "on") {
			this.activateSearch("menu")
		}else{
			this.deactivateSearch("menu");

			// remove to show menu icon again
			const valStatus = this.$body.hasClass("search-has-value");
			if(!valStatus) this.$body.removeClass("search-has-value");
		}

	});

	// Menu search button - We do need this to clear the input value when input button X pressed
	this.$menuSearchButton.on('click touchstart', (e) => {
		if(e.cancelable){
			e.preventDefault();
			e.stopPropagation();
		}
		this.toggleSearch("menu");

	}).on('touchend', e => e.preventDefault() ); //Do not remove

	this.update();

};

SearchMakeController.prototype.toggleSearch = function( type ) { // type is "menu" or "header"
	console.log(this.currentSearchType, ' this.currentSearchType');
	if(type === this.currentSearchType) {
		this.deactivateSearch(); //deactivate doesn't need a param
	} else {
		this.activateSearch(type);
	}
};

SearchMakeController.prototype.activateSearch = function( type ) { // type is "menu" or "header"

	// Called from giving the input focus or clicking the search button when it's not yet active

	const previousSearchType = this.currentSearchType;
	if(previousSearchType === type) return; // nothing to do

	// If there is a previous search type, that means the other search is currently open, so we'll need to close it first I guess?
	if(previousSearchType){
		this.deactivateSearch();
	}

	this.currentSearchType = type;

	const isHeader 			= type === "header";
	const isMenu 			= type === "menu";

	this.$currentInput      = isMenu ? this.$menuSearchInput    : this.$headerSearchInput;
	this.$currentHolder     = isMenu ? this.$menuSearchHolder   : this.$headerSearchHolder;
	this.$currentButton     = isMenu ? this.$menuSearchButton   : this.$headerSearchButton;
	this.$currentContainer  = isMenu ? this.$headerMenu         : this.$headerSearch; // todo change this one
	this.$currentOverlay  	= isMenu ? this.$menuOverlay        : this.$headerOverlay;

	if(isHeader){
		this.$body.addClass('search-on');
		// this.menuLockOverlayOpen();
		setTimeout(() => {
			this.$currentInput.focus();
		}, 100);
	}

	if(isMenu){
		this.menuLockOverlayOpen(); // this only happens on menu, because the header does it a bit later (when you start typing)

		// this has probably been triggered from the menu button. It takes a moment before the input is visible enough to give it focus. 100ms should be enough.
		setTimeout(() => {
			//this.$currentInput.focus();
		}, 100);
	}

	const state = true;
	this.$currentContainer.toggleClass("search-active",state).trigger('classChange');
	this.$currentHolder.toggleClass("active",state);
	this.$currentButton.toggleClass("active",state);

	// remembering previous searches
	if(this.savedSearch){
		this.$currentInput.val(this.savedSearch);
		this.update();
	}
};

SearchMakeController.prototype.deactivateSearch = function(  ) {

	// TODO repeated with activateSearch
	const state = false;
	this.$currentContainer.toggleClass("search-active",state).trigger('classChange');
	this.$currentHolder.toggleClass("active",state);
	this.$currentButton.toggleClass("active",state);

	const type				= this.currentSearchType;
	const isHeader 			= type === "header";
	const isMenu 			= type === "menu";

	if(isHeader){
		this.$body.removeClass('search-on');
	}

	if(isMenu){
		// this.menuLockOverlayClose();
	}

	// We do this for both in deactivateSearch(), but note that it's only for the menu in activateSearch()
	this.menuLockOverlayClose();

	this.$currentInput.blur();

	console.log("wait 200 and clear")
	// debugger;
	// Wait 200ms then clear the input and also set this.currentSearchType to false
	this.timeoutRemoveInputVal(200, true);

};

SearchMakeController.prototype.update = function(  ) {
	const $input 	= this.$currentInput;
	if(!$input) return; // panic

	const v 		= $input.val();

	this.request++;

	const hasInput = v.length > 0;
	const hasInputChanged = this.hasInput !== hasInput;

	if(hasInputChanged){
		this.$currentContainer.toggleClass("search-has-input",hasInput).trigger('classChange');
		if(this.currentSearchType === "header") {
			this.$body.toggleClass("search-has-value",hasInput).trigger('classChange');
		}
		this.$currentHolder.toggleClass("has-input",hasInput);
		this.$currentButton.toggleClass("has-input",hasInput);
	}

	// Activate menuLockOverlay open and close for header search
	if(this.currentSearchType === 'header' && hasInput) this.menuLockOverlayOpen();
	if(this.currentSearchType === 'header' && !hasInput) this.menuLockOverlayClose();

	this.hasInput = hasInput;

	if(v !== this.previousSearch){

		if(v) {
			this.savedSearch = false; // clear any saved searches right away (they're only saved when you click a result).

			// Track
			if(gtag) gtag("event", "search", {
				search_term: v
			});

			if(gtag&&0) 	console.log("event","search", {
							search_term: v
						});


			if(this.cache[v]){
				this.latest = this.request;
				this.render(this.cache[v]);
			}else{

				if(!this.previousSearch) this.showLoading();
				$.ajax({
					url: "/search/?ajax=1&q="+encodeURI(v),
					dataType : "json"
				}).success(Igloo.delegate(this,function (ajax_request, results) {
					if(!results || results.length == 0 ) return;

					if(this.latest > ajax_request) return;

					this.latest = ajax_request;
					this.render(results);
					this.cache[v] = results;
				},this.request));
			}
		}else{
			this.render(false);
		}
	}

	this.previousSearch = v;

};

SearchMakeController.prototype.showLoading = function(){
	this.$results.stop().fadeOut(250);
};

SearchMakeController.prototype.hideLoading = function(){
	this.$results.stop().fadeIn(250);
};

SearchMakeController.prototype.render = function(results){

	if(!results) results = {};

	results.results?.forEach((result) => {
		const template = this.resultTemplates[`result-${result.type}`] ?? this.resultTemplates[`result`];
		result.html = template(result);
	});

	const html = this.resultTemplates['results'](results);
	this.$results.html(html);
	this.$results.find(".js-search-result-article").on("click", (e) => {
		e.preventDefault();

		// Identify previous search input used - header or menu
		this.previousSearchType = this.currentSearchType;

		// remove to show menu icon again
		// this.$body.removeClass("search-has-value");
		// this.$body.addClass("tester");

		this.savedSearch 		= this.previousSearch;

		// actual <a> link in results
		const $a = $(e.currentTarget).find(".js-search-result-link");
		const url = $a.attr('href');

		// Track
		if(this.savedSearch){
			if(gtag) gtag("event", "search_result_clicked", {
				search_term: 		this.savedSearch,
				page_location:		url
			});

			if(gtag&&0) 	console.log("event","search_result_clicked", {
				search_term: 		this.savedSearch,
				page_location:		url
			});
		}

		nav.change(url); // sets link through aviator

		// we're going to remember the search for 30 seconds after clicking a result.
		clearTimeout(this.forgetSavedSearchTimeout);
		this.forgetSavedSearchTimeout = setTimeout(() => {
			this.savedSearch = false;
		}, 30 * 1000 ); // 30 seconds

	});

	this.hideLoading();

	this.checkScrollBars();
	setTimeout(()=>this.checkScrollBars(),1000);

}

SearchMakeController.prototype.checkScrollBars = function(timeDelay, clearSearchType = false){
	// check scroll bar
	const showScrollBar = this.$currentOverlay[0].clientHeight < this.$currentOverlay[0].scrollHeight;
	this.$currentOverlay.toggleClass("scroll-required",showScrollBar);
}


SearchMakeController.prototype.timeoutRemoveInputVal = function(timeDelay, clearSearchType = false){
	if(this.timeout) clearTimeout(this.timeout);
	this.timeout = setTimeout(() => {
		this.$currentInput.val('');
		this.update(); // because the events don't get called on the line above so we do this manually
		if(clearSearchType) this.currentSearchType = false;
	}, timeDelay);
};
