
	function FilterController(){
		
		this.className			= "FilterController";
		this.filters			= null;
		this.filtersUI			= null;
		this.searchPlaceholder	= "Search";
	};
	FilterController.abstract = true;
	//FilterController.inheritsFrom( StandardController );

	//Base filter methods

	FilterController.prototype.readFilters = function(){
		//Read current filters
		var state = nav.getState();
		var filters = {};
		var np = state.nextParams;
		for (var i in np) if (np.hasOwnProperty(i)) {
			var arr = np[i].split(",");
			filters[i] = arr;
		}
		this.filters = filters;
	}

	FilterController.prototype.filterAdd = function( key, value, currentFilter) {
		var filter = Igloo.copy(currentFilter);
		if(!filter) filter = [];

		if(currentFilter.hasOwnProperty(key)) {
			for(var i = 0; i < filter[key].length; i++) if(filter[key][i] == value) return filter;
			filter[key].push(value);
		}else{
			filter[key] = [value];
		}

		this.filterHistory = {key:key, value: value};

		return filter;
	}
	FilterController.prototype.filterRemove = function( key, value, currentFilter) {
		var filter = Igloo.copy(currentFilter);
		if(currentFilter.hasOwnProperty(key)) {
			for(var i = 0; i < filter[key].length; i++) if(filter[key][i] == value) filter[key].splice(i,1);
		}
		return filter;
	}
	FilterController.prototype.filterToggle = function( key, value, currentFilter) {
		var filter = Igloo.copy(currentFilter);

		if(currentFilter.hasOwnProperty(key)) {
			for(var i = 0; i < filter[key].length; i++) if(filter[key][i] == value){
				filter[key].splice(i,1);
				return filter;
			}
			filter[key].push(value);
		}else{
			filter[key] = [value];
		}
		this.filterHistory = {key:key, value: value};


		return filter;
	}
	FilterController.prototype.filterUpdate = function( key, value, currentFilter) {
		var filter = Igloo.copy(currentFilter);

		if(value)	filter[key] = [value];
		else		filter[key] = [];

		this.filterHistory = {key:key, value: value};

		return filter;
	}
	FilterController.prototype.filter2query = function( filter ) {

		var query = "";
		for(var i in filter) if(filter.hasOwnProperty(i)){
			if(filter[i].length){
				query += encodeURIComponent(i) + "=";
				for(var j = 0; j < filter[i].length; j++){
					query += encodeURIComponent(filter[i][j])+((j < filter[i].length -1) ? "," :"");
				}
				query += "&";
			}
		}
		if(query.substr(query.length-1,1) == "&") query = query.substr(0,query.length-1);


		return query ? "?"+query : "";
	}

	//UI setup
	FilterController.prototype.setupfiltersUI = function(x){
		//Load in the filter HTML

		var $filterHolder = $(".filter");
		this.$filterHolder = $filterHolder;

		var $filterUls = $filterHolder.find("ul ul");
		var filters = [];
		var prevParent = false;
		$filterUls.each(function (i) {
			var filter = {};
			var $this = $(this);
			filter.$ul 		= $this;
			filter.$title 	= filter.$ul.prev("a");
			filter.title 	= filter.$title.text();
			filter.status 	= filter.$ul.parent().hasClass("active") ? "open" : "closed";
			filter.height	= filter.$ul.height("auto").height();

			filter.$ul.height(filter.status == "closed" ? 0 : filter.height + 50 );
			filter.$ul.css({marginBottom:0});
			filter.$liAll 	= filter.$ul.find("li");
			filter.$li 		= filter.$liAll.not(".parent");
			filter.$parent 	= filter.$ul.find("li.parent");
			filter.items	= [];
			filter.parents 	= [];
			filter.$liAll.each(function () {
				var $this = $(this);
				var isParent = $this.hasClass("parent");
				var $a = $this.find("a, span");
				var _item = {
					$li: $this,
					$a: $a,
					name: $a.attr("name") ? $a.attr("name") : $this.text().toLowerCase(),
					checked: !!$this.attr("checked"),
					visible: true
				};
				if (isParent) {
					_item.children = [];
					filter.parents.push(_item);
				} else {
					_item.parent = filter.parents.length ? filter.parents[filter.parents.length - 1] : false;
					filter.items.push(_item);
					if (filter.parents.length) filter.parents[filter.parents.length - 1].children.push(_item);
				}
			});
			filter.type = $this.attr("class").substr("filter-".length);
			filters.push(filter);
		});
		this.filtersUI = filters;

		//Filter events
		var _this = this;
		for (var i = 0; i < this.filtersUI.length; i++)  var closure = function () {
			var filter = _this.filtersUI[i];
			//Show hide title bit
			//var $item = ;

			for (var j = 0; j < filter.items.length; j++) var closure = function () {

				var filterItem = filter.items[j];

				filterItem.$a.off("click").on("click", function (e) {

					e.preventDefault();
					var uri = nav.getState().nextUrl.split("?")[0];
					var f = _this.filterToggle(filter.type, filterItem.name, _this.filters);
					nav.change(uri + _this.filter2query(f));

				})

			}();


			for (var j = 0; j < filter.parents.length; j++) var closure = function () {
				var filterParent = filter.parents[j];

				filterParent.$a.off("click").on("click", function (e) {

					e.preventDefault();
					var uri = nav.getState().nextUrl.split("?")[0];

					//Check if all the children are checked
					var checked = true;
					for (var k = 0; k < filterParent.children.length; k++) checked = checked && filterParent.children[k].checked;

					var f = Igloo.copy(_this.filters);
					for (var k = 0; k < filterParent.children.length; k++) {
						var child = filterParent.children[k];

						if (!checked)       f = _this.filterAdd(filter.type, child.name, f);
						else                f = _this.filterRemove(filter.type, child.name, f);
					}

					nav.change(uri + _this.filter2query(f));
				})

			}();

			filter.close = function(){
				filter.$ul.css("height",0);
				_this.updateHeight();
				filter.$ul.parent().removeClass("active");
				filter.status = "closed";
			};

			filter.closeFunction = function(){
				if(filter.status == "closed") return;
				filter.$ul.animate({height:0}, {duration:250, progress: Igloo.delegate(_this,_this.updateHeight)});
				filter.$ul.parent().removeClass("active");
				filter.status = "closed";
			}

			filter.openFunction = function(){
				if(filter.status == "open") return;
				filter.$ul.animate({height:filter.height+50}, {duration:350, progress: Igloo.delegate(_this,_this.updateHeight)});//, easing:"easeInOutCubic"
				filter.$ul.parent().addClass("active");
				filter.status = "open";
			}

			filter.$title.off("click").on("click", function (e) {
				e.preventDefault();
				if(filter.status == "closed"){
					filter.openFunction();
				}else{
					filter.closeFunction();
				}
			});

		}();

		//Search
		var _this = this;

		this.$searchInput = $filterHolder.find(".search input");
		this.searchPlaceholder = this.$searchInput.attr('data-default');

		this.updateSearchThrottled = Igloo.throttle( _this.updateSearch, 1, _this);
		this.updateSearchUriDelayed = Igloo.debounce( _this.updateSearchUri, 500, _this);
		this.$searchInput.on("keyup input", function(e){
			_this.updateSearchThrottled($(this).val());
			_this.updateSearchUriDelayed($(this).val());
		});

		this.$searchInput.on("focus", function(e){
			var $this = $(this);
			$this.addClass("hasFocus");

			if($this.val() == _this.searchPlaceholder){
				$this.val("");
			}
			$this.removeClass("default");
		});

		this.$searchInput.on("blur", function(e){
			var $this = $(this);
			$this.removeClass("hasFocus");

			if($this.val() == _this.searchPlaceholder || $this.val() == ""){
				$this.addClass("default");
				$this.val(_this.searchPlaceholder);
			}
		});

		$(document).on("keydown",function(e){ //Todo input

			if( (e.which == 13 || e.which == 169) && _this.$searchInput.is(":focus")) {
				e.preventDefault();
				return false;
			}

		});

		this.filterHistory = false;
		this.updateHeight();

	}

	FilterController.prototype.updateHeight = function(){

		var visible = this.$filterHolder.is(":visible");

		var h 		= visible ? this.$filterHolder.height() : 0;
		var offset 	= this.$filterHolder.offset();

		var details = {

			height:	h,
			top:	offset.top,
			bottom:	offset.top + h
		}
		this.fire("updateFilterHeight",details);
	}


	FilterController.prototype.updateFiltersUI = function(){

		//Loop through the filters, and check for checked or not


		for(var i = 0 ; i < this.filtersUI.length; i++){
			var filter 		= this.filtersUI[i];
			var type 		= filter.type;

			var details		= this.filters[type];
			for(var j = 0 ; j < filter.items.length; j++){
				var filterItem 		= filter.items[j];
				var checked			= !!(details && Igloo.isIn(details,filterItem.name) !== false);

				//console.log(checked + " " + filterItem.checked)

				//Check the child box
				if(filterItem.checked != checked) {
					filterItem.checked = checked;
					if(filterItem.checked) filterItem.$li.addClass("checked"); else filterItem.$li.removeClass("checked");
				}
				//console.log(checked + " " + filterItem.checked)
			}

			for(var j = 0 ; j < filter.parents.length; j++){
				var filterParent	= filter.parents[j];
				var checked = true;
				for (var k = 0; k < filterParent.children.length; k++) checked = checked && filterParent.children[k].checked;

				if(filterParent.checked !== checked) {
					filterParent.checked = checked;
					if(filterParent.checked) filterParent.$li.addClass("checked"); else filterParent.$li.removeClass("checked");
				}

			}

			//Check the parent box - err, should this be in the loop above???
			//Todo - check
			if(filterItem && filterItem.parent && filterItem.parent.checked !== checked){
				filterItem.parent.checked = checked;
				if(filterItem.parent.checked) filterItem.parent.$li.addClass("checked"); else filterItem.parent.$li.removeClass("checked");
			}


			//Now
			for(var k in filter.items){
				if(filter.items[k].checked) {
					filter.openFunction();
					break;
				}
			}

		}

		//Search box
		//if(this.filters[this.searchTerm])
		var defaultText = this.$searchInput.hasClass("hasFocus") ? "" :  this.searchPlaceholder;
		this.$searchInput.val(this.filters[this.searchTerm] ? this.filters[this.searchTerm][0] : defaultText);
		this.updateHeight();

	}
	FilterController.prototype.filtersToWords = function(){

		var lookups = {};
		var location = [];

		for(var i = 0; i < this.meta.filters['locations'].length; i++){
			for(var j = 0; j < this.meta.filters['locations'][i]['cities'].length; j++){
				location.push(this.meta.filters['locations'][i]['cities'][j]);
			}
		}
		lookups['location'] 	= location;
		lookups['discipline'] 	= this.meta.filters.disciplines;
		lookups['position'] 	= this.meta.filters.positions;
		//lookups['pinned']	 	= this.meta.team;

		var str = "";
		for(var filter in this.filters){
			if(!lookups[filter]) continue;
			var details = this.filters[filter];

			if(str) str += "\n";
			str += filter.charAt(0).toUpperCase() + filter.slice(1);
			if(details.length > 1) str += "s";
			str += ": ";

			var inner_str = "";
			for(var i in details){
				var _item = details[i];
				var title = Igloo.searchArray(lookups[filter], "name", _item).title;
				inner_str += (inner_str?", ":"") + title;
			}
			str += inner_str;
		}

		return str;
	}
	FilterController.prototype.filterFilters = function(originalFilter, filterData){

		var locationItems	= filterData.items;

		for(var i = 0; i < locationItems.length; i++){
			var litem	= locationItems[i];
			var name 	= litem.name;
			var count = this.performFilter(this.filters2search(this.filterAdd(filterData.type, name, originalFilter))).length;
			if(!count){
				litem.visible = false;
				litem.$li.hide();
			}
		}
		//Double check the parents
		var parents = filterData.parents;
		for(var i = 0; i < parents.length; i++){
			var found = Igloo.searchArray(parents[i].children,"visible",true);
			if(!found) parents[i].$li.hide();
		}

		//Reset the height
		filterData.height	= filterData.$ul.height("auto").height();
		filterData.$ul.height(filterData.status == "closed" ? 0 : filterData.height + 50 );
	}
	FilterController.prototype.updateSearch = function(query){

		var current = this.searchQuery;


		if(query == this.searchPlaceholder) query = false;

		if(query != current){
			this.searchQuery = query;
			this.filters = this.filterUpdate(this.searchTerm,this.searchQuery, this.filters);
			this.applyFilters();
		}

	}

	FilterController.prototype.updateSearchUri = function(query){


		var uri = nav.getState().nextUrl.split("?")[0];
		nav.change(uri + this.filter2query(this.filters));


	}

	FilterController.prototype.filters2search = function(filters){

		var queries = [];
		for(var i in  filters) if(filters.hasOwnProperty(i) && this.filterDetails[i]) queries.push({label:i,filters:filters[i],type: this.filterDetails[i]['type'], partial: this.filterDetails[i]['partial'], words: this.filterDetails[i]['words']});
		return queries;


	}


