function ServicesController(){
	this.className	= "ServicesController";
	this.glob		= [
		"/services/**",
		"/tests/adr**"
	];
};
ServicesController.inheritsFrom( StandardController );
ServicesController.prototype.off = function(onComplete){
	jstage.removeListener("mousemove",this.mousemoveDelegate);
	//***********************************************************
	// Setting correct colours and opacity for page transition from 
	// ADR wheel choice page to Aim/Deliver/Report for Zero
	var state = nav.getState();
	// var cache = jcache.getCache(state.nextUrl);

	// hiding ADR button
	this.$adrHeaderOverlayButtonContainer.addClass('header-adr-overlay-button-hide');
	this.$adrHeaderOverlayButtonContainer.find(`.adr-icon-${state.previousParts[2]}`).removeClass(`adr-icon-${state.previousParts[2]}--show`);
	this.$adrHeaderOverlayButtonContainer.find(`.adr-icon-${state.previousParts[2]}`).addClass('adr-icon-hide');
	this.$adrHeaderOverlayButtonContainer.removeClass(`adr-service-${state.previousParts[2]}`);

	// hiding ADR Sector Service title
	this.$adrServiceSectorTitle.addClass('service-sector-header-title-hide');

	console.log(state, ' off state.');

	// if sector e.g. factories-and-supply-chain isn't changing
	if(state.changes[1] === false && state.nextParts[1] === state.previousParts[1]) {
		// fading out body and header content
		this.$body.addClass('opacity-0');
		// !this.$body.hasClass('adr-services-menu-on') 
		// 	? this.$body.addClass('opacity-0')
		// 	: this.$body.addClass('opacity-0')
		// adding background colour to transition to, for new page 
		if(state.nextParts.length === 3) {
			this.$body.removeClass('animating');
			this.$body.addClass('transition-background-transparency');

			if(state.previousParts.length === 2) {
				if(state.nextParts[2] === 'aim-for-zero') {
					this.$body.addClass('theme-red');
				} else if(state.nextParts[2] === 'deliver-for-zero') {
					this.$body.addClass('theme-mint');
				} else {
					this.$body.addClass('theme-purple');
				}
			}

			this.$body.removeClass('transition-main-colour');
			this.$body.removeClass('transition-aim-for-zero-colour');
			this.$body.removeClass('transition-report-for-zero-colour');
			this.$body.removeClass('transition-deliver-for-zero-colour');
			this.$body.addClass(`transition-${state.nextParts[2]}-colour`);

		} else if(state.nextParts.length === 2) {
			this.$body.removeClass('transition-main-colour');
			this.$body.removeClass('transition-aim-for-zero-colour');
			this.$body.removeClass('transition-report-for-zero-colour');
			this.$body.removeClass('transition-deliver-for-zero-colour');
			this.$body.addClass('transition-main-colour animating');
			
			// adding background transparency - override exiting background colours for background transition
			this.$body.addClass('transition-background-transparency');
		}
		
		setTimeout(() => {
			onComplete();
		}, 750);

		return false;

	} else if(state.changes[1] === true) {
		this.$pageServices.addClass('disable-pointer');
		!this.$body.hasClass('menu-on') && this.$body.addClass('opacity-0');
		this.$body.removeClass('colour-hue-blue');
		this.$body.removeClass('colour-hue-teal');

		// adding background colour to transition to, for new page 
		if(state.nextParts.length === 3) {
			this.$body.removeClass('animating');
			this.$body.addClass('transition-background-transparency');
			
			if(state.nextParts[2] === 'aim-for-zero') {
				this.$body.addClass('theme-red');
			} else if(state.nextParts[2] === 'deliver-for-zero') {
				this.$body.addClass('theme-mint');
			} else {
				this.$body.addClass('theme-purple');
			}  

		} else if(state.nextParts.length === 2) {
			this.$body.addClass('transition-background-transparency');
			
		} else {	
			this.$body.addClass('transition-background-transparency');
			this.$body.addClass('transition-main-colour');
			this.$body.removeClass('transition-aim-for-zero-colour').removeClass('theme-red');  
			this.$body.removeClass('transition-report-for-zero-colour').removeClass('theme-purple');
			this.$body.removeClass('transition-deliver-for-zero-colour').removeClass('theme-mint');
			this.$body.addClass('colour-hue-teal');
		}

		setTimeout(() => {
			onComplete();
		}, 750);
		return false;
		
	} else {
		return true;
	}
	// Setting correct colours and opacity for page transition from 
	// ADR wheel choice page to Aim/Deliver/Report for Zero ends
	
};
ServicesController.prototype.swap = function(){

	const headerController = Aviator.get("headerController");
	// headerController.say('hello');

	this.$html 		= $('html');
	this.$body 		= $('body');
	this.$jsBody	= this.$body.find('.js-body');
	this.$header	= this.$body.find('.js-page-header');
	this.$headerTop = this.$header.find('.js-header-top');
	this.$menu 		= $('.js-services-menu');
	this.$menuLinks = this.$menu.find('li');
	this.$index		= $('.js-service-index');
	
	this.$pageServices = this.$body.find('.js-page-services');

	this.$pageWrapper	= this.$body.find('.js-page-wrapper');
	this.$adrMenuWrapper	= this.$body.find('.js-adr-menu-wrapper');
	this.$adrSegmentsWrapper	= this.$body.find('.js-adr-segments-wrapper');
	this.$adrSegments	= this.$adrMenuWrapper.find('.js-adr-segment');

	this.$adrButtons	= this.$adrMenuWrapper.find('.js-adr-button');
	this.$adrButtonLinkRoot	= this.$adrButtons.attr('href');
	this.$adrAimForZeroButton	= this.$adrMenuWrapper.find('.js-button-adr-aim-for-zero');
	
	this.$adrServiceSectorTitle	= this.$body.find('.js-service-sector-title');
	this.$adrServiceSectorTitleContent	= this.$body.find('.js-service-sector-header-title-content');
	this.$adrHeaderOverlayButtonContainer	= this.$body.find('.js-header-adr-overlay-button');
	this.$adrHeaderOverlayOpenButton	= this.$body.find('.js-adr-open-button');
	this.$adrHeaderOverlayCloseButton   = this.$body.find('.js-adr-close-button');
	this.$adrHeaderOverlayMenu = this.$body.find('.js-adr-services-overlay-menu');
	this.$adrHeaderOverlayMenuAnimate = this.$adrHeaderOverlayMenu.find('.js-animate');
	this.$adrHeaderOverlayMenuHeight = this.$body.find('.js-adr-height');
	this.$servicesPageHeader = this.$body.find('.js-page-header');
	this.$pageServicesHeader = this.$body.find('.js-page-services-header');
	this.$servicesPageHeaderContent = this.$body.find('.js-header-content');
	this.$servicesPageAdr = this.$body.find('.js-page-adr');
	
	this.$page = this.$body.find('.js-page');

	// Header Nav - Setting correct icon for sector title in nav
	this.$pageSectorIcon = this.$page.attr("data-sector-icon");
	this.$headerSectorIcons = this.$headerTop.find('.js-sector-icon');

	// show correct sector icon in header
	console.log(this.$pageSectorIcon);
	// this.$headerSectorIcon = this.$headerSectorIcons.hide().filter(`[data-sector-icon=${this.$pageSectorIcon}]`).show();
	// this.$headerSectorIcon = this.$headerSectorIcons.attr('data-sector-icon', this.$pageSectorIcon).show();

	// Setting correct colours, opacity, title content when loading new page for chosen ADR section start
	var state = nav.getState();
	console.log(state, ' state in swap');

	// getting correct sector title on chosen service page
	this.getMeta();
	this.$adrServiceSectorTitleContent.text(this.meta['sector-title']);
	// console.log(this.meta['sector_icon'], ' this.meta');

	// enable pointer events
	this.$pageServices.removeClass('disable-pointer');

	if(state.changes[1] === false && state.nextParts[1] === state.previousParts[1] || state.changes[1] === true && state.nextParts[0] === 'services') {
		
		if(!state.changes[1] || state.nextParts.length === 2) {
			this.$body.addClass('opacity-0');
			this.$body.addClass('instant-opacity-transition');
			setTimeout(() => {
				this.$body.removeClass('opacity-0');
				this.$body.removeClass('instant-opacity-transition');
			}, 1);
			
			// removing background transparency - override exiting background colours for background transition
			this.$body.addClass('transition-background-transparency');
			setTimeout(() => {
				this.$body.removeClass('transition-background-transparency');
			}, 1);
		}

		window.scrollTo(0, 0);

		// enable pointer for Sector page
		this.$pageServices.removeClass('disable-pointer');

		if(state.nextParts.length === 3) {

			// show title and icon in header 
			this.$headerSectorIcon = this.$headerSectorIcons.attr('data-sector-icon', this.$pageSectorIcon).show();
			this.$adrServiceSectorTitle.removeClass('service-sector-header-title-hide');
			
			if(state.previousParts.length === 1 && state.nextParts.length === 3) {
				this.$body.addClass('opacity-0');
				setTimeout(() => {
					this.$body.removeClass('opacity-0');
				}, 1);
				this.$body.addClass(`transition-${state.nextParts[2]}-colour`);
				
				

				// removing background transparency - override exiting background colours for background transition
				this.$body.addClass('transition-background-transparency');
				setTimeout(() => {
					this.$body.removeClass('transition-background-transparency');
				}, 1);
			}

			if(!state.changes[1]) {
				this.$adrServiceSectorTitle.removeClass('service-sector-header-title-hide');
				this.$body.addClass(`transition-${state.nextParts[2]}-colour`);
				this.$body.removeClass('transition-main-colour');

				// change background colour on ADR button in header
				this.$adrHeaderOverlayButtonContainer.removeClass(`adr-service-${state.previousParts[2]}`);
				this.$adrHeaderOverlayButtonContainer.addClass(`adr-service-${state.nextParts[2]}`);
				this.$adrHeaderOverlayButtonContainer.find(`.adr-icon-${state.nextParts[2]}`).removeClass('adr-icon-hide');
				this.$adrHeaderOverlayButtonContainer.find(`.adr-icon-${state.nextParts[2]}`).addClass(`adr-icon-${state.nextParts[2]}--show`);
				state.previousParts[2] !== state.nextParts[2] && this.$adrHeaderOverlayButtonContainer.find(`.adr-icon-${state.previousParts[2]}`).removeClass(`adr-icon-${state.previousParts[2]}--show`);
				state.previousParts[2] !== state.nextParts[2] && this.$adrHeaderOverlayButtonContainer.find(`.adr-icon-${state.previousParts[2]}`).addClass('adr-icon-hide');
				
				// hide logo on chosen service page
				this.$pageServicesHeader.find('.js-header-top').addClass('page-services-header-hide-logo');
			}

		} else if(state.nextParts.length === 2) {
			this.$body.addClass('transition-main-colour');
			this.$body.removeClass(`transition-${state.nextParts[2]}-colour}`);
			this.$adrServiceSectorTitle.addClass('service-sector-header-title-hide');

			// change background colour on ADR button in header
			this.$adrHeaderOverlayButtonContainer.find(`.adr-icon-${state.previousParts[2]}`).removeClass(`adr-icon-${state.previousParts[2]}--show`);
			this.$adrHeaderOverlayButtonContainer.find(`.adr-icon-${state.previousParts[2]}`).addClass('adr-icon-hide');
			this.$adrHeaderOverlayButtonContainer.removeClass(`adr-service-${state.previousParts[2]}`);

			// hide logo on chosen service page
			this.$pageServicesHeader.find('.js-header-top').removeClass('page-services-header-hide-logo');
		} 

		// hide and show the Change Service button in the header
		if(this.meta['has_single_service']) {
			this.$adrHeaderOverlayButtonContainer.addClass('header-adr-overlay-button-hide');
			this.$adrServiceSectorTitle.removeClass('service-sector-header-title-hide');

			// hide logo on chosen service page
			this.$pageServicesHeader.find('.js-header-top').addClass('page-services-header-hide-logo');
		} else if(state.changes[1] === false && state.nextParts[2]) {
			this.$adrHeaderOverlayButtonContainer.removeClass('header-adr-overlay-button-hide');
		} else  {
			this.$adrHeaderOverlayButtonContainer.addClass('header-adr-overlay-button-hide');
		}; 

	} 
	// Setting correct colours, opacity, title content when loading new page for chosen ADR section ends


	this.$menuLinks.on('touchstart', Igloo.$delegate(this,function($this){
		$this.addClass("has-touch");
	}));

	// ADR
	this.$adrSegments.off().on({
		'mouseenter': (e) => {
			const $this = $(e.currentTarget);
			this.adrEnter($this);
		},
		'mouseleave': (e) => {
			const $this = $(e.currentTarget);
			this.adrLeave($this);
		},
		'click': (e) => {
			// Aviator page change - didnt get picked up initially due to links being in svg
			e.preventDefault();
			const href = e.currentTarget.getAttribute('href');
			nav.change(href);
			this.adrOverlayClose();
		}
	});

	this.$adrButtons.off().on({
		'mouseenter': (e) => {
			const $this = $(e.currentTarget);
			this.adrEnter($this);
		},
		'mouseleave': (e) => {
			const $this = $(e.currentTarget);
			this.adrLeave($this);
		},
		'click': (e) => {
			// Aviator page change - didnt get picked up initially due to links being in svg
			e.preventDefault();
			const href = e.currentTarget.getAttribute('href');
			nav.change(href);
			this.adrOverlayClose();
		}
	});

	this.$adrHeaderOverlayOpenButton.off().on({
		'click': (e) => {
			this.adrOverlayOpen();
		}
	});	

	this.$adrHeaderOverlayCloseButton.off().on({
		'click': (e) => {
			this.adrOverlayClose();
			this.$adrHeaderOverlayMenu.animate({scrollTop : 0},1200);
		}
	});	
	// ADR

	this.$menuLinks.on('mouseenter', Igloo.$delegate(this,function($this){

		if($this.hasClass("has-touch")) return;

		$this.addClass("hover");

		var colour = $this.attr("data-colour");
		this.hover(colour);

		// Position tooltip
		var $tooltip = $this.find('.js-content');
		var a = $tooltip.offset().top;
		var b = $tooltip.height();
		var c = $(window).height();
		var d = $(window).scrollTop();
		if((c+d)<(a+b)) {
			$this.addClass('pos-top');
		}

		var href = $this.find("a").attr("href");
		jcache.getCache(href);

	}));

	this.$menuLinks.on('mouseleave click', Igloo.$delegate(this,function($this){
		$this.removeClass("hover").removeClass("pos-top");
		this.hover(false);
	}));


	//This code ensures that we're not rolled over something right away.

	this.originalMouse = jstage.getMouse();
	if(this.originalMouse.x > 0 || this.originalMouse.y > 0){
		this.$index.css("pointer-events","none");
		this.mousemoveDelegate = jstage.addListener("mousemove",Igloo.delegate(this,function(e){
			var d = Math.pow(this.originalMouse.x - e.x,2) + Math.pow(this.originalMouse.y - e.y,2);
			if(d > 1000){
				this.activate();
			}
		}));
		this.$index.parent().on("mousedown touchstart click",Igloo.delegate(this,function(){
			this.activate();
		}));
	}


};


// ADR 
ServicesController.prototype.adrReset = function($elem){
	const $svgWrapper	= $elem.find('.js-svg-wrapper');
	const $buttonsWrapper	= $elem.find('.js-buttons-wrapper');

	$svgWrapper.attr('class', 'svg-wrapper js-svg-wrapper');
	$buttonsWrapper.attr('class', 'cont-12 js-buttons-wrapper');
};

// for ADR circle nav and buttons
ServicesController.prototype.adrEnter = function($elem){

	const $parent = $elem.parents('.js-adr-menu-wrapper');
	const $adrSegmentsWrapper	= $parent.find('.js-adr-segments-wrapper');
	const $svgWrapper	= $parent.find('.js-svg-wrapper');
	const $adrDescription = $parent.find('.js-svg-wrapper-center-text');
	const $buttonsWrapper	= $parent.find('.js-buttons-wrapper');

	this.adrReset($parent);
	$svgWrapper.addClass(`show-${$elem.context.getAttribute('data-selection')}`);
	$buttonsWrapper.addClass(`show-${$elem.context.getAttribute('data-selection')}`);

	if($elem[0].getAttribute('data-type') === 'adr-segment') {

		// change opacity on hover
		$svgWrapper.find('a').css({'opacity': 0.5});
		$elem.css({'opacity': 1});
		
		// move selected segment to end - changing order
		$elem.appendTo($adrSegmentsWrapper);
		
		// button styling when hover over adr-segment 
		$buttonsWrapper.find(`[data-selection="${$elem.context.getAttribute('data-selection')}"]`).addClass('adr-hover');

		// centre text changes
		$adrDescription.find('p').addClass('hide');
		$adrDescription.find(`p[data-selection=${$elem[0].getAttribute('data-selection')}]`).removeClass('hide');

	} else if($elem[0].getAttribute('data-type') === 'adr-button') {

		// change opacity on hover
		$svgWrapper.find('a').css({'opacity': 0.5});
		$svgWrapper.find(`a[data-selection="${$elem.context.getAttribute('data-selection')}"]`).css({'opacity': 1});

		// button styling when hover over button 
		$elem.addClass('adr-hover');

		// move selected segment to end - changing order
		$svgWrapper.find(`a[data-selection="${$elem.context.getAttribute('data-selection')}"]`).appendTo($adrSegmentsWrapper);

		// centre text changes
		$adrDescription.find('p').addClass('hide');
		$adrDescription.find(`p[data-selection=${$elem[0].getAttribute('data-selection')}]`).removeClass('hide');
	}	
};

ServicesController.prototype.adrLeave = function($elem){
	const $parent = $elem.parents('.js-adr-menu-wrapper');
	const $svgWrapper	= $parent.find('.js-svg-wrapper');
	const $adrDescription = $parent.find('.js-svg-wrapper-center-text');
	const $buttonsWrapper	= $parent.find('.js-buttons-wrapper');

	this.adrReset($parent);
	if($elem[0].getAttribute('data-type') === 'adr-button') {
		$svgWrapper.find('a').css({'opacity': 1});

		$elem.removeClass('adr-hover');

		$adrDescription.find('p').addClass('hide');
		$adrDescription.find('p:first-of-type').removeClass('hide');
	} else if($elem[0].getAttribute('data-type') === 'adr-segment') {
		$svgWrapper.find('a').css({'opacity': 1});
		
		$buttonsWrapper.find(`[data-selection="${$elem.context.getAttribute('data-selection')}"]`).removeClass('adr-hover');

		$adrDescription.find('p').addClass('hide');
		$adrDescription.find('p:first-of-type').removeClass('hide');
	}
};

ServicesController.prototype.adrOverlayOpen = function($elem) {

	this.backupScrollPosition = $(window).scrollTop();
	const bodyWidth = this.$jsBody.width();

	this.$body.addClass('adr-services-menu-on');
	this.$body.find('.adr-open-button').addClass('adr-button-hide');
	this.$body.find('.adr-close-button').removeClass('adr-button-hide');
	this.$body.addClass('adr-services-menu-on-scroll-lock');
	this.$html.addClass('adr-services-menu-on-scroll-lock');

	const newBodyWidth 		= this.$jsBody.width();
	const scrollBarWidth 	= newBodyWidth-bodyWidth;

	this.$adrHeaderOverlayMenuAnimate.css("height","auto");
	const menuHeight		= this.$adrHeaderOverlayMenuAnimate.height();
	this.$adrHeaderOverlayMenuAnimate.height(0);
	this.$adrHeaderOverlayMenuAnimate.height(menuHeight);

	console.log(scrollBarWidth, ' scrollBarWidth serv');
	this.$jsBody.css({
		paddingRight: 	scrollBarWidth, // this makes up for the lack of scroll bar
		top:			-this.backupScrollPosition
	});
	this.$servicesPageHeader.css({
		paddingRight:	scrollBarWidth
	});
};

ServicesController.prototype.adrOverlayClose = function($elem){

	if(!this.$body.hasClass("adr-services-menu-on")) return;

	this.$body.removeClass('adr-services-menu-on');
	this.$body.find('.adr-open-button').removeClass('adr-button-hide');
	this.$body.find('.adr-close-button').addClass('adr-button-hide');
	this.$body.removeClass('adr-services-menu-on-scroll-lock');
	this.$html.removeClass('adr-services-menu-on-scroll-lock');

	this.$adrHeaderOverlayMenu.css("display","block");

	setTimeout(() => {
		this.$adrHeaderOverlayMenu.css("display","");
	},500);

	this.$adrHeaderOverlayMenuAnimate.height(0);

	this.$jsBody.css({
		top: 0,
		paddingRight: 0
	});
	this.$servicesPageHeader.css({
		paddingRight: 0
	});

	$(window).scrollTop(this.backupScrollPosition);
};
// ADR

ServicesController.prototype.activate = function(){
	this.$index.css("pointer-events","");
	jstage.removeListener("mousemove",this.mousemoveDelegate);
};

ServicesController.prototype.hover = function(colour){
	if(!colour){
		this.$body.removeClass(this.makeThemeClass(this.hovering));
		this.$menu.removeClass('is-hovering');
		this.hovering = false;
	}else{
		this.hovering = colour;
		this.$body.addClass(this.makeThemeClass(colour));
		this.$menu.addClass('is-hovering');
	}
};

ServicesController.prototype.makeThemeClass = function(colour){
	return "colour-hue-" + colour;
};

ServicesController.prototype.on = function(){
	this.$adrSegments.each((i, e) => {
		var href = e.getAttribute('href');
		jcache.getCache(href);
	});
};

