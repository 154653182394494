
	//Notes
	//We can have elements, that a controller has, and can share.
	//We should consider if they're single or multiple and how multiples are shared
	//When a page change happens we need to work out how to keep the elements. Remove them from DOM but save them, then readd them perhaps?

	function StandardMenuController(){
		
		this.className			= "StandardMenuController";
		this.menuItems			= [];
		this.menuSelector		= ".";
		this.menuSelectedClass 	= "active";
		this.$menuHolder 		= null;
		
	};
	StandardMenuController.abstract = true;
	StandardController.inheritsFrom( EventTarget );
	StandardMenuController.inheritsFrom( StandardController );
	
	StandardMenuController.prototype.swap = function( onComplete ){	
		this.standardMenuSwap(true);
	}
	StandardMenuController.prototype.standardMenuSwap = function( setState, forceRefresh, performSelect ){
		var state = nav.getState();
		if(this.info.on || forceRefresh){
			this.gatherMenuItems();
			this.addTouchEvents();
		} //check
		//Igloo.log(performSelect + " " + this.className);
		if(performSelect !== false) this.selectMenuItemByUrl( state.nextUrl );
		if(setState) this.setState(true);
	}
	StandardMenuController.prototype.gatherMenuItems = function( ){

		this.$menuHolder = $(this.menuSelector);
		this.menuItems = this.gatherMenuLevel( this.$menuHolder  );
		return this.menuItems;
		
	}
	StandardMenuController.prototype.gatherMenuLevel = function( $menuHolder  ) { //level
		var menuLevel = [];

		var _this = this;
		//Igloo.log();

		var $firstLevelAs = $menuHolder.find("a").filter(function(){
			return $(this).parentsUntil($menuHolder,"li").length == 1;
		});

		var $firstLevelAs = $firstLevelAs.filter(function(){
			return $(this).parentsUntil($menuHolder,"nav").length == 0;
		});

		$firstLevelAs.each(function(i){

			var $t 		= $(this);
			var m 		= {};
			m.text 		= $t.html();
			m.index 	= i;
			m.href		= Jnav.cleanUrl($t.attr("href"));
			m.glob		= m.href + (i?"**":""); //this isn't great...
			m.regex		= Igloo.glob2regex(m.glob);
			var $li		= $t.parent("li");
			m.$li		= $li.size()?$li:false;
			m.$a		= $t;
			m.$node		= (m.$li?m.$li:m.$a);
			m.selected	= m.$node.hasClass(_this.menuSelectedClass);

			m.$sub		= $li.find("nav").first();
			m.hasSub	= m.$sub.size() > 0;

			m.subMenu	= m.hasSub ? _this.gatherMenuLevel(m.$sub) : [];
			menuLevel.push(m);
		});

		return menuLevel;
	}
	StandardMenuController.prototype.addTouchEvents = function(  ) {
		//this.menuItems
	}
	StandardMenuController.prototype.getMenuHolder = function(  ){
		return this.$menuHolder;
	}
	StandardMenuController.prototype.getMenuItemByUrl = function( url, loose ){
		var cleanUrl = Jnav.cleanUrl(url);

		var menuItemExact;
		if(!loose){
			menuItemExact = Igloo.searchArray(this.menuItems,"href",cleanUrl);
		}else{
			menuItemExact = Igloo.searchArrayGlob(this.menuItems,"href",cleanUrl+"*");
		}


		if(!menuItemExact){
			var menuItem = false;
			for(var i = 0; i < this.menuItems.length; i++){
				var result = url.match( this.menuItems[i].regex );
				if(result) menuItem = this.menuItems[i];
			}


			return menuItem;
		}
		return menuItemExact;	
	}
	StandardMenuController.prototype.getSelectedMenuItem = function( ){
		
		for(var i = 0; i < this.menuItems.length; i++){
			var m = this.menuItems[i];
			if(m.selected) return m;
		}
		
		return false;
	}
	StandardMenuController.prototype.getNextMenuItem = function( ){
		
		var selected = this.getSelectedMenuItem();
		if(selected){
			var index = selected.index;
			if(this.menuItems.length > index) return this.menuItems[index+1];
		}
		return false;
	}
	StandardMenuController.prototype.getPreviousMenuItem = function( ){
		
		var selected = this.getSelectedMenuItem();
		if(selected){
			var index = selected.index;
			if(index > 0) return this.menuItems[index-1];
		}
		return false;
	}
	StandardMenuController.prototype.selectMenuItemByUrl = function( url ){
		var menuItem = this.getMenuItemByUrl( url, true );
		return this.selectMenuItem( menuItem );
	}
	StandardMenuController.prototype.selectMenuItem = function( menuItem ){

		//Igloo.log("SELECT " + this.className)
		//Igloo.log(menuItem)

		for(var i = 0; i < this.menuItems.length; i++){
			var m = this.menuItems[i];
			if(m.selected){
				m.$node.removeClass(this.menuSelectedClass);
				m.selected = false;
			}
		}
		if(menuItem){
			menuItem.$node.addClass(this.menuSelectedClass);
			menuItem.selected = true;
		}
		
		return menuItem !== false;
	};