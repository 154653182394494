function ImageController( settings ){
	if(!settings) settings = {};

	this.className	= "ImageController";
	this.version 	= 3;
	this.base		= "/assets/images/";
	this.versions		= {
		"team":	[{w:150},{w:300},{w:450},{w:600,f:"full"}],
		//"about":	[{w:150},{w:300},{w:450},{w:1000,f:"full"}],
		// "careers":	[{w:150},{w:300},{w:450},{w:600,f:"full"}],
	};

	this.images 		= [];
	this.resizeDelegate = jstage.addListener("resize",Igloo.delegate(this,this.resize));
	this.resizeInstantDelegate = jstage.addListener("resizeInstant",Igloo.delegate(this,this.resizeInstant));

	this.requireAll		= "requireAll" in settings ? settings.requireAll : false;

	this.preloadIds 	= [];

	this.status 		= "active";

};
ImageController.count = 0;
ImageController.inheritsFrom( EventTarget );
ImageController.prototype.$process = function( $html ){


	var $images 	= $html.find("img[src-wait]:not(.img-resized-version)");
	$images.each(Igloo.$d(this,function($image){


		var src				= $image.attr("src-wait");
		var folder 			= this.getFolderFromUri(src);
		if(!(folder in this.versions)) return this.$processSimpleImage($image);

		var ext				= this.getExtFromUri(src);
		var class_str 		= $image.attr("class")?$image.attr("class"):"";
		var alt_str 		= $image.attr("alt")?$image.attr("alt"):"";
		var classes			= class_str.split(" ");
		var centre			= $image.attr("data-centre");
		var centreElement	= $image.attr("data-centre-element");
		var ratio 			= $image.attr("data-ratio");
		var generateSettings	= $image.attr("data-generate-settings");

		var style = ratio ? 'style="padding-bottom:'+(100/ratio)+'%"' : "";
		var index = ImageController.count;
		var inherited_class_string = ""; for (var i in classes) inherited_class_string += " inherited-replace-" + Text.trim(classes[i]);

		var inner = '<div class="img-replace img-replace-'+ index +' img-replace-loading '+inherited_class_string+'" '+style+' ><div class="img-replace-loader"></div>' + '<img class="img-default img-resized-version '+class_str+'" alt="'+alt_str+'" style="opacity:0;" />' + '</div>';
		$image.replaceWith(inner);
		var $element = $html.find('.img-replace-'+ index);


		var details = {
			index:		index,
			type:		"img",
			ext:		ext,
			original: 	src,
			folder:		folder,
			classes: 	classes,
			img_class:	class_str,
			img_alt:	alt_str,
			centre: 	centre,
			resize:		Igloo.isIn(classes,"blockresize") === false,
			selector:	".img-replace-" + index,
			processed: 	false,
			generateSettings: generateSettings,
			startTime:  +new Date()
		//$element: 	$element
		}

		ImageController.count++;

		this.images.push(details)

		$image.addClass("img-resized-version");


	}));

	if(this.images){
		//Go through these, find the default and preload it.
		var orientation = jstage.getWidth() > jstage.getHeight() ? "landscape" : "portrait";
		for(var i = 0, ii = this.images.length; i < ii; i++){
			var image = this.images[i];

			if(image.processed) continue;
			image.processed = true;
			image.versions 	= this.versions[image.folder];


			if(image.versions === true){
				image.default 	= image.original;
				image.expected 	= image.original;
			}else{
				var orientated_version = image.versions[0];
				for(var j in image.versions){
					var key = orientation == "landscape" ? "w" : "h";
					if(key in image.versions[j]) {
						orientated_version = image.versions[j];
						break;
					}
				}
				var size = orientated_version.p?orientated_version.p:orientated_version["w" in orientated_version ? "w" : "h"];
				image.default 	= this.translateSrc(image.original,size,image);
				image.expected 	= this.getBestVersion(image.folder,0,0);
			}




			var expected_src = this.translateSrc(image.original, image.expected,image);
			if(Preloader2.checkSrc(expected_src)){
				image.default 	= expected_src;
				image.size		= image.expected;
			}


			image.sizes = [{src: image.default, size: image.size , loaded: false}];

			image.count = 1;

			image.required = (this.requireAll && Igloo.isIn(image.classes,"nopreload") === false ) || Igloo.isIn(image.classes,"preload") !== false;

			//Preload the default?
			//Perhaps not worth it if we're just going to render anyway. Although required for sizing?

			//Load the first one priority 1, top
			Preloader2.add({src: image.default, priority: image.required ? 1 : 3, top: image.required, tag: "page"}).then(Igloo.delegate(this,this.imagePreloaded,image,image.sizes[0]));
			this.preloadIds.push(Preloader2.getLastId());
			//Igloo.log(image.default)
		}
	}else{
		this.checkReady();
	}
	this.identifyElements($html);

	return this.images.length > 0
}
ImageController.prototype.$processSimpleImage = function( $image ){
	var src = $image.attr("src-wait");
	if(src) $image.attr("src",src);
}
ImageController.prototype.identifyElements = function($container){
	//We should check through everything that's not yet defined, and see if we can find it.
	for(var i = 0, ii = this.images.length; i < ii; i++){
		var image = this.images[i];
		if(!image.$element){
			var $el = $container.find(image.selector);

			if($el.size()){
				image.$imageSizer = $el.parents(".js-image-sizer").first();

				image.$element 	= $el;
				image.$wrapper 	= $el.parent();
				image.$loader 	= $el.find(".img-replace-loader");
				image.$loader.css("opacity",1);
				var size = image.sizes[0];
				size.$img = $el.find("img");
				image.$element.attr("data-default",image.default)
				size.onCached = Igloo.delegate(this,this.inlineTrigger,size,image);
				if(size.cached){
					size.onCached();
				}
			}
		}
	}
//	this.checkReady();
}
ImageController.prototype.imagePreloaded = function(imageDetails,size,preloadDetails){


	if(!preloadDetails){
		//Igloo.log("failed / been cancelled, ignore")
		return false;
	}



	var src = preloadDetails.src;

	if(size.cached) return false;

	if(preloadDetails.status == "loaded"){
		size.cached = true; //What about on error?
		size.preload = preloadDetails;
		size.width = preloadDetails.width;
		size.height = preloadDetails.height;
		size.ratio = preloadDetails.width/preloadDetails.height;
		size.loadEndTime = preloadDetails.endTime;
		if(size.onCached) size.onCached();
	}else{
		size.cached = true;
		size.loaded = true;
		imageDetails.loaded = true; //TODO
	}


}
ImageController.prototype.inlineTrigger = function(sizeDetails, imageDetails){

	//Might need to ensure this isn't run twice

	var src = sizeDetails.src;
	var $img = sizeDetails.$img;
	Preloader2.addInlineTrigger(sizeDetails.$img,Igloo.delegate(this,function(details){

		if($img.hasClass("img-default")){
			if(sizeDetails.loadEndTime  - imageDetails.startTime < 100){ //Animate it unless it was (probably) cached
				$img.css("opacity",1);
			}else{
				$img.animate({opacity:1},{duration:500});
			}

		}

		imageDetails.$element.attr("style","");
		imageDetails.$element.removeClass("img-replace-loading");
		imageDetails.$loader.css("opacity",0);
		sizeDetails.loaded = true;
		imageDetails.loaded = true;
		if(sizeDetails.onLoaded) sizeDetails.onLoaded();
		this.resizeImage(imageDetails);
		this.checkReady(); //This might want to wait

	}));
	sizeDetails.$img.attr("src",sizeDetails.src).attr("width",sizeDetails.width).attr("height",sizeDetails.height);

}
ImageController.prototype.checkReady = function(){
	if(this.ready) return true;
	var ready = true;
	for (var i = 0, ii = this.images.length; i < ii; i++) {
		var image = this.images[i];
		if(image.required && !image.loaded) return false;
	}
	//Otherwise
	this.ready = true;
	this.fire("ready");

	return true;

}
ImageController.prototype.resize = function(){
	for (var i = 0, ii = this.images.length; i < ii; i++) {
		var image = this.images[i];
		this.resizeImage(image);

	}
}
ImageController.prototype.resizeInstant = function(){
	for (var i = 0, ii = this.images.length; i < ii; i++) {
		var image = this.images[i];
		this.resizeImage(image,false);

	}
}
ImageController.prototype.resizeImage = function(image, checkForVersions){


	//We have to work out how big the image is supposed to be
	if(!image.loaded) return;

	this.fire("refresh");

	var $centreElement = image.centreElement == "div" ? image.$element : image.sizes[image.sizes.length-1].$img;


	if(image.type == "img"){
		image.$element.css("width","").css("height","");
		for (var i = 0, ii = image.sizes.length; i < ii; i++) {
			var size = image.sizes[i];
			if(size.$img && size.$img.attr("src")){
				size.$img.css({width:"",height:""});
				var x = 0;
				var y = 0;
				var w = size.$img.width();
				var h = size.$img.height();
				var cw = w;
				var ch = h;

				if(image.$imageSizer.length){
					cw = image.$imageSizer.width();
					ch = image.$imageSizer.outerHeight();
				}

			}
		}

		image.$element.width(cw).height(ch);
		for (var i = 0, ii = image.sizes.length; i < ii; i++) {
			var size = image.sizes[i];
			if(size.$img) size.$img.width(cw).height(ch);
		}


	}else{
		//This should work for backgrounds
		for (var i = 0, ii = image.sizes.length; i < ii; i++) {
			var size = image.sizes[i];
			if(size.$img) size.$img.hide();
		}

		image.$element.css("width","").css("height","");

		var wv = image.$wrapper.css("display") !== "none";
		var style = image.$wrapper.attr("style");
		var inStyle = style?style.indexOf("none")>0:false;

		if(!wv) image.$wrapper.css("display","block");

		var cw      = image.$element.width();
		var ch      = image.$element.height();

		if(!wv) image.$wrapper.css("display",inStyle ? "none" : "");

		var x, y, w, h;
		for (var i = 0; i < ii; i++) {
			var size = image.sizes[i];
			if(cw/ch > size.ratio){
				w = cw;
				h = w / size.ratio;
			}else{
				h = ch;
				w = h * size.ratio;
			}

			if(image.centreElement == "img") {
				x = (cw - w)*image.centre[0]/100;
				y = (ch - h)*image.centre[1]/100;
			}else {
				x = (cw - w)/2;
				y = (ch - h)/2;
			}

			//Note that we're not using any of the pos-br stuff currently...
			var positioning = "centre";
			if(Igloo.isIn(image.classes,"if-full-bottom") !== false){
				if(cw == jstage.getWidth()){
					positioning = "bottom";
				}
			}
			if(positioning == "bottom"){
				y = (ch - h);
			}



			if(size.$img) size.$img.width(Math.round(w)).height(Math.round(h)).css("left",Math.round(x)).css("top",Math.round(y)).show();
		}
		image.$element.width(cw).height(ch);
	}

	image.containerWidth  = cw;
	image.containerHeight = ch;
	image.imgWidth = w;
	image.imgHeight = h;
	image.imgX = x;
	image.imgY = y;

	//var $centreElement = image.centreElement == "div" ? image.$element : image.sizes[image.sizes.length-1].$img;

	if(image.centre && image.centreElement == "div"){
		var bw = image.$wrapper.width();
		var bh = image.$wrapper.height();
		var ww = image.$element.width();
		var hh = image.$element.height();

		image.$element.css("left",-(ww-bw)*image.centre[0]/100);
		image.$element.css("top",-(hh-bh)*image.centre[1]/100);
	}

	this.fire("refresh");

	if(checkForVersions !== false){
		this.checkForBetterImage(image, {instant : false}); //image.count == 1
	}

	this.fire("refresh",{image:image});
}
ImageController.prototype.checkForBetterImage = function( image, options ){

	if(this.status !== "active") return false;
	if(image.ext == "svg") return false;

	if(!options) options = {};

	var version = this.getBestVersion(image.folder,image.containerWidth, image.containerHeight )

	if(image.sizes[image.sizes.length - 1].size !== version){
		this.swapImage(image, version, {instant:options.instant});
	};

}
ImageController.prototype.swapImage = function( image, version, options ){
	if(!options) options = {};


	var c		= "size_"+(image.count++);
	var $img 	=  image.$element.append("<img class='"+c+ " " + image.img_class + "' alt='"+ image.img_alt + "' />").find("img."+c);

	$img.css("opacity",0);

	var size = {src: this.translateSrc(image.original,version,image), size: version, loaded: false, cached: false, $img : $img};
	//Igloo.log(image.sizes)
	image.sizes.push(size);
	image.size = version;

	$img.attr("data-requested",size.src);
	image.$element.attr("data-requested",size.src);
	size.onCached = Igloo.delegate(this,this.inlineTrigger,size,image)
	size.onLoaded = Igloo.delegate(this,function(original_size){



		if(this.status !== "active"){
			Igloo.log("interupted the image onLoad... need to do something else")
			return false;
		}
		$img.animate({opacity:1},{duration:options.instant?0:1000, complete:Igloo.delegate(this,function(){
			var sizeIndex = image.sizes.length;
			for(var i = 0; i < sizeIndex - 1; i++){
				var s = image.sizes[0];
				s.$img.remove();
				image.sizes.splice(0,1);
			}
			this.fire("shown");
		})});

		this.fire("new");

	},size);


	var pt = Igloo.delegate(this,this.imagePreloaded,image,size);
	Preloader2.add({src: size.src, priority: 3, tag: "page"}).then(pt,pt);
	this.preloadIds.push(Preloader2.getLastId());


}
ImageController.prototype.translateSrc = function( src, final,image ){


	var regex = new RegExp(this.base.replace(/\//g,'\\/')+"(.*?)\/(.*?)\/");




	var match;
	if(match = regex.exec(src)){

		var generateSettings = image.generateSettings ? image.generateSettings.replace(/\*/g,final) : final;

		src = src.substr(0,match.index) + this.base + match[1] + "/" + generateSettings + "/" + src.substr(match.index + match[0].length);

		//if(final !== "full") src = src.replace(".png",".jpg");

		return src;
	}

	return false;


}
ImageController.prototype.getFolderFromUri = function(str){
	if(!str) return false;
	var regex = new RegExp(this.base.replace(/\//g,'\\/')+"(.*?)\/");
	var folder = regex.exec(str);
	return folder && folder.length ? folder[1] : false;
}
ImageController.prototype.getExtFromUri = function(str){
	if(!str) return false;
	var parts = str.split("?");
	return parts[0].substr(parts[0].length-3);
}
ImageController.prototype.extractClassesFromString = function(classes_str){

	var class_regex = new RegExp("class=[\"\']+(.*?)[\"\']+");
	var match;
	if(match = class_regex.exec(classes_str)){
		var classes = match[1].split(" ");
		for(var i = 0; i < classes.length; i++){
			classes[i] = Text.trim(classes[i]);
		}
		var extracted = classes_str.substr(0,match.index) + classes_str.substr(match.index + match[0].length);
		return {extracted: extracted, classes:classes, found: true};
	}else{
		return {extracted: classes_str, classes: [], found: false}
	}
}
ImageController.prototype.getRatioFromString = function(str){
	var ratio_str 		= this.getAttrFromString("data-ratio",str);
	return ratio_str ? ratio_str : false;
}
ImageController.prototype.getCentreFromString = function(str){

	var centre_str 		= this.getAttrFromString("data-centre",str);
	var centre_parts 	= centre_str.split(",");
	if(centre_parts.length == 2){
		return [centre_parts[0]*1,centre_parts[1]*1];
	}else{
		return [50,50];
	}

}
ImageController.prototype.getAttrFromString = function(attr,str){

	var regex = new RegExp(attr + "=[\"\']+(.*?)[\"\']+");
	var match;

	if(match = regex.exec(str)){
		return match[1]
	}else{
		return "";
	}
}
ImageController.prototype.getBestVersion = function(folder, width,height){

	var versions = this.versions[folder];

	//This needs a check to see if there are portraits, otherwise use landscapes and vice versa

	if(!width) width 	= jstage.getWidth() * (folder == "two-up" ? 0.2 : 1);
	if(!height) height 	= jstage.getHeight()* (folder == "two-up" ? 0.2 : 1);

	width = width * (window['devicePixelRatio']?window['devicePixelRatio']:1);
	height = height * (window['devicePixelRatio']?window['devicePixelRatio']:1);

	//Igloo.log(width + " x " + height)

	var hasLandscape = false;
	var hasPortrait = false;
	for(var i in versions) {
		var size = versions[i];
		if("w" in size) hasLandscape = true;
		if("p" in size) hasPortrait = true;
	}

	var type;
	if(!hasLandscape) 		type = "portrait";
	else if(!hasPortrait)	type = "landscape";
	else					type = jstage.getWidth() > jstage.getHeight() ? "landscape" : "portrait";

	var best = false;
	for(var i in versions){
		var size = versions[i];

		if(type == "landscape" && "w" in size && ( size.w > best.w || !best.w ) ) {
			if(!best.w) best = size;
			else if (best.w <= width) best = size; //This makes sure it's bigger than the screen
		}else if(type == "portrait" && "h" in size && (size.h > best.h || !best.h) ){
			if(!best.h) best = size;
			else if (best.h <= height) best = size; //This makes sure it's bigger than the screen
		}

	}
	return best.p ? best.p : best.w;

}
ImageController.prototype.getImage = function($element){
	var c = $element.attr("class");
	var regex = /img\-replace\-(\d+)/;
	var result = c.match(regex);
	return Igloo.searchArray(this.images,"index",result[1]*1);
}
ImageController.prototype.getExpectedSrc = function(original){
	var ext =  this.getExtFromUri(original);
	if(ext == "svg") return original;
	var folder = this.getFolderFromUri(original);
	var size = this.getBestVersion(folder);
	var expected = this.translateSrc(original,size);
	return expected;
}
ImageController.prototype.destroy = function(){
	for(var i = 0, ii = this.images.length; i < ii; i++) {
		var image = this.images[i];

	}
	jstage.removeListener("resize",this.resizeDelegate);
	jstage.removeListener("resizeInstant",this.resizeInstantDelegate);
};

//Jquery pause and resume
//(function(){var e=jQuery,f="jQuery.pause",d=1,b=e.fn.animate,a={};function c(){return new Date().getTime()}e.fn.animate=function(k,h,j,i){var g=e.speed(h,j,i);g.complete=g.old;return this.each(function(){if(!this[f]){this[f]=d++}var l=e.extend({},g);b.apply(e(this),[k,e.extend({},l)]);a[this[f]]={run:true,prop:k,opt:l,start:c(),done:0}})};e.fn.pause=function(){return this.each(function(){if(!this[f]){this[f]=d++}var g=a[this[f]];if(g&&g.run){g.done+=c()-g.start;if(g.done>g.opt.duration){delete a[this[f]]}else{e(this).stop();g.run=false}}})};e.fn.resume=function(){return this.each(function(){if(!this[f]){this[f]=d++}var g=a[this[f]];if(g&&!g.run){g.opt.duration-=g.done;g.done=0;g.run=true;g.start=c();b.apply(e(this),[g.prop,e.extend({},g.opt)])}})}})();

