function HeaderController(){
	this.className	= "HeaderController";
	// this.glob		= [
	// 	"/services/**",
	// 	"/tests/adr**"
	// ];
};
HeaderController.inheritsFrom( StandardController );
HeaderController.prototype.off = function(onComplete){
	
	
};
HeaderController.prototype.swap = function(){

	this.$html 		= $('html');
	this.$body 		= $('body');
	// this.$pageServicesHeader = this.$body.find('.js-page-services-header');
	
};


// ADR 
HeaderController.prototype.mobileHideHeaderLogo = function(){
	alert($string);
};

