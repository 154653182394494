


	function Jcache(_settings){
		
		this.cache				= [];
		this.ajax_request		= 1;
		this.last_ajax_request 	= 0;
				
	};
	Jcache.inheritsFrom( EventTarget );
	//**** PUBLIC METHODS ****//
	Jcache.prototype.setup = function(url, dataString){

		//Defaults
		if(!url) 		url = Jnav.getDocumentUrl();
		if(!dataString) dataString = document.getElementsByTagName("html")[0].innerHTML

		var cleanUrl	= Jnav.cleanUrl(url);
		this.createCache(cleanUrl, dataString);
	}
	Jcache.prototype.getCache = function(url){
		if(url === true){
			url = this.cache[0].url;
		}

		//Remove the hash - it doesn't mean anything

		var cleanUrl	= Jnav.cleanUrl(url.split("#")[0]);
		var cache 		= Igloo.searchArray(this.cache,'url',cleanUrl);

		if(!cache){
			//Test the glob matches
			for(var i = 0; i < this.cache.length; i++){
				var matches = this.cache[i].matches;
				if(matches) for(var j = 0, jj = matches.length; j < jj; j++){
					var result = cleanUrl.match(matches[j]);
					if(result){
						cache = this.cache[i];
						//Igloo.log("woo yeag")
						break;
					}
				}
				if(cache) break;
			}
		}

		//Check if a controller has it already
		if(!cache){
			var controllers = Aviator.get("controllers");
			for(j = 0; j < controllers.length; j++) {
				var result = controllers[j].controller.checkGlobMatch(cleanUrl);
				if(result){
					//Not sure
					cache = this.createCache(cleanUrl,"");
					break;
				}
			}
		}

		//If we haven't found it, load it.
		if(!cache){
			cache = this.createCache(cleanUrl);
		}
		return cache;

	}
	Jcache.prototype.getCacheByRequest = function(request){
		var cache = Igloo.searchArray(this.cache,'request',request);
		return cache ? cache : this.cache[0];
		
	}	
	Jcache.prototype.createCache = function(cleanUrl, dataString){
		var c		= {};
		c.status	= "empty";
		c.url		= cleanUrl;
		c.parts		= Jnav.getParts(cleanUrl);
		
		this.cache.push(c);

		if(typeof(dataString) == "undefined") 	this.ajaxLoad(cleanUrl);
		else									this.dataLoad(cleanUrl, dataString);
		

		return c;
	}
	Jcache.prototype.updateCache = function(cleanUrl, updates){
		var cache = this.getCache(cleanUrl);
		for(var i in updates){
			cache[i] = updates[i];
		}
		return cache;
	}
	Jcache.prototype.updateCacheByRequest = function(request, updates){
		var cache = this.getCacheByRequest(request);
		return this.updateCache(cache.url,updates);
	}
	
	
	Jcache.prototype.ajaxLoad = function(url){ 
		this.ajax_request++;
		
		//this.last_ajax_request = this.ajax_request;
		var requestUrl = url + (url.indexOf("?")>= 0 ? "&" : "?") + "ajax=1&c=" + this.ajax_request;
		var updates = this.updateCache(url, {request:this.ajax_request, requestUrl: requestUrl, onLoad: function(){}});
		$.ajax({
			url: url,
			success: Igloo.delegate(this,this.ajaxLoadedPause,this.ajax_request),
			error: Igloo.delegate(this,this.ajaxLoadedPause,this.ajax_request)
		});
		return this.ajax_request;
	}
	Jcache.prototype.ajaxLoadedPause = function(request,result){
		//We add a wee pause to a) allow cache to update whether it's loaded from cache or ajax, and b) because it makes things run a bit smoother
		Igloo.pause(1,Igloo.delegate(this,this.ajaxLoaded,request,result));
	}
	Jcache.prototype.ajaxLoaded = function(request,result){
	
		if(typeof(result)== "object") result = result.responseText;
	
		var data = this.parseData(result);

		//Update the cache with the new data
		var cache = this.updateCacheByRequest(request, data);

		this.gatherGlobMatches(cache);

		this.onCacheLoaded(cache);
		cache.onLoad(); //TODO - this might need to be an event that is fired, otherwise preloaded and main content could screw up.

	}
	
	Jcache.prototype.dataLoad = function(cleanUrl, dataString){
		
		var data = this.parseData(dataString);

		//Update the cache with the new data
		var cache = this.updateCache(cleanUrl, data);

		this.gatherGlobMatches(cache);

		this.onCacheLoaded(cache);
		if(cache.onLoad) cache.onLoad(); //TODO - this might need to be an event that is fired, otherwise preloaded and main content could screw up.

	}
	Jcache.prototype.parseData = function(dataString){
		
		//Make a Jquery object to use, save it in the cache
		//dataString = dataString.replace(/\<script/gi,"<!--<noscript").replace(/\<\/script\>/gi,"</noscript>-->");

		//Match title
		var titleRe = /\<title\>(.*?)\<\/title\>/i;
		var titleMatch = dataString.match(titleRe);
		var title = titleMatch ? titleMatch[1] : "";

		//Match preloads
		var preloadRe = /\<img(.*?)class=[\"\'](.*?)preload(.*?)[\"\'](.*?)\>/gi;
		var preloadArray = dataString.match(preloadRe);
		var preloadImages = [];
		if(preloadArray) for(var i = 0; i < preloadArray.length; i++){
			var img = preloadArray[i];
			var myRe = /src\=[\"\'](.*?)[\"\']/;
			var r 	= myRe.exec(img);
			if(r && r[1]) preloadImages.push(r[1]);
		}

		//Match meta
		var myRe = /\<script(.*?)type\=[\"\']?text\/json[\"\']?(.*?)\>([\s\S]*?)(.*?)([\s\S]*?)\<\/script\>/gi;
		var results;
		var meta = {};
		while(results = myRe.exec(dataString)){ //exec loops through until it returns null
			if(!(results[1]+results[2]).match(/id=[\"\']?meta[\"\']?/)) continue;
			var meta_string = results ? Text.trim(results[5]) : "";
			if(meta_string){
				meta = Igloo.merge(meta,JSON.parse(meta_string));
			}
		}
		var matches = meta['match']?meta['match']:[];
		if(typeof(matches) == "string") matches = [matches];
		if(matches) for(var i = 0, ii = matches.length; i < ii; i++){
			matches[i] = Igloo.glob2regex(matches[i]);
		}
		delete meta['match'];

		//(.|[\r\n]) matches newlines too. or you can do \s\S can you?
		var templates		= {};
		var templateRx 		= new RegExp('\<script[^\<]*?type\=[\"\']?text\/template[\"\']?(.*?)\>((.|[\n\r])*?)\<\/script\>','gi');
		var templateMatches = dataString.match(templateRx);

		for(var i in templateMatches){
			var templateInnerRx 		= new RegExp('.*?id\=[\"\']?(.*?)[\"\'\\s].*?\>((.|[\r\n])*?)<\/script>','i');
			var templateInnerMatches	= templateInnerRx.exec(templateMatches[i]);

			if(templateInnerMatches){
				templates[templateInnerMatches[1]] = {id:templateInnerMatches[1], html:templateInnerMatches[2]};
			};
		}


		dataString = dataString.replace(/\<script([\s\S]*?)\<\/script\>/gi,"");

		var jquery_string = dataString.replace(/\<img(.*?) src=([\"\']*)(.*)([\"\']*)/gi,"<img$1 src-wait=$2$3$4");
		jquery_string = jquery_string.replace(/\<link(.*?)\/\>/gi,"");


		var $jquery_ajax = this.data2jquery(jquery_string);

		//Update the cache with the new data
		var data = {
			raw: 		dataString,
			meta: 		meta,
			data:		{},	//This will be used to store shared data
			templates: 	templates,
			matches:	matches,
			$jquery:	$jquery_ajax,
			status:		"loaded",
			title:		title,
			preloads:	preloadImages,
			preloaded:	preloadImages.length ? false : true,
			get:		function(selector, includeImages){
				var $r;
				if(selector) 	$r = $jquery_ajax.find(selector);
				else			$r = $jquery_ajax;

				var html = $r.html();
				if(includeImages !== false) html = html.replace(/\<img(.*?) src-wait=([\"\']*)(.*)([\"\']*)/gi,"<img$1 src=$2$3$4");
				return html;
			}
		};
		data.$get = function(selector, includeImages){
			return $(data.get(selector, includeImages));
		}
		data.$quickGet = function(selector){
			if(selector) return data.$jquery.find(selector);
			else return data.$jquery;
		}

		return data;
		
	}

	Jcache.prototype.gatherGlobMatches = function(cache) {

		//Check if any controllers know about the matches for this cache
		var controllers = Aviator.get("controllers");
		for (j = 0; j < controllers.length; j++) {
			var globCaches = controllers[j].controller.globCache;
			if (typeof(globCaches) == "string") globCaches = [globCaches];
			var found = false;
			if (globCaches) for (var i = 0, ii = globCaches.length; i < ii; i++) {


				var glob	= globCaches[i].replace(/\[\*\]/g,"*");
				var regex = Igloo.glob2regex(glob);
				var match = cache.url.match(regex);
				//Igloo.log("chechking: "+cache.url+" " + glob, match)
				if (match){
					found = true;
					break;
				}
			}
			if(found){
				for (var i = 0, ii = globCaches.length; i < ii; i++) {

					//Update the [*] to the url part
					var parts = globCaches[i].split("/");
					for(var k = 1, kk = parts.length; k < kk; k++){
						if(parts[k] == "[*]") parts[k] = cache.parts[k-1];
					}
					var glob = parts.join("/");

					var regex = Igloo.glob2regex(glob);
					//Igloo.log("Found: " + regex);
					cache.matches.push(regex);
				}
			}
		}


	}

	//**** OVERLOADABLES *****//
	Jcache.prototype.onCacheLoaded = function(cache){
		this.fire("loaded", cache);
	}
	//**** HELPERS *****//
	Jcache.prototype.data2jquery = function(data){
		var jquery_object	= $("<div/>").html(Text.trim(data));
		var jquery_ajax 	= jquery_object.find(".ajax");
		if(!jquery_ajax.size()) jquery_ajax =  jquery_object;
		return jquery_ajax ;
	};