


	function Stage(){

		this.$window	= $(window);
		this.minWidth	= null;
		this.minHeight	= null;
		this.width		= null;
		this.height		= null;
		this.mouseX		= null;
		this.mouseY		= null;
		this.scrollTop	= null;
		this.refreshThrottled = Igloo.throttle(this.refreshThrottleFunction,250,this);
				
	};
	Stage.inheritsFrom( EventTarget );	
	//**** INITIAL METHODS ****//
	Stage.prototype.setup = function(minWidth, minHeight){
		var $window = this.$window;
		var $body 	= $("body");

		if(minWidth) this.minWidth = minWidth;
		if(minHeight) this.minHeight = minHeight;

		$window.on("orientationchange",Igloo.$delegate(this,this.refreshStage));
		$window.on("resize",Igloo.$delegate(this,this.refreshStage));
		$window.on("scroll",Igloo.$delegate(this,this.scroll));
		$body.on("mousemove",Igloo.delegate(this,this.mouseMove));

		this.scrollTop = 0;

		this.refreshStage($window);
		this.scroll($window);


		
	}
	//**** PUBLIC METHODS ****//
	Stage.prototype.getState = function(){
		var data = {
			width:		this.width,
			height:		this.height
		}
		return data;
	}
	Stage.prototype.getWidth = function(){
		return this.width;
	}
	Stage.prototype.getHeight = function(){
		return this.height;
	}
	Stage.prototype.getMouse = function(){
		return {x:this.mouseX, y: this.mouseY};
	}
	//**** INTERNAL METHODS ****//
	Stage.prototype.refreshStage = function($window){

		this.prevWidth 	= this.width;
		this.prevHeight = this.height;

		this.width 	= $window.width();
		this.height = $window.height();

		if(this.minWidth && this.width < this.minWidth) this.width = this.minWidth;
		if(this.minHeight && this.height < this.minHeight) this.height = this.minHeight;

		this.refreshThrottled();
		this.fire("resizeInstant",this.getState());
	}
	Stage.prototype.refreshThrottleFunction = function(){
		this.updateCSS(".js-height", {
			height: this.height + "px!important"
		});

		this.updateCSS(".fullscreen", {
			width: 	this.width + "px!important",
			height: this.height + "px!important"
		});



		this.fire("resize", this.getState());
		if(this.prevWidth !== this.width) this.fire("resizeHorizontal", this.getState());
		if(this.prevHeight !== this.height) this.fire("resizeVertical", this.getState());
	}
	Stage.prototype.mouseMove = function(e){
		this.mouseX 	= e.pageX;
		this.mouseY 	= e.pageY;
		this.fire("mousemove",{x: this.mouseX, y: this.mouseY});
	}
	Stage.prototype.scroll = function(e){
		var st 			= this.$window.scrollTop();
		var delta		= st - this.scrollTop;
		this.scrollTop 	= st;

		var details = this.getScrollDetails();
		details.delta = delta;
		this.lastScrollDetails = details;
		this.fire("scroll",details);
	}
	Stage.prototype.getScroll = function(){
		return this.scrollTop;
	}
	Stage.prototype.getScrollDetails = function(){
		var body = document.body,
			html = document.documentElement;
		this.scrollHeight = Math.max( body.scrollHeight, body.offsetHeight,
			html.clientHeight, html.scrollHeight, html.offsetHeight );

		if(this.scrollHeight == this.$window.height()){
			var p = 0;
			var hasScroll = false;
		}else{
			var p = this.scrollTop/(this.scrollHeight - this.$window.height());
			var hasScroll = true;
		}

		return {scrollTop:this.scrollTop, p: p, hasScroll:hasScroll, scrollHeight:this.scrollHeight - this.$window.height(), scrollBottom:this.scrollHeight - this.$window.height() - this.scrollTop  };
	}
	Stage.prototype.updateCSS = function(selector,rules){

		//Note that you can't update the HTML of the style in ie8 so we have to remove and rebuild.
		var id		= selector.replace(/[^a-zA-Z0-9]/g,"_");
		var $style 	= $( "head #" + id );
		if($style) $style.remove();

	//	if(!$style.size()){
	//		$("<style type=\"text/css\" id=\""+id+"\"></style>").appendTo( "head" );
	//		$style 	= $( "head #" + id );
	//	}
		
		var str		= 	selector + "{";
		for(var i in rules) if(rules.hasOwnProperty(i)){
			str		+= 	i + ":" + rules[i] + ";";
		}
		str		+= 	"}";
		

		$("<style type=\"text/css\" id=\""+id+"\">"+str+"</style>").appendTo( "head" );
		
		
		//$style.get(0).textContent = str;

	};