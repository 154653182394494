function CareersVacanciesController(){
	this.className	= "CareersVacanciesController";
	this.glob		= ["/careers/vacancies/","/careers/vacancies/?*"];
	this.globMain 	= true;
};

CareersVacanciesController.inheritsFrom( StandardController );

CareersVacanciesController.prototype.swapMain = function(onComplete){
	console.log('changed page internally, may block individual job pages');
	this.filterJobsBasedOnNav();
};

CareersVacanciesController.prototype.swap = function(){

	const state = nav.getState();
	console.log('swapping vacancies');
	console.log(state);

	this.$window = $(window);
	this.$jobWrapper = $('.jobs');

	this.$banner = $(".js-canvas");
	if(this.$banner.length) {
		var worms = new Worms({
			$holder: this.$banner,
			colour: "#9694A8",
			sizing: "fit"
		});
	}
	//details.controller = worms;

	this.$paragraphs = $(".js-wrapper");
	this.readMores = ReadMore.build({
		$wrapper: 	this.$paragraphs,
		maxHeight:	120,
		content: 	".js-content",
		text: 		".js-reduce",
		readmore:	".js-readmore",
		dynamic: 	true,
		inline:		true
	});

	this.setupSelectize("team");
	this.setupSelectize("location");
	this.setAvailableJobsHeight();
	this.$window.on('resize', this.setAvailableJobsHeight);

	this.$jobs = $('.job');

	this.filterJobsBasedOnNav();

	// preload the corner image to try to make it faster
	this.$jobs.find(".js-vacancy-button").on("mouseover",(e) => {
		const src = `/assets/images/about/corner_circle.xsvg?colour=${$(e.currentTarget).parent().attr('data-hex-colour')}`;
		Preloader2.add({src:src});
	});


	return true;
};

CareersVacanciesController.prototype.selectizeFilter = function($filterType) {
	$filterType.selectize({
		persist: true,
		create: false,
		allowEmptyOption: true
		// maxOptions: 1,
		// sortField: 'text',
	}).on("change",Igloo.$delegate(this,function($this){
		var v = $this.val();
		var name = $this.attr('name');
		const currentValue = nav.getState().nextParams[name] ?? '';
		if( currentValue !== v){
			nav.changeQuery({[name]:v});
		}
	}));
};

CareersVacanciesController.prototype.setAvailableJobsHeight = function(){
	this.$jobWrapper = $('.jobs');
	this.$jobWrapper.height('');
	let jobWrapperHeight = this.$jobWrapper.height();
	this.$jobWrapper.height(jobWrapperHeight);
};

CareersVacanciesController.prototype.filterJobsBasedOnNav = function(){
	const state = nav.getState();


	this.$errorMessage = $('.js-error-message');

	const team = state.nextParams.team ? `.team-${state.nextParams.team}` : '';
	const location = state.nextParams.location ? `.loc-${state.nextParams.location}` : '';

	const selector = `${team}${location}`;
	const selectorLength = selector.length;
	const selectorFilterCheck = this.$jobs.filter(selector).length;

	// selector ? this.$jobs.hide().filter(selector).show() : this.$jobs.show();
	if(!selectorLength) {
		this.$jobs.show();
		this.$errorMessage.removeClass('error-message--show');
		console.log('no choices');
	} else if(!selectorFilterCheck) {
		this.$jobs.hide();
		this.$errorMessage.addClass('error-message--show');
		console.log('no matches');
	} else {
		this.$jobs.hide().filter(selector).show();
		this.$errorMessage.removeClass('error-message--show');
		console.log('matches found');
	}

	//this.setFilterBasedOnNav("team");
	this.setFilterBasedOnNav("location");
};

CareersVacanciesController.prototype.setupSelectize = function(filterName) {

	if(!this.$selectizeByName) this.$selectizeByName = {};

	const $s = $(`.input-selectize select[name=${filterName}]`);
	this.$selectizeByName[filterName] = $s;
	this.selectizeFilter($s);
};

// sets the value shown in the filter to match parameter
// e.g. when returning from vacancy page to vacancies page
CareersVacanciesController.prototype.setFilterBasedOnNav = function(filterName) {

	const state = nav.getState();
	const $selectize = this.$selectizeByName[filterName];
	const parameterValue = state.nextParams[filterName] ?? '';
	console.log(parameterValue, ' parameterValue');
	const currentSelectValue = $selectize[0].selectize.getValue();

	if(currentSelectValue !== parameterValue) {
		$selectize[0].selectize.setValue(parameterValue);
	}
};

CareersVacanciesController.prototype.offMain = function() {
	var state = nav.getState();
	if (!state.changes[0]) return;
	this.off();
};

CareersVacanciesController.prototype.off = function(){
	// jstage.removeListener("resize",this.resizeDelegate);
	// jstage.removeListener("scroll",this.scrollDelegate);
	// Aviator.get("baseController").removeListener("resizedHeader",this.baseDelegate);


	// this.readMores.destroy();
};
