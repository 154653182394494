function ResourcesController(){
	this.className	= "ResourcesController";
	this.glob		= ["/resources/*"];
}; 
ResourcesController.inheritsFrom( StandardController );
ResourcesController.prototype.swap = function(){

	$(".js-download").off("click").on("click",Igloo.$delegate(this,function($this,e){
		e.preventDefault();
		const isGated = $this.hasClass('gated');

		const resourceType 	= $this.attr("data-type");
		const link 			= $this.attr("data-link");
		const title 		= $this.attr("data-title");

		if(!isGated || Igloo.getCookie("downloads-access")){

			const redirectLink = $this.attr("data-link");
			this.analyticsDownloadEvent("Downloads", redirectLink);
			this.hubspotTracking(resourceType, title, link).then(() => { //will always resolve
				if(resourceType == "webinar" || resourceType == "podcast"){
					nav.change(redirectLink)
				}else{
					window.location.href = redirectLink;
				}
			})

		}else{
			new RegisterForm({
				link,
				title,
				target: $this.attr("target"),
				resourceType,
				values: {
					Download: $this.attr("data-downloadtitle") ?? $this.attr("data-title"),
					URL: $this.attr("data-link")
				}
			});
		}
	}))

	// *********** category filter ************* //
	
	this.$filterButtons = document.querySelector('.js-filter');
	this.$downloadWrapper = $('.js-downloads'); 
	this.$downloadCardElements = this.$downloadWrapper.find('.js-download-card'); 
	this.currentType = false;

	const cards = [];
	this.$downloadCardElements.each((index, cardElement) => {
		const card = {};
		card.element = cardElement;
		card.type = cardElement.getAttribute('data-type');
		card.media = card.type !== 'webinar' && card.type !== 'podcast' ? 'download' : card.type;
		card.show = true; //Default to show
		cards.push(card);
	});
	this.cards = cards;

	if (this.$filterButtons) {
		// filter buttons
		this.$filterButtons.addEventListener('click', e => {
			if(e.target.matches('a')) {
				e.preventDefault();
				let type = e.target.dataset.type;
				if(!e.target.classList.contains('selected')){
					this.$filterButtons.querySelectorAll('a').forEach(button => {
						button.classList.remove('selected');
					});
					e.target.classList.add('selected');
				}
				this.showType(type);
			}
		});
	}

	this.showType();
};

ResourcesController.prototype.showCard = (card, visible) => {
	var $elem = $(card.element);
	visible = visible ?? true;
	if(card.index % 2 === 1) $elem.addClass('odd');
	if(card.index === 1) $elem.addClass('mt-2');
	$elem.removeClass('download-hide');
};

ResourcesController.prototype.whichTransitionEvent = function() {
	// Function from David Walsh: http://davidwalsh.name/css-animation-callback
	var t, el = document.createElement('temp');
	var transitions = {
		'transition'      : 'transitionend',
		'OTransition'     : 'oTransitionEnd',
		'MozTransition'   : 'transitionend',
		'WebkitTransition': 'webkitTransitionEnd'
	};
	for (t in transitions){
		if (el.style[t] !== undefined){
			return transitions[t];
		}
	}
};

ResourcesController.prototype.showType = function( type ) {
	if (!type) type = 'all';
	if(this.currentType === type) return;
	var transitionEvent = this.whichTransitionEvent();
	this.$downloadWrapper.addClass('hide-download')
		.one(transitionEvent, (event) => {

			this.$downloadCardElements.addClass('download-hide').removeClass('odd').removeClass('mt-2');

			var cardsFiltered = this.cards.filter(card => (type === 'all' || card.type === type || card.media === type));
			cardsFiltered.forEach((card, index) => {
				card.index = index;
				this.showCard(card);
			});

			this.$downloadWrapper.removeClass('hide-download');
		});
	this.currentType = type;
};

ResourcesController.prototype.hubspotTracking = function(resourceType,title,link){
	const message	= `Repeat resources:\n${resourceType}\n${title}\n${link}`

	return PopupForm.submitHubspot({
		trackingOnly: true,
		message
	});

}
ResourcesController.prototype.analyticsDownloadEvent = function(category, label){
	if(typeof(ga) === "undefined") return false;
	ga('send', 'event', {
		eventCategory: category,
		eventAction: 'click',
		eventLabel: label
	});





}

ResourcesController.prototype.off = function(){

}



