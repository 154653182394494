function QuizController(){
	this.className	= 'QuizController';
	this.glob		= ['/quiz**', '/repquiz**'];
};

QuizController.inheritsFrom( StandardController );
QuizController.prototype.swap = function(){

	this.state = nav.getState();

	// General page and basic form setup
	this.$page 		= $('.page');
	this.$wrapper 	= $('.js-quiz');
	this.$header    = $('.js-page-header');
	this.$form 		= this.$wrapper.find('form');

	// Check form on submit (and only allow submit if all is complete)
	if(this.$form.length) this.$form.on('submit', (e) => {
		const ready = this.checkForm({renderErrors: true, scrollToError: true});
		if(!ready) e.preventDefault();
	});

	$copyButton = this.$page.find('.js-copy');
	$copyButton.on('click', (e) => {
		const $this = $(e.currentTarget);
		const copyText = $this.attr('copy-text');
		if(copyText) {
			this.copyTextToClipboard(copyText);
			const buttonText = $this.text();
			$this.text('Copied!');
			setTimeout(() => $this.text(buttonText), 1000);
		}
	});

	this.scrollToSection();
	this.setupFormFieldsObject();
	this.checkForm();
	this.setupGraphScrollReveal();
	this.checkForHubspot();

};
QuizController.prototype.scrollToSection = function(scrollPosition = false) {
	const headerHeight = this.$header.height();
	const $foundElement = $(`#${this.state.nextHash}`);
	if(this.state.nextHash && $foundElement.length){
		this.hashScrollPosition = $foundElement.offset().top - headerHeight;
		this.scrollTo(this.hashScrollPosition);
	}
	$(document).on('click', 'a[href^="#"]', (e) => {
		e.preventDefault();
		const href = $(e.currentTarget).attr('href');
		const $foundElement = $(`${href}`);
		if ($foundElement.length) this.scrollTo($foundElement.offset().top - headerHeight);
	});
}
QuizController.prototype.scrollTo = function(scrollPosition = false) {
	if (!scrollPosition) return;
	$('html, body').animate({
		scrollTop: scrollPosition
	}, 300);
}
QuizController.prototype.checkForm = function( options = {}) {

	this.checkFormFields( options );
	return this.checkFormGlobal( ); // returns true if form is ready, false otherwise

};
QuizController.prototype.checkFormFields = function( options = {} ) {
	this.formFields.forEach((formField) => {
		this.checkFormField(formField, options);
	});

	const firstError = this.formFields.find(formField => formField.error);
	if(firstError && options.scrollToError){
		const menuPadding = $('.js-header-content').height() + 50;
		$('html, body').animate({scrollTop: firstError.$formField.offset().top - menuPadding},{duration: 500});
	}

	return !!firstError; // will show if there's a error or not.
};
QuizController.prototype.checkFormField = function(formField, options = {}) {

	const renderFormFieldErrors = options.renderErrors ?? false;

	if( formField.type === 'radio' || formField.type === 'checkbox' ) {
		const $checkedElement = formField.$element.filter(':checked');
		formField.isChecked = $checkedElement.length > 0;
		formField.value = formField.isChecked ? $checkedElement.attr('value') : '';
		formField.error = !formField.isChecked;
		formField.formIsReady = formField.isChecked;
		// formField.errorMessage = formField.error && this.state.nextParts[1] ? 'Please choose a statement' : `Please choose a ${formField.title}`;
		formField.errorMessage =
			this.state.nextParts[1]
				? 'Please choose a statement'
				: formField.type === 'checkbox'
					? 'This field is required'
					: `Please choose a ${formField.title}`;

	} else {
		formField.value = formField.$element.val();
		formField.error = formField.value.length <= 2;
		formField.formIsReady = !formField.error;
		formField.errorMessage = formField.error ? `Please enter your ${formField.title}` : '';
	}

	if(renderFormFieldErrors) this.renderFormFieldError(formField);
};

QuizController.prototype.checkFormGlobal = function() {

	const formHasErrors 		= this.formFields.some(formField => formField.error);
	const formIsShowingErrors 	= this.formFields.some(formField => formField.errorShowing);
	const formIsComplete 		= this.formFields.every(formField => formField.formIsReady);

	this.$wrapper.toggleClass('form-has-errors', formHasErrors);
	this.$wrapper.toggleClass('form-has-visible-errors', formIsShowingErrors);
	this.$wrapper.toggleClass('form-not-complete', !formIsComplete);
	this.$wrapper.toggleClass('form-complete', formIsComplete);

	return formIsComplete;
};

QuizController.prototype.renderFormFieldError = function(formField) {
	if(formField.error) {
		formField.$errorMessage.text(formField.errorMessage).addClass('error-message--show');
		formField.errorShowing = true;
	} else {
		formField.$errorMessage.removeClass('error-message--show');
		formField.errorShowing = false;
	}
};
QuizController.prototype.setupFormFieldsObject = function() {

	this.formFields = [];
	this.$formField = this.$wrapper.find('.js-form-field');

	this.$formField.each((index, formElement) => {
		let formField = {};

		const $formField = $(formElement);
		const $element = $formField.find('input');

		formField.$element = $element;
		formField.$formField = $formField;
		formField.$errorMessage = $formField.find('.js-error-message').removeClass('error-message--show');
		formField.type = $element.attr('type');
		formField.name = $element.attr('name');
		formField.title = $element.attr('placeholder') || $element.attr('name');
		formField.formIsReady = false;

		//Events
		formField.$element.on(formField.type == 'radio' ? 'change blur' : 'input blur', (e) => this.inputEvent(e, formField));

		this.formFields.push(formField);
	});
	console.log(this.formFields);
};


QuizController.prototype.inputEvent = function(e, formField) {

	this.checkFormField(formField);

	//Only update the error rendering if it's already showing (we might want to hide it) or if it's blur - eg. not just an input
	if(e.type == 'blur' || formField.errorShowing) this.renderFormFieldError(formField);

	this.checkFormGlobal();
};

QuizController.prototype.setupGraphScrollReveal = function() {

	this.$quizSummary = $('.js-quiz-summary');
	if(!this.$quizSummary.length) return;

	// this.quizSummaryBottom = this.$quizSummary.offset().top + this.$quizSummary.outerHeight();
	this.$window = $(window);
	// this.quizSummaryBottom = this.$quizSummary[0].getBoundingClientRect().bottom;
	// this.windowHeight = this.$window.height();

	this.positionAndHeightSetup = () => {
		this.$graphElements 		= this.$quizSummary.find('.js-quiz-summary-wrapper');
		this.$graphElement 			= this.$graphElements.filter(':visible');

		this.quizSummaryBottom = this.$graphElement[0].getBoundingClientRect().bottom;
		this.windowHeight = this.$window.height();

		this.graphBarHeightHide();

	};

	this.positionAndHeightSetup();

	this.scrollFunction = () => {

		const windowScrollTop = this.$window.scrollTop();

		if (windowScrollTop > (this.quizSummaryBottom - this.windowHeight)){
			this.$quizSummary.addClass('show-results-summary');

			this.graphBarHeightShow(this.$graphElements);

			// needs the conditional to make sure dont remove all scroll events from the website!!
			this.unSetupGraphScrollReveal();
		}

	};

	this.$window.on('scroll', this.scrollFunction);
	this.$window.on('resize', this.positionAndHeightSetup);

	setTimeout(() => this.scrollFunction(), 500);
	// Aviator.get('jstage').addListener('resize', this.resizeDelegate  );
};
QuizController.prototype.graphBarHeightHide = function(){
	this.$graphElements.each( (i,element) => {
		const $graph 		= $(element);
		const $rects 		= $graph.find('rect');
		const barMaxHeight	= $graph.find('.js-x-axis').attr('y2'); // height of space for the bars - will need updating if SVG is updated

		$rects.each((index, rect) => {
			const $rect = $(rect);
			const rectHeight = $rect.attr('height');
			$rect.attr('data-height', rectHeight).css({height: 0, y: barMaxHeight});
		});
	});
};
QuizController.prototype.graphBarHeightShow = function(){

	this.$graphElements.each( (i,element) => {
		const $graph = $(element);
		const $rects = $graph.find('rect');
		const barMaxHeight = $graph.find('.js-x-axis').attr('y2'); // height of space for the bars - will need updating if SVG is updated

		$rects.each((index, rect) => {
			const $rect = $(rect);
			const rectHeight = $rect.attr('data-height');
			const rectDelay = 200 + 500 * index;
			$rect.css({transitionDelay: `${rectDelay}ms`}).css({
				height: `${rectHeight}px`,
				y: (barMaxHeight - rectHeight)
			});
		});
	});
};
QuizController.prototype.unSetupGraphScrollReveal = function(){
	console.log('unSetupGraphScrollReveal run');
	if(this.scrollFunction) this.$window.off('scroll', this.scrollFunction);
	if(this.positionAndHeightSetup) this.$window.off('resize', this.positionAndHeightSetup);
};

/*
 * Copy text to clipboard with fallback.
 */
QuizController.prototype.copyTextToClipboard = function(text){
	if (!navigator.clipboard) {
		this.fallbackCopyTextToClipboard(text);
		return;
	}

	navigator.clipboard.writeText(text).then(function() {
		console.log('Async: Copying to clipboard was successful!');
	}, function(err) {
		console.error('Async: Could not copy text: ', err);
	});
}
QuizController.prototype.fallbackCopyTextToClipboard = function(text){
	var textArea = document.createElement("textarea");
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = "0";
	textArea.style.left = "0";
	textArea.style.position = "fixed";

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		var successful = document.execCommand('copy');
		var msg = successful ? 'successful' : 'unsuccessful';
		console.log('Fallback: Copying text command was ' + msg);
	} catch (err) {
		console.error('Fallback: Oops, unable to copy', err);
	}

	document.body.removeChild(textArea);
}


QuizController.prototype.checkForHubspot = function(  ){
	const meta = this.getMeta();
	if(meta.hubspot && meta.hubspot.track) return this.hubspotTracking(meta.hubspot.track);
}
QuizController.prototype.hubspotTracking = function( data ){
	const settings = {
		//region: "na1",
		formId: "728dd681-acff-4ebb-9ed7-9ec6e7dae759",
		//version: "V2_PRERELEASE"
	}

	let message = "";
	for(let key in data.meta??{}){
		const value = data.meta[key];
		message += `${key}: ${value}\n`;
	};

	PopupForm.submitHubspot({...settings,...data,agree:true,quiz:message});
}


QuizController.prototype.off = function(){
	// console.log(state, ' off');
	this.unSetupGraphScrollReveal();
};
