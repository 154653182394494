
	function TeamController(){
		this.className			= "TeamController";
		this.glob				= ["/team/","/team/?*"];
		this.globMain 			= true;

		this.preloadGlob		= this.glob;

		this.ignoreWords = []; //We don't want to ignore and and a etc.
		this.searchTerm = "query";

	};
	TeamController.inheritsFrom( StandardController );
	TeamController.inheritsFrom( SearchController );
	TeamController.inheritsFrom( FilterController );
	TeamController.prototype.swapMain = function(onComplete){
		//Apply search to ui


		var state = nav.getState();
		if(!this.updateSearchUriDelayed.isWaiting()) this.$searchInput.val(state.nextParams.query || "");
		this.selectize.val(state.nextParams.sector || "");


		this.readFilters();
		this.applyFilters();



	}
	TeamController.prototype.swap = function(onComplete){

		var state = nav.getState();
		if(state.nextParts[1]) return;

		if(!this.isSetup) {

			this.getMeta();
			this.getTemplates();
			this.team = this.meta.team;

			//Gather filter data
			this.setSearchData(this.team);

			this.createSearchIndex("query", ["fullname", "role"], {words: true});
			this.createSearchIndex("sector", ["simple_services"]);

			this.filterDetails = {
				//"location":			{type:"OR"},
				//"position":			{type:"OR"},
				//"discipline":		{type:"AND"},
				//"practice_group":	{type:"AND"},
				//"pinned":			{type:"OR"},
				"sector": {type: "OR"},
				"query": {type: "AND", words: true, partial: true}
			};


			this.mouseoverDelegate = Igloo.$delegate(this,function($this){
				var href = $this.attr("href");
				jcache.getCache(href);
			});

			//this.createSearchIndex("pinned", 		["pinned"]);

			this.isSetup = true;

		}

		if(this.cachedScrollPosition){
			$("html, body").scrollTop(this.cachedScrollPosition);
		}


		this.selectize = $('.input-selectize select');
		this.selectize.selectize({
			persist: true,
			create: false,
			allowEmptyOption: true
			// maxOptions: 1,
			// sortField: 'text',
		}).on("change",Igloo.$delegate(this,function($this){
			var v = $this.val()
			nav.changeQuery({sector:v});
			this.readFilters();
			this.applyFilters();
		}));

		this.setupfiltersUI();
		this.readFilters();
		this.applyFilters()

	};
	TeamController.prototype.setupfiltersUI = function(x){
		//Load in the filter HTML


		var $filterHolder = $(".controls");
		this.$filterHolder = $filterHolder;

		//Search
		var _this = this;

		this.$searchInput = $filterHolder.find("input");
		this.searchPlaceholder = this.$searchInput.attr('data-default');

		this.updateSearchThrottled = Igloo.throttle( _this.updateSearch, 1, _this);
		this.updateSearchUriDelayed = Igloo.debounce( _this.updateSearchUri, 500, _this);
		this.$searchInput.on("keyup input", function(e){
			_this.updateSearchThrottled($(this).val());
			_this.updateSearchUriDelayed($(this).val());
		});

		this.$searchInput.on("focus", function(e){
			var $this = $(this);
			$this.addClass("hasFocus");

			if($this.val() == _this.searchPlaceholder){
				$this.val("");
			}
			$this.removeClass("default");
		});

		this.$searchInput.on("blur", function(e){
			var $this = $(this);
			$this.removeClass("hasFocus");

			if($this.val() == _this.searchPlaceholder || $this.val() == ""){
				$this.addClass("default");
				$this.val(_this.searchPlaceholder);
			}
		});

		$(document).on("keydown",function(e){ //Todo input

			if( (e.which == 13 || e.which == 169) && _this.$searchInput.is(":focus")) {
				e.preventDefault();
				return false;
			}

		});

		this.filterHistory = false;
		//this.updateHeight();

	}
	TeamController.prototype.applyFilters = function( ){

		this.$people = $(".js-person");
		var queries 		= this.filters2search(this.filters);
		this.filteredData 	= this.performFilter(queries);

		this.$people.hide().each(Igloo.$delegate(this,function($this){
			var id = $this.attr("data-id");
			var person = Igloo.searchArray(this.filteredData,"itemID",id);
			if(person){
				$this.show();
			}
		}));
		this.$people.find("a").off("mouseover").on("mouseover",this.mouseoverDelegate);


//		var data = {team:this.filteredData, hasResults:!!this.filteredData.length}
		Aviator.get("baseController").imageController.resize();

	}
	TeamController.prototype.offMain = function(onComplete) {
		return this.off();
	}
	TeamController.prototype.off = function(onComplete){
		$(".js-person a").off("mouseover",this.mouseoverDelegate);

		var csp = false;
		if(!nav.getState().changes[0]) csp = jstage.getScroll();
		this.cachedScrollPosition = csp;


	}

