function CareersVacancyController(){
	this.className	= "CareersVacancyController";
	this.glob		= ["/careers/vacancies/*/*"];
};

CareersVacancyController.inheritsFrom( StandardController );

CareersVacancyController.prototype.swap = function(onComplete){
	var state = nav.getState();
	this.$body 		= $('body');
	// Expander
	this.$expander     = this.$body.find('.js-expander');
	this.$expanderLink = this.$expander.find('.js-expander-link');
	this.$cornerCircle = this.$body.find('.svg-shape--corner');

	this.$expanderLink.on('click touchstart', (e) => {
		if(e.cancelable) e.preventDefault(); //touchstart can't call preventDefault
		const $expander = $(e.currentTarget).parents('.js-expander');
		this.expandContent($expander);
	}).on('touchend', (e) => {
		e.preventDefault(); //Call prevent default to stop the click
	});

	this.$cornerCircle.addClass('show-corner-circle');

	// Only show the page once the corner image has loaded
	Preloader.add(`/assets/images/about/corner_circle.xsvg?colour=${this.getMeta().hexColour}`, () => onComplete());

	return false;
};

CareersVacancyController.prototype.expandContent = function($elem) {
	const $expanderContent  = $elem.find('.js-expander-content');
	if (!$expanderContent.length) return;

	const isOpen = $elem.hasClass('expander--open');
	$elem.toggleClass('expander--open');

	const currentHeight  = $expanderContent[0].clientHeight;
	const revealerHeight = $expanderContent[0].scrollHeight;
	const newHeight      = isOpen ? 0 : revealerHeight;

	$expanderContent.stop().animate({height: `${newHeight}px`}, 800);
};

CareersVacancyController.prototype.off = function(){
	this.$cornerCircle.removeClass('show-corner-circle');
};


