
	function BaseController(){
		this.className			= "BaseController";
		this.glob				= "/**";
		this.priority			= 10000;

	};
	BaseController.inheritsFrom( StandardController );
	BaseController.prototype.swapMain = function(onComplete){



		// Loader
		// window.onload = function () {
		// 	document.getElementById("loader").style.display = "none";
		// 	document.querySelector(".page-wrapper").style.display = "block";
		// 	document.body.style.overflow = "auto";
		// };


		if(!this.isSetup){

			//this.booking = new BookingForm({ values: { Service: "manufacturing", Section: "compliance"} }); // for testing

			this.$header			= $(".js-page-header");
			this.$headerWrapper		= $(".js-secondary-header-wrapper");
			this.$logo				= this.$header.find(".js-header-top");
			this.$menuButtonWrapper = this.$header.find(".menu-button");
			this.$searchNavWrapper 	= this.$header.find(".search-nav");

			// settings for service-header 
			// this.$serviceHeader		= $(".service-header");
			// this.$serviceHeaderSymbol		= this.$serviceHeader.find(".symbol");

			this.$menu 			= $('.js-primary-menu');
			this.$menuItem 		= this.$menu.find("li");
			this.$menuButton 	= $('.js-menu-button');
			this.$body 			= $("body");
			this.$scollElement	= $("body, html");
			this.$jsBody		= this.$body.find(".js-body");
			this.$html 			= $('html');
			this.menuState 		= false;

			this.$menuButton.on("click touchstart",Igloo.$d(this,function($this,e){
				this.toggleMenu(null, {userInitiated: true}); // passing null to make it toggle
			}));

			// Menu click
			this.$menu.find("a").on("click touchstart",Igloo.$d(this,function($this,e){
				this.$menuItem.removeClass("selected");
				$this.parent().addClass('selected');


				// if($this.attr("href") == nav.getState().nextUrl) {
				// 	this.toggleMenu(false);
				// }
			})).each(Igloo.$delegate(this,function($this){
				var href = $this.attr("href");
				jcache.getCache(href);
			}));

			//Scrolled
			jstage.addListener("resize",Igloo.delegate(this,this.getPositions));
			jstage.addListener("scroll",Igloo.delegate(this,this.scroll));
			this.getPositions();




			this.$loadBar = $("body").append("<div class='loadbar' style='width:0px;height:5px;background-color:#ffffff;box-shadow: 0px 4px 8px #ffffff;position:fixed;top:0;left:0;z-index:10000'></div>").parent().find(".loadbar");
			this.loader = new Loader();
			this.loader.onStart = (e) => {
				this.$loadBar.stop().css("opacity",1);
			}
			this.loader.onUpdate = (e) => {
				var p = e.percent;
				this.$loadBar.css({width:p+"vw"});
			}
			this.loader.onFinished = (e) => {
				this.$loadBar.animate({opacity:0},{duration:500});
			}


			this.isSetup = true;





		}else{



			var state = nav.getState();

			var cache = jcache.getCache(state.nextUrl);
			var $page = cache.$get(".js-body",false);

			this.$jsBody.html($page);

			var bodyClass = cache.$get("body",false).attr("class");
			var match = cache.raw.match(/<body[^>]*?class="([a-zA-Z0-9\-_\s]+)"[^>]*>/);
			// debugger;
			var bodyClass = match ? match[1] : "";
			this.$body.attr("class",bodyClass);
			// debugger;
			
			var $secondary = cache.$get(".js-secondary-header-wrapper",false);
			this.$headerWrapper.html($secondary);
			// var headerClass = cache.$get(".js-page-header",false).attr("class");
			// var match = cache.raw.match(/<header[^>]*?class="([a-zA-Z0-9\-_\s]+)"[^>]*>/);
			// var headerClass = match ? match[1] : "";
			// this.$header.attr("class",headerClass);
			this.$header.attr("class",cache.$quickGet(".js-page-header").attr("class"))

			// Only scroll to top if not hash link.
			if(!state.nextHash){
				this.$scollElement.scrollTop(0);
			}

			this.getPositions();

		}

		// needed if not changing pages
		this.toggleMenu(false);

		var p = nav.part(0)
		if(!p) p = "home";
		this.$menuItem.removeClass("selected").filter(".primary-menu-"+p).addClass("selected");

		// debugger;

		// Process images
		var imageCount = this.processImages(this.$body, onComplete);

		// this.imageController = new ImageController();
		// this.imageController.$process(this.$body);

		nav.addToLinks($("a"));

		$('.js-share').on("click",'a',Igloo.$d(this,function($this, e){
			var href = $this.attr('href');
			var options = ({
				width: ($this.attr('data-width') || 650),
				height: ($this.attr('data-height') || 448),
				title: $this.attr('title') || false,
			});

			// Twitter popup
			if($this.hasClass('share-twitter')){
				options = ({
					width: ($this.attr('data-width') || 700),
					height: ($this.attr('data-height') || 450),
					title: $this.attr('title') || false,
				});
			}

			// LinkedIn popup
			if($this.hasClass('share-linkedin')){
				options = ({
					width: ($this.attr('data-width') || 700),
					height: ($this.attr('data-height') || 600),
					title: $this.attr('title') || false,
				});
			}

			this.sharePopup(e, href, options);
		}));


		// Arrange a call popup
		$(".js-contact-start").on("click",Igloo.$d(this,function(){
			new BookingForm({
				template: "arrange"
			});
		}));

		// Contact us popup
		$(".js-contact-us").on("click",Igloo.$d(this,function(){
			new BookingForm({
				template: "contact"
			});
		}));

		// Updates popup start
		$(".js-updates-start").on("click",Igloo.$d(this,function(){
			new SignupForm();
		}));

		/*this.readMore = ReadMore.build({
			$wrapper: 	$(".js-wrapper"),
			content: 	".js-content",
			text: 		".js-text",
			readmore: 	".js-readmore",
			dynamic:	true
		});/*
		new ReadMore({
			$wrapper: 	$(".js-wrapper"),
			$content: 	$(".js-content"),
			$text: 		$(".js-text"),
			$readmore: 	$(".js-readmore")
		});*/

		this.loader.complete();
		onComplete();
		// return true;
	}

	BaseController.prototype.processImages = function($container, onComplete, rebuild = true){
		if(!$container) $container = this.$page;

		if(rebuild || !this.imageController) {
			this.imageController = new ImageController();
		}

		if(onComplete) this.imageController.addListener("ready",Igloo.delegate(this, function(){
			onComplete();
		}));
		var imageCount = this.imageController.$process($container);

		return imageCount;
	}

	BaseController.prototype.reduce = function(text){

	}
	BaseController.prototype.swap = function(onComplete){
		console.log("BaseController swap");
		return true;
	};
	/*
	BaseController.prototype.on = function(onComplete){
		return true;
	};
	*/


	BaseController.prototype.toggleMenu = function(state = null, options = {}){

		const userInitiated = options.userInitiated ?? false;

		if(state === null) state = !this.menuState;

		// nothing to do so return
		if(state === this.menuState) return state;

		this.menuState = state;
		this.$body.toggleClass("menu-on", state);
		this.fire("menu-toggled", {state: state ? "on" : "off", userInitiated });

		return state;
	}

	BaseController.prototype.getMenuState = function(){
		return this.menuState;
	}


	BaseController.prototype.on = function(onComplete){
		console.log("BaseController on");
		this.$body.css("visibility","visible");
		const pageSlug = nav.getState().nextUrl;

		if(this.hasDoneFirstPage){

			if(typeof(ga) !== "undefined"){
				ga('send', 'pageview', pageSlug);
			}

			if(typeof(window._hsq) !== "undefined"){
				var _hsq = window._hsq;
				_hsq.push(['setPath', pageSlug]);
				_hsq.push(['trackPageView']);
 			}
		}

		this.hasDoneFirstPage = true;
	}
	BaseController.prototype.off = function(onComplete){
		console.log("BaseController off");
		var nextCache = jcache.getCache( nav.getState().nextUrl );
		if(nav.getState().changes.some(c => c === true) && nextCache?.status !== "loaded"){
			this.loader.start();
		}

	}
	BaseController.prototype.getPositions = function(){

		//return false;

		//Header height
		var hasScroll = this.$header.hasClass("scrolled");

		this.headerHeightC = this.$header.height();
		this.logoTopC = Text.toNumber(this.$logo.css("top"));
		this.menuButtonTopC = Text.toNumber(this.$menuButtonWrapper.css("top"));
		this.searchNavTopC = Text.toNumber(this.$searchNavWrapper.css("top"));

		this.$header.css("height","");
		this.$logo.css("top","");
		this.$menuButtonWrapper.css("top","");
		this.$searchNavWrapper.css("top","");

		this.$header.removeClass("scrolled");
		this.headerHeight1 = this.$header.height();
		this.logoTop1 = Text.toNumber(this.$logo.css("top"));
		this.menuButtonTop1 = Text.toNumber(this.$menuButtonWrapper.css("top"));
		this.searchNavTop1 = Text.toNumber(this.$searchNavWrapper.css("top"));
		this.$header.addClass("scrolled");
		this.headerHeight2 = this.$header.height();
		this.logoTop2 = Text.toNumber(this.$logo.css("top"));
		this.menuButtonTop2 = Text.toNumber(this.$menuButtonWrapper.css("top"));
		this.searchNavTop2 = Text.toNumber(this.$searchNavWrapper.css("top"));
		if(!hasScroll) this.$header.removeClass("scrolled");


		if(this.headerHeightC) {
			this.$header.height(this.headerHeightC);
		}
		if(this.logoTopC) this.$logo.css("top",this.logoTopC);
		if(this.menuButtonTopC) this.$menuButtonWrapper.css("top",this.menuButtonTopC);
		if(this.searchNavTopC) this.$searchNavWrapper.css("top",this.searchNavTopC);

		this.resetting = true;
		this.scroll(true);
		this.resetting = false;

	}
	BaseController.prototype.scroll = function(instant){

		//return false;

		instant = instant === true;

		var sd = jstage.lastScrollDetails;



		var st = jstage.getScroll();
		var scrolled = st > 0;
		var max = jstage.getHeight()/8;
		var scrolledFar = st > max;

		if(!scrolled){
			this.scrolledFar = false;
		}



		var r = (st < max) ? st / max : 1;

		this.setSize(r);

		this.$header.toggleClass("hard-scrolled",scrolled);

		return;


	}
	BaseController.prototype.setSize = function(r){
		// console.log(this.$body.hasClass('active-scroll-lock'), ' scroll-lock');
		if (this.$body.hasClass('active-scroll-lock')) return; // need to assign variable rather than use class
		if(this.lastR !== r || this.resetting){

			this.lastR = r;

			var a = this.headerHeight1 - (this.headerHeight1 - this.headerHeight2) * r;
			var b = this.logoTop1 - (this.logoTop1 - this.logoTop2) * r;
			var c = this.menuButtonTop1 - (this.menuButtonTop1 - this.menuButtonTop2) * r;

			this.$header.height(a);
			this.$logo.css("top",b);
			this.$menuButtonWrapper.css("top",c);
			this.$searchNavWrapper.css("top",c);
			this.fire("resizedHeader");

			// settings for service-header 
			// if(this.$serviceHeader) {
			// 	this.$serviceHeader.css({
			// 		"height":`calc(100vh - ${a}px)`,
			// 		"padding-top": a,
			// 	});
			// 	this.$serviceHeaderSymbol.css({
			// 		"top": a
			// 	})
			// }

		}
	}
	BaseController.prototype.forceSmallHeader = function(r){
		this.setSize(1);
	}
	BaseController.prototype.sharePopup = function(e, href, option){
		e.preventDefault();

		// Set values for window
		href = href || false;

		if(!href) return false;

		option.title 	= (option.title || 'Share window');
		option.width 	= (option.width || '600');
		option.height 	= (option.height || '500');
		option.resize 	= (option.resize ? 'yes' : 'no');

		var left = (screen.width/2)-(option.width/2);
		var top = (screen.height/2)-(option.height/2);

		// Set title and open popup with focus on it
		var strTitle = option.title,
			strParam = 'width='+ option.width +',height='+ option.height +', top='+ top +', left='+ left +', resizable='+ option.resize +', toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no',
			objWindow = window.open(href, strTitle, strParam).focus();
	}

