
	function Xsrc(){
		this.className		= "Xsrc";
	};
	Xsrc.cache = {}
	Xsrc.load = function($holder) {
		var cache = this.cache;
		$holder.find("img[xsrc]").each(function(i){
			var $img = $(this);
			var xsrc = $img.attr("xsrc");
			if(cache[xsrc]) Xsrc.replace($img,cache[xsrc]);
			else{
				var filename = xsrc.split("/").pop().split(".")[0];
				if(!filename) return;
				$.get(xsrc,function(data){
					if(data.documentElement){
						cache[xsrc] = new XMLSerializer().serializeToString(data.documentElement)
						Xsrc.replace($img,cache[xsrc]);
					}else{
						Igloo.log("Xsrc error: " + xsrc);
					}
				});
			}

		})
	}
	Xsrc.replace = function($img,data) {
		$img.replaceWith(data);
	}
