function SlideController(){
	this.className	= "SlideController";
	this.debug		= false;

	this.nextSlide 		= false;
	this.currentSlide 	= false;
	this.waitingSlide	= false;

	this.nav			= true;

	this.level 			= 0;

};
SlideController.abstract = true;
SlideController.inheritsFrom( StandardController );
//SlideController.prototype.swapMain = function( onComplete ){
//Igloo.log("swap main")
//}
//SlideController.prototype.onMain = function( onComplete ){
//	Igloo.log("onMain")
//}
SlideController.prototype.offMain = function( onComplete ){
	//Igloo.log("offmain")
}
SlideController.prototype.swap = function( onComplete ){

	var state 	= nav.getState();

	//this.setupBoxes(".practice-area", ".practice-area-content", ".add-btn");

	//We need to record what the current status is, so here we can do skip stuff\
	if(!this.isSetup) {
		this.setupPage();
		this.loadSlides();
		this.setupListeners();






	}

	return true;

}
SlideController.prototype.touchstartDelegate = function( e ) {
	e.preventDefault();
	e.stopPropagation();
}
SlideController.prototype.on = function( onComplete ) {



	this.onCompleteFunction = onComplete;


	if(this.nav){
		var next = state.nextParts.length > this.level ? Igloo.searchArray(this.slides,"name",state.nextParts[this.level]).index : 0;
	}else{
		var next = 0
	}
	//var next = 8;
	this.showSlide(next);
	//this.nextSlide = false;

	return this.waitForAnimation ? false : true;

}
SlideController.prototype.finished = function( onComplete ) {
	//$("html, body, header").css("position","fixed");

}
SlideController.prototype.showSlide = function( index ) {


	if(this.nextSlide && this.nextSlide.adding){
		//Igloo.log("A?")
		//this.slideBringOffComplete(this.nextSlide.index);
	}


	var nextSlide 		= this.getSlide(index);
	this.nextSlide		= nextSlide;



	//var currentSlide 	= this.getSlide(this.currentSlide);

	if(!nextSlide) return false;

	//First hide the previous slide

	/*
	 if(this.currentSlide !== false){
	 this.status = "hiding";
	 this.waitingSlide = nextSlide.index;
	 this.hideSlide(this.currentSlide);
	 return;
	 }*/

	this.status = "showing";
	nextSlide.status = "showing";
	//nextSlide.bringOn();
	this.currentSlide = nextSlide.index;

}
SlideController.prototype.slideBringOn = function(slide){


	var mc = new MultipleOnComplete.create(Igloo.delegate(this,this.showSlideComplete,slide.index));

	slide.$scene.show().css("opacity",1);


	var sw = jstage.getWidth();
	var sh = jstage.getHeight();

}
SlideController.prototype.showSlideComplete = function( index ) {
	//Igloo.log("complete " + index + " but is that the right one? " + this.currentSlide)

	if(index == this.currentSlide){
		this.status = "ready";

		//Force double skippage
		this.attempts 	= 0;
		this.lastAttemptTime = 0;
	}

	if(this.waitForAnimation) this.onCompleteFunction();
}
SlideController.prototype.hideSlide = function( index ) {
	//Igloo.log("hide slide " + index);
	if(typeof(index) == "undefined") return true;
	var currentSlide 	= this.getSlide(index);
	if(!currentSlide || currentSlide.status == "off") return false;

	currentSlide.bringOff();

}

SlideController.prototype.slideBringOff = function(slide){


	Igloo.log("AAA")

	var mc = new MultipleOnComplete.create(Igloo.delegate(this,this.slideBringOffComplete,slide.index));

	slide.$scene.parent().stop().fadeTo(250,0,mc());


}
SlideController.prototype.slideBringOffComplete = function( index ){

	this.currentSlide = false;

	var currentSlide = this.getSlide(index);

	currentSlide.adding = false;
	Igloo.log(currentSlide);
	// currentSlide.$scene.hide();
	currentSlide.$scene.parent().css("opacity",1);
	if(this.waitingSlide !== false) this.showSlide(this.waitingSlide);

}
SlideController.prototype.setupPage = function(){


	this.isSetup = true;

	this.$body 				= $("body");

	this.$holder 			= null;

	this.status				= "ready"; //Check what this should be
	this.attempts			= 0;
	this.lastAttemptTime 	= null;

	this.nextSlide 		= false;
	this.currentSlide 	= false;
	this.waitingSlide	= false;

}
SlideController.prototype.setupListeners = function(){
	this.$body 				= $("body");
	this.$pagination 		= this.$body.find(".js-pagination");
	this.$paginationButton 	= this.$pagination.find(".js-pagination-button");
	this.$arrow 			= this.$body.find(".js-scroll-down");

	this.setupPagination();

	this.mouseWheelDelegate = Igloo.delegate(this, this.wheelNoRepeat, this.wheelChangeWeak);
	this.keyPressDelegate 	= Igloo.delegate(this, this.keyPress);


	this.$body.on("mousewheel", this.mouseWheelDelegate);
	this.$body.on("keydown", 	this.keyPressDelegate);

	document.addEventListener('touchstart', this.touchstartDelegate, {passive: false});

	//This needs setting up
	var _this = this;
	if ('ontouchstart' in window || 'onmsgesturechange' in window) { //Touchevents
		var myElement = this.$body.find(".js-body").get(0);
		this.hammer = new Hammer(myElement, {inputClass: Hammer.TouchInput});
		this.hammer.get('swipe').set({direction: Hammer.DIRECTION_ALL, velocity: 0.001, threshold: 5});
		this.hammer.on("swipeup swipedown swipeleft swiperight", Igloo.throttle(function (ev) {
			if(Aviator.get("menuController").mobileMenuActive) return false;
			//Igloo.log(ev);


			if (ev.type == "swipedown") _this.previous();
			if (ev.type == "swipeup") _this.next();
			if (ev.type == "swipeleft") _this.next();
			if (ev.type == "swiperight") _this.previous();
		}, 1000));

	}

	this.$arrow.off("click touchstart").on("click  touchstart", Igloo.delegate(this, function (e) {
		e.preventDefault();
		this.next();
	}));

	this.$pagination.off("click touchstart").on("click touchstart", "a", Igloo.$delegate(this, function($this,e){
		e.preventDefault();
		Igloo.log(this.$paginationButton);
		this.$paginationButton.removeClass('selected');
		$this.addClass('selected');
		var index = $this.index();
		this.showSlide(index);
	}));

	if(!this.onResizeDelegate) {
		this.onResizeDelegate = Igloo.delegate(this, this.onResize);
		Aviator.get("jstage").removeListener("resize", this.onResizeDelegate).addListener("resize", this.onResizeDelegate);
	}

	if(!this.onResizeDelayDelegate) {
		this.onResizeDelayDelegate = Igloo.debounce(this.onResizeDelay, 1000, this);
		Aviator.get("jstage").removeListener("resize", this.onResizeDelayDelegate).addListener("resize", this.onResizeDelayDelegate);
	}



}
SlideController.prototype.loadSlides = function(){
	if(this.buildSlides) this.buildSlides();
	//this.completeSlides();
}
SlideController.prototype.setupPagination = function(){
	var $button = this.$paginationButton;
	var totalSlides = this.slides.length;
	for(var i=1; i<totalSlides; i++){
		$button.clone().insertAfter($button);
	}
	$button.eq(0).addClass('selected');
	this.$paginationButton = this.$pagination.find(".js-pagination-button");
}
SlideController.prototype.completeSlides = function(){

	var state 	= nav.getState();
	var base    = this.level > 0 ? nav.sliceUrl(state.nextUrl,this.level-1) : "/";

	for(var i in this.slides){
		var s = this.slides[i];

		if(!s.url) s.url = base + (i*1?s.name:"");
		s.status = "off";
		s.index = i*1;

		s.controller = this;
		s.bringOn = Igloo.delegate(this,this.slideBringOn,s);
		s.bringOff = Igloo.delegate(this,this.slideBringOff,s);
	}
}
SlideController.prototype.getSlide = function(index){
	if(typeof(index) == "undefined") return false;
	return index in this.slides ? this.slides[index] : false;
}
SlideController.prototype.next = function(){
	//if(Aviator.get("menuController").mobileMenuActive) return true;
	//var dm = Aviator.get("dotMenuController");
	//var next = dm.getNextMenuItem();
	var next = this.getSlide(this.currentSlide + 1);
	if(!next) return;

	this.$paginationButton.removeClass('selected');
	this.$paginationButton.eq(next.index).addClass('selected');

	if(this.nav){
		nav.change(next.url);
	}else{
		this.showSlide(next.index);
	}

}
SlideController.prototype.previous = function(){
	//if(Aviator.get("menuController").mobileMenuActive) return true;

	//var dm = Aviator.get("dotMenuController");
	//var next = dm.getNextMenuItem();
	var previous = this.getSlide(this.currentSlide - 1);
	if(!previous) return;

	this.$paginationButton.removeClass('selected');
	this.$paginationButton.eq(previous.index).addClass('selected');

	if(this.nav){
		nav.change(previous.url);
	}else{
		this.showSlide(previous.index);
	}

	//if(previous) nav.change(previous.url);
}
SlideController.prototype.nextWeak = function(){
	//if(Aviator.get("menuController").mobileMenuActive) return true;

	if(this.allowSkippage) return this.next();


	if(this.status == "ready" || this.attempts > 0){
		this.next();
	}else{
		this.attempts++;
		this.lastAttemptTime = +new Date();
	}
}
SlideController.prototype.prevWeak = function(){
	if(this.allowSkippage) return this.prev();
}
SlideController.prototype.onResize = function(stage){

}
SlideController.prototype.onResizeDelay = function(stage){



}
SlideController.prototype.wheelNoRepeat = function(fe, e) {
	e.preventDefault();

	if(Aviator.get("baseController").getMenuState()) return;

	var delta_y = e.deltaY + e.deltaX;
	//var delta_x = ;
	var threshold = 150;
	var thresholdAction = 250;

	var now 			= +new Date;
	var delayed 		= (this.wnrLast && now < this.wnrLast + threshold);
	var delayedAction 	= (this.wnrAction && now > this.wnrAction + thresholdAction);

	var biggerDelta = false;
	if(delta_y * this.deltaLast < 0) biggerDelta = true;
	if(delayedAction && Math.abs(delta_y) - Math.abs(this.deltaLast) > 100) biggerDelta = true; //No reliable

	this.wnrLast 	= now;
	this.deltaLast 	= delta_y;
	if (!delayed || biggerDelta) {
		this.wnrAction	= now;
		fe.apply(this,[e]);
	}
	return false;
}
SlideController.prototype.wheelChange = function( e ){
	//Igloo.log(e)
	if(e.deltaY > 0 || e.deltaX < 0) this.previous();
	if(e.deltaY < 0 || e.deltaX > 0) this.next();
}
SlideController.prototype.wheelChangeWeak = function( e ){
	if(e.deltaY > 0 || e.deltaX < 0) this.previous();
	if(e.deltaY < 0 || e.deltaX > 0) this.nextWeak(); //nextWeak
}

SlideController.prototype.keyPress = function( e ) {

	if(e.which == 37) this.previous();
	if(e.which == 38) this.previous();
	if(e.which == 39) this.nextWeak();
	if(e.which == 40) this.nextWeak();

	if(e.which == 37 || e.which == 38 || e.which == 39 || e.which == 40) e.preventDefault();
}
SlideController.prototype.off = function(  ) {
	this.isSetup = false;

	//Igloo.log("wo there?")

	//$("html, body, header").css("position","");

	this.$body.off("mousewheel", this.mouseWheelDelegate);
	this.$body.off("keydown", 	this.keyPressDelegate);
	Aviator.get("jstage").removeListener("resize", this.onResizeDelayDelegate);
	Aviator.get("jstage").removeListener("resize", this.onResizeDelegate);

	document.removeEventListener("touchstart",this.touchstartDelegate,{passive:false});



	if(this.hammer){
		this.hammer.off("swipeup swipedown swipeleft swiperight");
		this.hammer.destroy();
	}
	this.$arrow.off("click touchstart");

};
