
	function BookingForm(settings){
		this.className			= "BookingForm";

		this.name = "booking";

		if(!settings) settings = {};

		settings = {
			template: ("template" in settings) ? settings.template : "arrange",
		}
		settings.submitAnalytics = {label: settings.template}

		this.data = {
			pages: [
/*
				{
					title: "Which sector are you most interested in?",
					name: "sector",
					instant: true
				},
				{
					title: "What are you looking to achieve right now?",
					name: "section",
					init: Igloo.delegate(this,function(){
						var service = this.values.Service;
						this.$content.find(".js-service-"+service).show();
						this.$content.find(".js-service:not(.js-service-"+service+")").remove()
						this.loadDefaults();

					}),
					instant: true
				},
*/
				{
					title: "Contact details",
					name: settings.template,
					submissionUrl: "/forms/booking",
				},
				{
					title: "Thanks",
					name: "complete",
					render: Igloo.delegate(this,function(){

						const v 		= this.values;

						this.submitHubspot({
							message: v['Message'],
							enquiry: "1",
							agree: false,
							name: v['Name'],
							email: v['Email'],
							phone: v['Phone'],
							company: v['Company']
						});

					})
				},
			]
		}

		this.setup(settings);
	};
	BookingForm.inheritsFrom( PopupForm );

