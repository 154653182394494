function CareersOldController(){
	this.className	= "CareersController";
	this.glob		= ["/careersX/","/careersX/?*"];
};
CareersOldController.inheritsFrom( StandardController );
CareersOldController.prototype.swap = function(){


	this.$banner = $(".js-canvas");
	var worms = new Worms({
		$holder: this.$banner,
		colour: "#048C9B",
		sizing:	"fit"
	});
	//details.controller = worms;


	this.$paragraphs = $(".js-wrapper");
	this.readMores = ReadMore.build({
		$wrapper: 	this.$paragraphs,
		maxHeight:	120,
		content: 	".js-content",
		text: 		".js-reduce",
		readmore:	".js-readmore",
		dynamic: 	true,
		inline:		true
	});


};
CareersOldController.prototype.off = function(){
	this.readMores.destroy();
}
