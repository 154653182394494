const wrapTextWithSpans = ($wrapper) => {
    if (!$wrapper.length) return;
    const containerWidth = $wrapper.width();
    const text = $wrapper.text().trim();
    const lines = text.split('\n');
    const lastLine = lines.pop().trim();
    const words = lastLine.split(/\s/);
    let lastLineWords = [];
    let currentLineWidth = 0;

    let finalText = lines.join(" <br>\n");
    $wrapper.html(finalText);

    // Create a new span wrapper for last line words
    // $wrapper.append("<br>\n");
    let $spanWrapper = $('<span>');

    // Append span wrapper to wrapText
    $spanWrapper.appendTo($wrapper);

    // $spanWrapper.append("<br>\n");

    // Iterate through words in reverse to find the last words that fit in the container
    for (let i = words.length - 1; i >= 0; i--) {
        // Create a temporary span element to measure the word's width
        const $tempSpan = $('<span>').text("" + words[i]).appendTo($spanWrapper);
        const wordWidth = $tempSpan.width();

        //console.log("Current line width:", currentLineWidth + wordWidth);

        // If adding the word exceeds the container's width, stop adding words
        if (currentLineWidth + wordWidth > containerWidth) {
            break;
        }

        // Add the word to the list of last line words and remove it from the words array
        lastLineWords.unshift(words[i]); // Add word to the beginning of the array
        words.splice(i, 1); // Remove word from words array
        currentLineWidth += wordWidth;
    }

    // console.log("Current line width:", currentLineWidth, "Container width:", containerWidth);
    // console.log(lastLineWords);
    // console.log($spanWrapper);
    // return;

    const spanClass = $wrapper.attr('data-class');

    // Join lines and words with appropriate HTML markup
    finalText =
        lines.join(" <br>\n")
        + " <br>\n" + words.join(" ")
        + (lastLineWords.length ? "<span class=\"" + spanClass + "\"> " + lastLineWords.join(" ") + "</span>" : "");

    // console.log(finalText);
    // return;

    // Update the HTML content of the wrapper
    $wrapper.html(finalText);
}