function VideoController(){
	this.className	                    = 'VideoController';
	this.glob				            = '/**';
	this.isVimeoLoaded                  = false;
	this.isPlyrLoaded                   = false;
}

VideoController.inheritsFrom( StandardController );

VideoController.prototype.swap = function(){
	// Elements
	this.$body 		= $('body');
	this.$media		= this.$body.find('.js-media-player');
	// console.log(' media', this.$media);
	this.videos     = [];
	if(this.$media.length) {
		// console.log(' api media', this.$media);
		this.loadVimeoAPI();
	}
};

/**
 * Load all videos
 */
VideoController.prototype.loadVideos = function() {
	
	const videos = [];

	this.$media.each((index, element) => {
		const $elem = $(element);

		// Get service from video src
		const $video = $elem.find('.js-media-video');
		const $iframeHolder = $video.find('.js-media-iframe');
		const service = this.getVideoService($iframeHolder);

		const video = {
			index               : index,
			videoId             : $elem.attr('data-id'),
			title               : $elem.attr('data-title'),
			service             : service,
			type                : 'image',
			autoplay            : !!($elem.attr('data-autoplay')*1),
			loop                : !!($elem.attr('data-loop')*1),
			start               : $elem.attr('data-start')*1,
			end                 : $elem.attr('data-end')*1,
			player              : false,
			loaded              : false,
			isMuted             : false,
			isPaused            : false,
			isPlaying           : false,
			isActuallyPlaying   : false,
			$elem: 				$elem,
			$video: 			$video,
			$iframe: 			$iframeHolder,
			$poster: 			$elem.find('.js-media-poster'),
			$loader: 			$elem.find('.js-media-loader'),
			$controls: 			$elem.find('.js-media-controls'),
			$play: 				$elem.find('.js-media-play'),
			// $restart: 			$elem.find('.js-media-restart'),
			$pause: 			$elem.find('.js-media-pause'),
			$audio: 			$elem.find('.js-media-audio'),
			$mute: 				$elem.find('.js-media-mute'),
			$unMute: 			$elem.find('.js-media-unmute'),
			$fullscreen:		$elem.find('.js-media-fullscreen'),
			$progress:		    $elem.find('.js-media-progress'),
			play:				() => this.play(index),
			stop:				() => this.stop(index),
			// restart:			() => this.restart(index),
			//mute:				() => this.mute(index),
			//unMute:			() => this.unMute(index),
			//progress:			() => this.progress(index),
		};

		// Control: Play button
		if (video.$play.length) {
			video.$play.on('click touchstart', ((e) => {
				e.preventDefault();
				video.play(true); //true makes it force play
			}));
		}

		// Control: Audio button
		if (video.$audio.length) {
			video.$audio.on('click touchstart', (() => {
				e.preventDefault();
				// this.toggleAudio(video.index);
			}));
		}

		videos.push(video);
	});

	this.videos = videos;
	//console.log(this.videos);

	this.setupVideos();
};

VideoController.prototype.getVideoService = function($elem) {
	if (!$elem) return;

	const srcParts = new URL($elem.attr('data-src')).hostname.split('.');
	return (srcParts.length < 3 ? srcParts[0] : srcParts[1]);
};

/**
 * Setup all videos
 *
 * @returns {boolean}
 */
VideoController.prototype.setupVideos = function() {
	if(!this.videos.length) return false;

	for(var i=0; i<this.videos.length; i++){
		var video = this.videos[i];
		if(!video.player){
			// console.log(' set up video iframe');
			this.loadIframe(video);

			if(video.service === 'vimeo') video.player = this.setupVimeoVideo(video);
			else if(video.service === 'youtube') video.player = this.setupVimeoVideo(video);
		}
	}
};

/**
 * Setup Vimeo video
 *
 * @param video
 * @returns {boolean|Vimeo.Player}
 */
VideoController.prototype.setupVimeoVideo = function( video ) {
	if(!video) return false;
	if(!video.$iframe || video.player) return false;

	// console.log('load plyr', video.$iframe[0]);
	
	const player = new Plyr(video.$elem.find('.js-media-video'), { 
		controls: [
			// 'play-large', // The large play button in the center
			'restart', // Restart playback
			// 'rewind', // Rewind by the seek time (default 10 seconds)
			'play', // Play/pause playback
			// 'fast-forward', // Fast forward by the seek time (default 10 seconds)
			'progress', // The progress bar and scrubber for playback and buffering
			// 'current-time', // The current time of playback
			// 'duration', // The full duration of the media
			'mute', // Toggle mute
			'volume', // Volume control
			// 'captions', // Toggle captions
			// 'settings', // Settings menu
			// 'pip', // Picture-in-picture (currently Safari only)
			// 'airplay', // Airplay (currently Safari only)
			// 'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
			'fullscreen' // Toggle fullscreen
		],
		loadSprite: false,
		iconUrl: '/assets/images/icons/player/video-plyr-sprite.svg',
	});
	// player.play();
	/*

    player.on("loaded", ((data) => {
        video.loaded = true;
    }));
*/
	// player.on('play', ((data) => {
	// 	video.isPlaying = true;
	// 	console.log(data, ' data');
	// Hide loader
	//if(video.$loader) video.$loader.fadeOut(this.loaderDuration);
	// }));

	// console.log(player, ' player');

	// Shows restart button when ended and hides when clicked again
	player.on('ended', function(e) {
		// ended = whether or not the video has ended
		const $plyrControlRestart = $('.plyr__control[data-plyr=restart]');
		const $plyrControlPlay = $('.plyr__control[data-plyr=play]');
		if(e.type === 'ended'){
			$plyrControlRestart.addClass('control-show');
			$plyrControlPlay.addClass('control-hide');
		}
	});
	player.on('play', function(e) {
		const $plyrControlRestart = $('.plyr__control[data-plyr=restart]');
		const $plyrControlPlay = $('.plyr__control[data-plyr=play]');
		if(e.type === 'play'){
			$plyrControlRestart.removeClass('control-show');
			$plyrControlPlay.removeClass('control-hide');
		}
	});
	// $('.plyr__controls').add
	// Shows restart button when ended and hides when clicked again ends




	//video.progress = [];
	/*
    player.on('progress', ((data) => {
        console.log(data);
        var currentPercent = Math.ceil(data.percent * 100);
        video.$progress.attr('value', currentPercent);

        /*
        if (video.progress.indexOf(currentPercent) != -1) {
            return;
        }
        *

        //video.progress.push(currentPercent);
    }));*/

	/*
    player.getDuration().then(function(duration) {
        // duration = the duration of the video in seconds
    }).catch(function(error) {
        // an error occurred
    });

    player.getCurrentTime().then(function(seconds) {
        // `seconds` indicates the current playback position of the video
    });*/

	return player;
};

/**
 * Get video by index
 *
 * @param index
 * @returns {boolean|boolean}
 */
VideoController.prototype.getVideo = function( index ) {
	if(typeof(index) === 'undefined') return false;
	return this.videos.find(video => (video.index === index));
};

/**
 * Media: Play
 *
 * @param index
 * @param force
 * @returns {boolean}
 */
VideoController.prototype.play = function( index, force ) {
	var video = this.getVideo(index);

	if(!video.player) return;
	if(video.isPlaying || !video.loaded) return false;

	this.currentVideoIndex = index;

	// Play video
	video.isPlaying = true;
	video.isPaused = false;
	video.$elem.addClass('is-playing');

	// Show iframe
	video.$iframe.css({visibility: 'visible', opacity: 1});

	// Actually play media
	this.playPlayer(index);

	// Fade out play button
	video.$play.stop().animate({opacity: 0}, 1000, (() => {
	    video.$play.hide();
	}));

	// Fade out poster after 800ms.
	if(video.$poster && video.isPlaying){
		video.$poster.stop().delay(800).animate({opacity: 0}, 2000, function() {
			const $this = $(this);
			$this.hide();
			video.$play.hide();
		});
	}
};

/**
 * Media: Stop
 *
 * @param index
 * @param force
 * @returns {boolean}
 */
VideoController.prototype.stop = function( index, force ) {
	var video = this.getVideo(index);
	if(!video.player || !video.loaded) return;

	if(force){
		this.isForcedPlaying = false;
		video.isForcedPlaying = false;
	}

	if(!video.isPlaying) return false;
	if(video.isForcedPlaying && !force) return false;

	this.currentVideoIndex = false;

	// Pause video
	video.isPlaying = false;
	video.isPaused = true;
	video.$elem.removeClass('is-playing');

	clearTimeout(video.hideTimeout);

	// Pause player
	this.pausePlayer(index);
};

/**
 * Player: Play
 *
 * @param index
 */
VideoController.prototype.playPlayer = function(index) {
	var video = this.getVideo(index);
	if(!video.player || !video.loaded) return;

	// YOUTUBE
	/*
	if(video.service === "youtube"){
		if(video.start) video.player.seekTo(video.start, true);
		//video.player.setPlaybackQuality("highres");
		video.player.playVideo();
		video.isActuallyPlaying = true;
	}
	*/

	// VIMEO
	if(video.service === 'vimeo') {
		video.player.play().then(() => {
			if(video.start) video.player.setCurrentTime(video.start);
			video.isActuallyPlaying = true;
		});
	}
};

/**
 * Player: Pause
 *
 * @param index
 */
VideoController.prototype.pausePlayer = function(index) {
	var video = this.getVideo(index);
	if(!video.player || !video.loaded) return;

	if(video.service === 'youtube'){
		video.player.pauseVideo();
		video.isActuallyPlaying = false;
	}

	if(video.service === 'vimeo'){
		video.player.pause().then(() => {
			video.isActuallyPlaying = false;
		});
	}

	video.isActuallyPlaying = false;
};



/**
 * Player: Restart - NEW FUNCTION
 *
//  * @param index
 */
// VideoController.prototype.restartPlayer = function(index) {
// 	var video = this.getVideo(index);
// 	video.getEnded().then(function(end) {
// 		console.log(end, ' end');
// 		// ended = whether or not the video has ended
// 	}).catch(function(error) {
// 		console.log(error, ' error');
// 		// an error occurred
// 	});
// };
/**
 * Player: Restart - NEW FUNCTION
 *



/**
 * Load in iFrame
 *
 * @param video
 * @returns {boolean|*}
 */
VideoController.prototype.loadIframe = function( video ) {
	if(!video) return false;
	// if(video.$iframe) return video.$iframe;

	// Get iframe holder
	var $iframeHolder = video.$iframe;
	if(!$iframeHolder.length) return false;

	// Get all iframe attributes from holder
	var iframeAttrs = $iframeHolder[0].attributes;

	var attrs = [];
	for(var i=0; i<iframeAttrs.length; i++){
		var attr = iframeAttrs[i];
		var key = attr.name;
		var dataString = 'data-';
		if(key.indexOf(dataString) === -1) continue;
		attrs[key.substring(dataString.length)] = attr.value;
	}

	// Create iframe data string
	var attrString = '';
	for(var key in attrs){
		attrString += key +'="'+ attrs[key] +'" ';
	}

	// Create iframe and replace holder
	var $iframe = $('<iframe '+ attrString +'></iframe>');
	$iframeHolder.replaceWith($iframe);

	video.$iframe = video.$elem.find('iframe');
	if (video.$iframe) {
	    video.type = 'iframe';
	    return true;
	}
};

/**
 * Load Plyr API
 *
 * @param options
 */
VideoController.prototype.loadPlyrPlayer = function(options) {
	if (!this.isPlyrLoaded) {
		// console.log(' load plyr media', this.$media);
		this.isPlyrLoaded = true;
		var s = document.createElement('script');
		s.onload = Igloo.delegate(this, this.onPlyrReadyCallback);
		s.setAttribute('id', 'plyr-player');
		s.setAttribute('type', 'text/javascript');
		// s.setAttribute('defer', '');
		s.setAttribute('src', 'https://cdn.plyr.io/3.6.8/plyr.js');
		document.body.appendChild(s);
	}
};
/**
 * Load Vimeo API
 *
 * @param options
 */
VideoController.prototype.onPlyrReadyCallback = function(options) {
	this.loadVideos();
};
VideoController.prototype.loadVimeoAPI = function(options) {
	if (!this.isVimeoLoaded) {
		// console.log(' vimeo loaded media', this.$media);
		var s = document.createElement('script');
		s.onload = Igloo.delegate(this, this.onVimeoReadyCallback);
		s.setAttribute('id', 'vimeo-api-player');
		s.setAttribute('type', 'text/javascript');
		s.setAttribute('defer', '');
		s.setAttribute('src', 'https://player.vimeo.com/api/player.js');
		document.body.appendChild(s);
	}
};
VideoController.prototype.onVimeoReadyCallback = function() {
	this.isVimeoLoaded = true;
	this.loadPlyrPlayer();
	// console.log(' callback media', this.$media);
	//this.loadVideos();
};

VideoController.prototype.off = function() {
	this.isPlyrLoaded = false;
	this.isVimeoLoaded = false;
	this.isPlyrLoaded = false;

	this.$body.find('#vimeo-api-player').remove();
	this.$body.find('#plyr-player').remove();
};


