function ContactController(){
	this.className	= "ContactController";
	this.glob		= ["/contact/"];
};
ContactController.inheritsFrom( StandardController );
ContactController.prototype.swap = function(){

	this.$gmap = $(".js-map");
	this.$contactItems = $(".js-contact");

	this.resizeDelegate = jstage.addListener("resize",Igloo.debounce(Igloo.delegate(this,function(){


		if(this.map){
			this.map.setCenter(this.currentMarker ? this.currentMarker.latLng : this.defaultLatLng);
		}


	}),1));

	/*
    $(".js-contact-start a").on("click",Igloo.$d(this,function(){
		new BookingForm();
	}));

	$(".js-updates-start a").on("click",Igloo.$d(this,function(){
		new SignupForm();
	}));
	*/

	var _this = this;
	this.$gmap.on("mouseover",function(){
		//	_this.$button.addClass("active")
	});
	this.$gmap.on("mouseout",function(){
		//_this.$button.removeClass("active")
	});

	$.getScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyAud_T0oGRUYNyIHy2jJ8L3dMIg-C39Oak", Igloo.delegate(this,function () {

		var markers = this.getMeta().offices;

		var alat = 0;
		var alng = 0;
		for(var i in markers){
			var m = markers[i];
			m.lat = m.lat * 1;
			m.lng = m.lng * 1;
			alat += m.lat/markers.length;
			alng += m.lng/markers.length;
			m.latLng = new google.maps.LatLng(m.lat, m.lng);
		}


		alat = markers[2].lat*1;
		alng = markers[2].lng*1;
		var zoom = 5;
		var latLng = new google.maps.LatLng(alat, alng);

		this.defaultZoom = zoom;
		this.defaultLatLng = latLng;




		var map = new google.maps.Map(this.$gmap.get(0), {
			center: {lat: alat, lng:alng},
			disableDefaultUI: true,
			scrollwheel: false,
			zoomControl: true,
			zoomControlOptions: {
				position: google.maps.ControlPosition.RIGHT_BOTTOM
			},
			backgroundColor: 'none',
			zoom: 5
		});
		this.map = map;



		for(var i in markers){
			markers[i].marker = new google.maps.Marker({
				position: {lat: markers[i].lat*1, lng: markers[i].lng*1},
				map: map,
				icon: '/assets/images/icons/general/verco-zero.svg',
				title: markers[i].title
			});

			markers[i].marker.addListener('click', Igloo.delegate(this,function(marker,e) {
				this.gotoMarker(marker)
			},markers[i]));

		}

		map.setOptions({styles: this.getStyles()});


		this.$contactItems.on("click",Igloo.$delegate(this,function($this){

			var marker = Igloo.searchArray(markers,"id",$this.attr("data-id"));
			if(marker) this.gotoMarker(marker);
		}))

	}));

};
ContactController.prototype.gotoMarker = function ( marker ) {



	if(marker.selected){
		marker.selected = false;
		this.$contactItems.removeClass("selected")
		this.map.setZoom(this.defaultZoom);
		this.map.panTo(this.defaultLatLng);
		this.$contactItems.first().parent().addClass("show-all");
		this.currentMarker = false;
		return;
	}

	for(var i in this.markers) this.markers[i].selected = false;

	marker.selected = true;

	this.map.setZoom(15);
	this.map.panTo(marker.latLng);

	this.$contactItems.removeClass("selected").filter("[data-id='"+marker.id+"']").addClass("selected");
	this.$contactItems.first().parent().removeClass("show-all");

	this.currentMarker = marker;

}
ContactController.prototype.getStyles = function ( zoom ) {
	var styles = [
        {
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative",
            "stylers": [
                {
                    "color": "#BFD3D6"
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "administrative.country",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative.locality",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "administrative.locality",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "administrative.locality",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#18464b"
                },
                {
                    "visibility": "on"
                },
                {
                    "weight": 2.5
                }
            ]
        },
        {
            "featureType": "administrative.neighborhood",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative.province",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "landscape",
            "stylers": [
                {
                    "color": "#004a53"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi",
            "stylers": [
                {
                    "color": "#16454b"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#004a53"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#065960"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels",
            "stylers": [
                {
                    "color": "#cce9ee"
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#cce9ee"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#1c4a53"
                },
                {
                    "visibility": "on"
                },
                {
                    "weight": 3
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#32676f"
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#547b81"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#195961"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "transit",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#33737B"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "invert_lightness": true
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "labels.text",
            "stylers": [
                {
                    "color": "#80A7AC"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "color": "false"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "lightness": 30
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#183136"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "transit.station.airport",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#154e59"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "water",
            "stylers": [
                {
                    "color": "#003e44"
                },
                {
                    "visibility": "on"
                }
            ]
        }
    ];
	/*if(zoom && zoom < 12) styles.push({
	 "featureType": "road",
	 "elementType": "labels",
	 "stylers": [
	 { "visibility": "off" }
	 ]
	 });*/
	return styles;
}
ContactController.prototype.off = function(){
	jstage.removeListener("resize",this.resizeDelegate);

};
