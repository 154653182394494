	function RegisterForm(settings){
		this.className			= "RegisterForm";

		this.name = "register";

		if(!settings) settings = {};
		settings.link = settings.link ?? false;
		settings.title = settings.title ?? false;
		settings.target = settings.target ?? false;
		settings.submitAnalytics = settings.submitAnalytics ?? true;
		settings.resourceType = settings.resourceType ?? "document";
		settings.values = settings.values ?? false;

		console.log(settings);

		//var CookiesController = Aviator.get('cookiesController');
		var nav = Aviator.get('nav');

		this.data = {
			pages: [
				{
					name: "details",
					submit: Igloo.delegate(this,function(){

					}),
					init: Igloo.delegate(this,function(){
						var $check = this.$content.find(".js-checkbox");
						var $label = $check.parent();
						var $updates = this.$content.find("[name='updates']");

						$label.on("click",Igloo.$d(this,function($this){
							$label.toggleClass("checked");
							$updates.val($label.hasClass("checked") ? "Yes" : "No");
						}));

					}),
					submissionUrl: "/forms/register"

				},
				{
					name: "complete",
					render: Igloo.delegate(this,function(){

						Igloo.setCookie("downloads-access",true);

						const v 		= settings.values;
						const message	= `Resources:\n${settings.resourceType}\n${v.Download}\n${settings.link}`
						const agree 	= v.updates === "Yes";

						this.submitHubspot({
							message,
							agree,
							firstname: v['First Name'],
							lastname: v['Last Name'],
							jobtitle: v['Job title'],
							email: v['Email']
						});

						if(settings.resourceType == "document") {
							this.close();
							var fileName = settings.link.split('?')[0].substring(settings.link.lastIndexOf('/')+1);
							this.downloadFile(settings.link, fileName);
						} else {
							this.close();
							console.log("changing!",nav.getUrl(),settings.link);
							if (nav.getUrl() === settings.link) {
								window.location.href = settings.link;
							} else {
								nav.change(settings.link, true);
							}
						}



					}),
					/*
					init: Igloo.delegate(this,function(){
						var $link = this.$content.find(".js-download");
						$link.text(this.settings.title);
						$link.attr("href",this.settings.link);
						if(this.settings.target) {
							$link.attr("target",this.settings.target);
						}
					})*/
				},
			]
		}

		this.setup(settings);
	};
	RegisterForm.inheritsFrom( PopupForm );
	RegisterForm.prototype.downloadFile = function(data, fileName, type="text/plain") {
		// Create an invisible A element
		const a = document.createElement("a");
		a.style.display = "none";
		document.body.appendChild(a);

		// Set the HREF to a Blob representation of the data to be downloaded
		a.href = window.URL.createObjectURL(
			new Blob([data], { type })
		);

		// Use download attribute to set set desired file name
		a.setAttribute("download", fileName);

		// Trigger the download by simulating click
		a.click();

		// Cleanup
		window.URL.revokeObjectURL(a.href);
		document.body.removeChild(a);
	}

