
	function SignupForm(settings){
		this.className			= "SignupForm";

		this.name = "signup";

		if(!settings) settings = {};
		settings.submitAnalytics = true;

		this.data = {
			pages: [
				{
					title: "Sign up details",
					name: "details",
					submissionUrl: "/forms/signup"
				},
				{
					title: "Thanks",
					name: "complete",
					render: Igloo.delegate(this,function(){

						const v 		= this.values;
						const message	= `Signup`
						const agree 	= true;

						this.submitHubspot({
							message,
							agree,
							firstname: v['First Name'],
							lastname: v['Last Name'],
							jobtitle: v['Job title'],
							email: v['Email']
						});

					})
				},
			]
		}

		this.setup(settings);
	};
	SignupForm.inheritsFrom( PopupForm );

