
function EventTarget(){
    //this._listeners = {};
	//this.saved = [];
	//Igloo.log(this)

	EventTarget.count = EventTarget.count ? EventTarget.count+1 : 1;
	if(!EventTarget.countListeners) EventTarget.countListeners = 0;
}
EventTarget.inheritsFrom( BaseClass );	
EventTarget.prototype.addListener = function(type, listener, single){
	if(!this._listeners) this._listeners = [];
	if (typeof this._listeners[type] == "undefined"){
		this._listeners[type] = [];
	}

	//Igloo.log("AD list")
	//Igloo.log(this)

	//this.saved.push("test");

	this._listeners[type].push({func:listener,single:single});
	EventTarget.countListeners++;

	//Igloo.log("Listener count: " + (this.className?"("+this.className+")" :"") + " " + type + " " + this._listeners[type].length);
	
	return listener;
}
EventTarget.prototype.addListenerOnce = function(type, listener, single){
	this.removeListener(type,listener,single);
	return this.addListener(type,listener,single);
}
EventTarget.prototype.fire = function(event,object){
	
	if (typeof event == "string"){
		event = { type: event };
	}
	if (!event.target){
		event.target = this;
	}
	if (!event.type){  //false
		throw new Error("Event object missing 'type' property.");
	}


	event.preventDefault = function(){
		event.preventedDefault = true;
	}

	event = Igloo.merge(object,event);
	//if(event.preventDefault) event.preventDefault();
		
	if (this._listeners && this._listeners[event.type] instanceof Array){
		var listeners 	= this._listeners[event.type];
		for (var i=0, len=listeners.length; i < len; i++){
			var func = listeners[i].func;
			if(listeners[i].single){
				this.removeListener(event.type,func)
			}
			func.call(this, event);
		}
	}

	return event;
}
EventTarget.prototype.removeListener = function(type, listener){
	//if(!type || !listeners) Igloo.log("error: Didn't specify what to remove from listener")
	if (this._listeners && this._listeners[type] && this._listeners[type] instanceof Array){
		
		var listeners = this._listeners[type];
		for (var i=0, len=listeners.length; i < len; i++){
			if (!Igloo.defined(listener) || listeners[i].func === listener){
				listeners.splice(i, 1);
				EventTarget.countListeners--;
				break;
			}
		}
	}
	return this;
}
EventTarget.prototype.destroy = function(){
	this._listeners = null;
	EventTarget.count--;
	this.parent.destroy();
};