
	function Worms(options){
		this.className			= "Worms";

		this.type = options.type ? options.type : 1;
		this.$wrapper = options.$holder;

		this.sizing = options.sizing ? options.sizing : "fullscreen";

		this.valuesSetBasedOnWidth = 1900;

		this.animation = false;

		this.$canvas = this.$wrapper.html("<canvas/>").find("canvas");
		this.canvas = this.$canvas[0];
		this.ctx = this.canvas.getContext("2d");

		this.colour = options.colour ? options.colour : "#000000";
		this.lineRgb = Igloo.hexToRgb(this.colour);

		this.lineWidthOrig = 12;
		this.lineLengthOrig = 62;

		this.mousemoveDelegate = Igloo.delegate(this,function(e){
			if(!this.mouseActive) this.mouseActive = true;
			this.mouseX = e.pageX;
			this.mouseY = e.pageY;
			this.update();
		});
		$("body").on("mousemove",this.mousemoveDelegate);

		this.resizeDelegate = jstage.addListener("resize",Igloo.delegate(this,this.resize));


		this.mouseleaveDelegate = Igloo.delegate(this,function(e){
			//this.mouseActive = false;
			//this.update();
		})
		$("body").on("mouseleave",this.mouseleaveDelegate);

		/*$("body").on("mouseenter",Igloo.delegate(this,function(e){
		 this.mouseActive = true;
		 this.update();
		 }))*/

		this.interval = setInterval(Igloo.delegate(this,function(){
			this.update();
			this.render();
		}),30);


		this.refresh();

	};
	Worms.prototype.refresh = function(){



		if(this.sizing == "fit"){
			this.width =  this.$wrapper.width();
			this.height = this.$wrapper.height();
		}else{
			this.width =  jstage.getWidth();
			this.height =  jstage.getHeight();
		}

		this.$canvas.attr("width",this.width).attr("height",this.height)


		this.scale = this.width / this.valuesSetBasedOnWidth * 0.55 + 0.45;

		this.lineWidth = this.lineWidthOrig * this.scale;
		this.lineLength = this.lineLengthOrig * this.scale;

		var grid = Math.round(90 * this.scale);




		var xoffset = Math.round(( this.width - (Math.ceil(this.width / grid) - 0) * grid ) / 2); //-1 became -0 when we added an extra row
		var yoffset = Math.round(( this.height - (Math.ceil(this.height / grid) - 0) * grid ) / 2);


		var points = [];
		for (var i = 0; i < this.width + grid; i = i + grid) {
			for (var j = 0; j < this.height + grid; j = j + grid) {

				var r = grid/3;
				points.push([xoffset + i+grid/200000000+Math.random()*r-Math.random()*r,yoffset + j+grid/20000000000+Math.random()*r-Math.random()*r,0,0,0,0]);
			}
		}
		this.points = points;

		var ctx = this.ctx;
		ctx.lineWidth = this.lineWidth;
		ctx.strokeStyle = '#0ad8d4';
		//ctx.strokeStyle = 'rgba(0,0,0,0.5)';

		this.mouseX = this.width/2;
		this.mouseY = 0;

		this.mouseX = jstage.mouseX;
		this.mouseY = jstage.mouseY;


		this.update();
		this.render();
	}
	Worms.prototype.update = function(onComplete){


		var type = this.type;

		var mouse_x = (this.mouseX-this.width/2);
		var mouse_y = (this.mouseY-this.height/2);

		var magnets = [];
		if(type == 1){


			/*if(this.mouseActive){

				magnets.push({
					x: this.mouseX,
					y: this.mouseY,
					f: 0,
					t: "linear",
					n: false
				})

			}*/



			var mouse_r = 0;

			if(1) magnets.push({
				x: this.width/2 + mouse_x*mouse_r,
				y: this.height/2 + mouse_y*mouse_r,
				f: 10,
				t: "linear",
				n: false
			})

			var ff = 2;

			magnets.push({
				x: this.width/2,
				y: this.height*2,
				f: Math.max(0,(this.mouseY-this.height/2)/75/ff),
				t: "flat",
				n: false
			})

			magnets.push({
				x: this.width/2,
				y: -this.height,
				f: Math.max(0,(this.height/2-this.mouseY)/75/ff),
				t: "flat",
				n: false
			})

			magnets.push({
				x: this.width*2,
				y: this.height/2,
				f: Math.max(0,(this.mouseX-this.width/2)/100/ff),
				t: "flat",
				n: false
			})

			magnets.push({
				x: -this.width,
				y: this.height/2,
				f: Math.max(0,(this.width/2-this.mouseX)/100/ff),
				t: "flat",
				n: false
			})

		}

		if(type == 2){

			if(!this.randoms) this.randoms = [];


			magnets.push({
				x: this.mouseX + 100,//this.width/4,
				y: this.mouseY + 60,//this.height/4,
				f: 1,
				t: "square"
			})


			magnets.push({
				x: this.width * 2,
				y: this.height * 2,
				f: -2,
				t: "flat",
				n: false
			})


			var tt = 0;
			for (var i = 0; i < tt; i++) {
				var mouse_r = 0;
				if(!this.randoms[i]) this.randoms.push([Math.random(),Math.random()]);
				magnets.push({
					x: this.randoms[i][0]*this.width + mouse_x*mouse_r,
					y: this.randoms[i][1]*this.height + mouse_x*mouse_r,
					f: -0.4,
					t: "square"
				})

			}

		}





/*
		var tt = 4;
		for (var i = 0; i < tt; i++) {
			magnets.push({
				x: Math.random()*this.width,
				y: Math.random()*this.height,
				f: -0.4,
				t: "square"
			})

		}
		*/




		for (var i = 0; i < this.points.length; i++) {

			//if(i > 0) continue;

			var p = this.points[i];


			var x = 0.000001; //Ensures they don't break!
			var y = 0;

			var nx = 0;
			var ny = 0;
			for (var j = 0; j < magnets.length; j++) {
				var magnet = magnets[j];
				var a = magnet.x - p[0];
				var b = magnet.y - p[1];
				var c = Math.sqrt(a*a + b*b);

				var s;
				if(magnet.t = "flat") s = magnet.f
				if(magnet.t = "linear") s = magnet.f*1000 / Math.sqrt(c);
				if(magnet.t = "square") s = magnet.f*1000 / Math.sqrt(Math.sqrt(c));

				if(!magnet.n){
					x += a/c * s;
					y += b/c * s;
				}


				if(magnet.n){
					nx += a/c * s;
					ny += b/c * s;
				}
			}

			var d = Math.atan(-x/y) + Math.PI / 2;
			if(y < 0) d += Math.PI;

			var a = p[3];

			if(d > a + Math.PI) d -= Math.PI*2;
			if(d < a - Math.PI) d += Math.PI*2;

			p[2] = d;

			p[4] = nx;
			p[5] = ny;

		}

		if(!this.animation) this.render();

	}
	Worms.prototype.render = function(onComplete){
		var ctx = this.ctx;
		ctx.clearRect(0, 0, this.width, this.height);


		var t = this.animation ? this.animation : 999;

		for (var i = 0; i < this.points.length; i++) {
			//if(i > 0) continue;

			var p = this.points[i];

			var p0 = p[0];
			var p1 = p[1];


			var d = p[2];
			var a = p[3];

			if(d < a){
				a = Math.max(d,a-t);
				p[3] = a;
			}else if(d > a){
				a = Math.min(d,a+t);
				p[3] = a;
			}

			var x = Math.cos(a);
			var y = Math.sin(a);

			var z = 1;
			//var z = Math.sqrt(x*x + y*y);
			//Igloo.log(z)
			var r = this.lineLength;///z;
			var n = 0.1/z;

			p0 += p[4]*n;
			p1 += p[5]*n;

			var x1 = (x * r);
			var y1 = (y * r);

			var cx = Math.abs(this.width/2 - p0)/10;
			var cy = Math.abs(this.height/2 - p1)/10;
			var cz = Math.sqrt(cx*cx + cy*cy);




			if(this.type == 1) {



				var f1 = 20 * this.scale;
				var f2 = 50 * this.scale;

				ctx.strokeStyle = 'rgba('+this.lineRgb.r+','+this.lineRgb.g+','+this.lineRgb.b+',' + Math.max(0, (cz - f1) / f2) + ')';
			}

			if(this.type == 2) {

				var cx = Math.abs(p0 - this.mouseX)/10;
				var cy = Math.abs(p1 - this.mouseY)/10;
				var cz = Math.sqrt(cx*cx + cy*cy);

				var f1 = 15 * this.scale;
				var f2 = 100 * this.scale;

				ctx.strokeStyle = 'rgba('+this.lineRgb.r+','+this.lineRgb.g+','+this.lineRgb.b+',' + Math.max(0, (cz - f1) / f2) + ')';

			}

			ctx.beginPath();

			if(this.type == 1){
				var k = 4;
				ctx.moveTo(p0 - x1/k,p1 - y1/k);
				//ctx.moveTo(p[0],p[1]);
				ctx.lineTo(p0 + x1 * (k-1)/(k), p1 + y1*(k-1)/(k));

			}
			if(this.type == 2){
				//var k = 1;
				//ctx.moveTo(p0 - x1/k,p1 - y1/k);
				//ctx.lineTo(p0 + x1 * (k-1)/(k), p1 + y1*(k-1)/(k));

				var k = 1;
				ctx.moveTo(p0 - x1/k,p1 - y1/k);
				ctx.lineTo(p0 + x1 * (k-1)/(k), p1 + y1*(k-1)/(k));

			}
			ctx.stroke();


		}



	}
	Worms.prototype.resize = function(){

		this.refresh();

	}
	Worms.prototype.destroy = function(){
		//
		this.$wrapper.html("");
		clearInterval(this.interval);
		$("body").off("mousemove",this.mousemoveDelegate);
		$("body").off("mouseleave",this.mouseleaveDelegate);
		if(this.resizeDelegate) jstage.removeListener("resize",this.resizeDelegate);

	}

