
function Jnav(_settings){

	this.status				= null;
	this.callStack			= null;

	this.currentUrl			= null;
	this.currentParams		= {};
	this.currentHash		= null;

	this.nextUrl			= null;
	this.nextParams			= {};
	this.nextHash			= null;


};
Jnav.inheritsFrom( EventTarget );

//Initialise
Jnav.prototype.setup = function(base, links){


	var _this = this;

	//Defaults
	this.status			= "ready";
	this.callStack		= [];
	if(!base) base 		= "/";
	this.currentUrl		= null;//this.cleanUrl(History.getState().cleanUrl);

	//Target links
//		if(links) this.addToLinks(links);

	//Setup history.js

	window.addEventListener('popstate',function(e){
		_this.checkChange();
	})

	window.addEventListener('hashchange',function(){
		_this.checkChange();
	});

	/*History.Adapter.bind(window,'statechange',function(e){

	 var r = _this.checkChange();
	 if(!r) _this.onChangeFailed();
	 });
	 History.Adapter.bind(window,'anchorchange',function(){
	 History.saveState(History.createStateObject()); //Anchor links don't update as usual so we need to force it
	 _this.checkChange(true);
	 });*/

	setTimeout(Igloo.delegate(this,this.checkChange),1);
}

//*** PUBLIC METHODS **//
Jnav.prototype.change = function(url, force){

	url = this.cleanUrl(url);
	if(force !== true && this.preChangeCheck){
		var r = this.preChangeCheck(url,"change");
		if(!r) return false;
	}

	window.history.pushState({rd: window.Math.random()}, document.title, url);

	this.checkChange();
	return true;
}
Jnav.prototype.replace = function(url, force){

	if(force !== true && this.preChangeCheck){
		var r = this.preChangeCheck(url,"replace");
		if(!r) return false;
	}

	url = this.cleanUrl(url);
	window.history.replaceState({rd: window.Math.random()}, document.title, url);
	this.checkChange();

}
Jnav.prototype.refresh = function(){
	this.checkChange(true);
}
Jnav.prototype.changeUri = function (uri, options) {
	var base = options.base ? options.base : "";

	var full = base + uri;
	if(full.substr(0,1) == "/") full = full.substr(1);
	var parts = full.split("/");

	options.trim = true;

	return this.changeUriParts(parts,options);

}
Jnav.prototype.changeUriParts = function (parts, options) {

	if(!options) options = {};

	var historyType = options.type ? options.type : "push",
		state = options.state ? options.state : {},
		title = options.title ? options.title : "",
		trim  = options.trim ? options.trim : false;

	var currentParts = this.parts();

	for (var i in parts) {
		var part = parts[i];
		var index = i*1;
		currentParts[index] = part;
	}

	if(trim){
		var max = index;
		currentParts.splice(max+1);
	}
	if(options.folder && currentParts[currentParts.length-1] !== "") currentParts.push("");

	var uri = "/" + currentParts.join("/");

	window.history[historyType + "State"](state, title, uri + document.location.search);
	this.checkChange();


}
Jnav.prototype.changeQuery = function (key, value, options) {

	var baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
		q = document.location.search,
		changes = {},
		aType = typeof(key),
		clear = false;

	if (aType == "string") {
		changes[key] = value;
	} else if (aType == "object") {
		changes = key;
		options = value;
	} else if (key === false) {
		clear = true;
	}

	//Options
	if (!options) options = {};
	var historyType = options.type ? options.type : "push",
		state = options.state ? options.state : {},
		title = options.title ? options.title : "";


	if (clear) {
		q = "";
	} else {
		var escapeRegExp = function (string) {
			return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
		};
		for (var key in changes) {
			var value = changes[key];
			var newParam = key + '=' + encodeURIComponent(value);
			var updateRegex = new RegExp('([\?&])' + escapeRegExp(key) + '[^&]*');
			var removeRegex = new RegExp('([\?&])' + escapeRegExp(key) + '=[^&;]+[&;]?');

			if (typeof value == 'undefined' || value == null || value == '') { // Remove param if value is empty
				q = q.replace(removeRegex, "$1");
				q = q.replace(/[&;]$/, "");
			} else if (q.match(updateRegex) !== null) { // If param exists already, update it
				q = q.replace(updateRegex, "$1" + newParam);
			} else { // Otherwise, add it to end of query string
				var join = q ? "&" : "?";
				q = q + join + newParam;
			}

		}
	}


	if (q == "?") q = "";
	window.history[historyType + "State"](state, title, baseUrl + q);
	this.checkChange();

	return !!q; //Return true if there's a query string, false otherwise
}

Jnav.prototype.update = function(){
	this.changed();
}
Jnav.prototype.getState = function(){
	var state = Igloo.copy(this.callStack[this.callStack.length - 1]);
	state.status = this.status;
	return state;

}
Jnav.prototype.part = function(x){
	var state = this.getState();
	return state.nextParts ? state.nextParts[x] : state.previousParts[x];
}
Jnav.prototype.parts = function(){
	var state = this.getState();
	return state.nextParts ? state.nextParts : state.previousParts;
}
Jnav.prototype.getUrl = function(){
	var state = this.getState();
	return state.nextUrl ? state.nextUrl : state.previousUrl;
}
//*** BASE METHODS ***//
Jnav.prototype.checkChange = function(force){

	//What about interuptions?



	if(this.status != "ready"){
		var interupt = this.onInterupt();
		if(force) this.forceWaiting = true;
		if(!interupt) return false;
	}

	this.forceWaiting 	= false;



	//var state			= History.getState();
	var url				= this.cleanUrl(document.location.href);
	var previousUrl		= this.currentUrl;
	var documentUrl		= this.getDocumentUrl();

	var nextParams		= this.getParams(url);
	var previousParams	= this.currentParams;

	var nextHash		= this.getHash(url);
	var previousHash	= this.currentHash;

	if(url === previousUrl && !force ){ //
		return false;
	}

	//Check if our body loaded a different page from our # url
	if(!previousUrl && documentUrl != url){
		//url = documentUrl;
	}

	var parts 			= this.getParts(url);
	var previousParts	= this.getParts(previousUrl);
	var changes			= this.findChanges(previousUrl,url);

	this.nextUrl		= url;
	this.nextParams		= nextParams;
	this.nextHash		= nextHash;

	var nextUriSimple	= url.split("?")[0];
	var previousUriSimple	= previousUrl ? previousUrl.split("?")[0] : null;

	var details			= {
		'nextUrl' 			: url,
		'nextParts' 		: parts,
		'nextParams'		: nextParams,
		'nextHash'			: nextHash,
		'nextUriSimple'		: nextUriSimple,
		'previousUrl' 		: previousUrl,
		'previousParts' 	: previousParts,
		'previousParams'	: previousParams,
		'previousHash'		: previousHash,
		'previousUriSimple'	: previousUriSimple,
		'changes'			: changes,
		'interupt'			: interupt
	}
	if(previousUrl === null) details.initial = true;

	this.callStack.push(details);
	this.status = "waiting";
	var onChangeResult = this.onChange(details);
	this.fire("change",{state:details})

	if(onChangeResult === true){
		this.changed();
	}

	return true;//(onChangeResult === true);

}
Jnav.prototype.changed = function(details){

	this.status = "ready";
	this.currentUrl 	= this.nextUrl;
	this.currentParams	= this.nextParams;
	this.currentHash	= this.nextHash;
	this.nextUrl		= null;
	this.nextParams		= null;
	this.nextHash		= null;

	this.onChanged();
	this.checkChange(this.forceWaiting);

}

//*** OVERLOADABLE METHODS ***//
Jnav.prototype.preChangeCheck = function(url,type){
	return true;
}
Jnav.prototype.onChange = function(details){
	//Igloo.log("On Change")
	//Igloo.log(details)
	//setTimeout(Igloo.delegate(this,this.update),5000);
	return true;
}
Jnav.prototype.onChanged = function(){
	//Igloo.log("On Changed")
	//Igloo.log(this.getState());
	return true;
}
Jnav.prototype.onChangeFailed = function() {
	//Igloo.log("On Change failed")
	//Igloo.log(this.getState());
	return false;
}
Jnav.prototype.onInterupt = function(){
	//Igloo.log("On Changed")
	//Igloo.log(this.getState());
	return false;
}

//*** HELPER METHODS **//
Jnav.prototype.cleanUrl = function(url){
	//Take a Url and output a nice version, starting with a /, or false if it's not on this domain.
	if( typeof(url) !== "string" ){
		Igloo.warn("JNav cleanUrl given non-string");
		throw("VGV")
		return "";
	}

	var location	= document.location;
	var real_base	= location.protocol + "//" + location.hostname + (location.port?(":"+location.port):"");

	//Fix the no http or https problem
	if(url.substr(0,2) == "//") url = location.protocol + url;

	//What if it's just the #
	if( url.substr(0,1) == "#"){
		var state 		= this.getState();
		url = state.nextUrl.split("#")[0] + url;
	}

	var parts		= url.split("://");

	//Is it a full url? Check if it's on the same domain
	if(parts.length > 1){
		if( real_base != url.substr(0,real_base.length) ) 	return false;
		else												url = url.substr(real_base.length);
		if(url == "") url = "/";
	}

	//What if it doesn't start with a /. Then we need to use the current location to work it out.
	if( url.substr(0,1) != "/" ){
		if( url.substr(0,3) == "../" ) {
			var base = this.sliceUrl(this.getBaseUrl(),-2);
			url = base + url.substr(3);
		}else{
			url = this.getBaseUrl() + url;
		}
	}
	return url;
}
Jnav.cleanUrl = Jnav.prototype.cleanUrl; //Static
Jnav.prototype.getUrl = function(){
	return Jnav.cleanUrl(document.location.href);
}
Jnav.getUrl = Jnav.prototype.getUrl; //Static
Jnav.prototype.getDocumentUrl = function(){ //On first load of IE 6/7/8 you get the base page not the # page.
	return Jnav.cleanUrl(document.location.pathname);
}
Jnav.getDocumentUrl = Jnav.prototype.getDocumentUrl; //Static
Jnav.prototype.getBaseUrl = function(){
	var url 		= document.location.href;
	//var path 		= state.hash;

	//if(path === "./")
	var path = this.cleanUrl(url);

	var path_parts 	= path.split("/");
	path_parts.pop();
	var base_path 	= path_parts.join("/") + "/";

	return base_path;
}
Jnav.getBaseUrl = Jnav.prototype.getBaseUrl; //Static
Jnav.prototype.getParams = function(url){

	if( typeof(url) !== "string" ){
		Igloo.warn("JNav getParams not given a string");
		return {};
	}

	var params 			= {};

	var url_parts_1		= url.split("#");
	var url_parts_2		= url_parts_1[0].split("?");
	var param_string	= url_parts_2[1] ? url_parts_2[1] : "";
	var param_parts		= param_string.split("&");
	for(var i = 0; i < param_parts.length; i++){
		var inner_parts = param_parts[i].split("=");
		var key			= decodeURI(inner_parts[0]);
		var value		= decodeURI(inner_parts[1]);

		if(key)	params[key]	= value;
	}

	return params;
}
Jnav.prototype.getHash = function(url){

	if( typeof(url) !== "string" ){
		Igloo.warn("JNav getHash not given a string");
		return {};
	}
	var url_parts		= url.split("#");
	return url_parts.length > 1 ?  url_parts[1] : "";

}
Jnav.prototype.getParts = function(url){
	if(!Igloo.defined(url)) url = this.cleanUrl(this.getUrl());
	if(typeof(url) !== "string") return [];
	url = this.cleanUrl(url);
	url = url.split("?")[0];
	url = url.split("#")[0];
	var parts = url.split("/");
	parts.splice(0,1);
	if(parts[parts.length-1] === "") parts.pop();
	return parts;
}
Jnav.getParts = Jnav.prototype.getParts; //Static
Jnav.prototype.findChanges = function(previousUrl,currentUrl){

	if(typeof(currentUrl) == "undefined") currentUrl = this.getUrl();

	var previous 	= this.getParts(previousUrl);
	var current 	= this.getParts(currentUrl);

	var changes = [];
	for(var i = 0 ; i < Math.max(previous.length,current.length); i++){
		var a = i < previous.length ? previous[i] : "";
		var b = i < current.length ? current[i] : "";
		changes[i] = a != b;
	}
	return changes;
}
Jnav.findChanges = Jnav.prototype.findChanges; //Static
Jnav.prototype.sliceUrl = function(url, length){

	var parts = Jnav.getParts(url);
	if(length < 0) length = parts.length + length;
	var newUrl = "/";//Jnav.getBaseUrl();
	for(var i = 0; i < Math.min(parts.length,length+1); i++){
		newUrl += parts[i] + "/";
	}
	return newUrl;
}
Jnav.sliceUrl = Jnav.prototype.sliceUrl; //Static
Jnav.prototype.sectionUrl = function(length){
	return Jnav.sliceUrl(this.getState().nextUrl,length);
}
Jnav.sectionUrl = Jnav.prototype.sectionUrl; //Static
//*** HELPER METHODS (HTML) **//
Jnav.prototype.addToLinks = function($elements, addTouch){
	//If elements is not set

	if($elements === true)	return this.addToLink($("a"), addTouch);
	else					return this.addToLink($elements, addTouch);

}
Jnav.prototype.addToLink = function($elements, addTouch){

	var _this = this;
	$elements.each(function(i){

		var $element	= $(this);

		var href		= $element.attr("href");
		if(!href) 		return;

		var target		= $element.attr("target");
		if(target)	 	return;

		var ignore		= $element.data("ignore") || $element.hasClass("no_auto_link") || $element.hasClass("no-auto-link");
		if(ignore) 		return;

		var ext 		= href.lastIndexOf(".") >= 0 ? href.substr(href.lastIndexOf(".")) : "";





		if(href.substr(0,1) == "#") return;
		if(ext)			return;

		var parts		= href.split(":");
		var location	= document.location;
		var real_base	= location.protocol + "//" + location.hostname + "/";

		//Fix the no http or https problem
		if(href.substr(0,2) == "//") href = location.protocol + href;

		if(parts.length > 1 && real_base != href.substr(0,real_base.length)) return;


		_this.addOnClick($element, addTouch);

	});
}
Jnav.prototype.removeFromLinks = function($elements){
	//If elements is not set
	if($elements === true)	return this.removeFromLink($("a"));
	else					return this.removeFromLink($elements);

}
Jnav.prototype.removeFromLink = function($elements){

	var _this = this;
	$elements.each(function(i){

		var $element	= $(this);
		if($element.data("jnav")) $element.off("click",$element.data("jnav"));
		$element.data("jnav",false);

	});
}
Jnav.prototype.addOnClick = function($element, addTouch){
	//if(!Igloo.definied(addTouch)) addTouch = true;
	if(!addTouch) if($element.hasClass("js-touch")) addTouch = true;
	if(!addTouch) if($element.parents(".js-touch").length) addTouch = true;

	if($element.data("jnav")) $element.off("click touchstart",$element.data("jnav"));
	var f = Igloo.delegate(this,this.onClick,$element);
	$element.data("jnav",f);
	$element.addClass("auto-link")
	var eventString = "click";

	if(addTouch) eventString += " touchstart";
	$element.on(eventString,f);
}
Jnav.prototype.onClick = function( $this, e ){

	//This only works on an <a> - returning false doesn't work on any other element
	if(e.ctrlKey === true){
		e.stopPropagation();
		return true;
	}

	e.preventDefault();
	e.stopPropagation();
	this.change($this.attr("href"));
};