
	function PopupForm(options){
		this.className			= "PopupForm";

		this.name				= "";
		this.templateBase 		= "/forms/"

		this.templates = {};

		this.values = {};

		this.reset()

	};
	PopupForm.prototype.setup = function(settings){

		if(!settings) settings = {};

		this.reset()

		this.settings = settings;

		this.$body = $("body");
		this.$htmlBody = $("html, body");

		if(settings.values) this.values = settings.values;
		this.$overlay = settings.$overlay ? settings.$overlay : $(".js-popup");
		this.$content = this.$overlay.find(".js-content");
		this.$window = this.$overlay.find(".js-window");

		this.$close = this.$overlay.find(".js-close");
		this.$close.on("click",Igloo.$d(this,function($this){
			this.cancel();
		}));
		this.$overlay.find(".js-submit").on("click",Igloo.$d(this,this.submit));
		this.$overlay.find(".js-back").on("click",Igloo.$d(this,this.previousPage));

		if(settings.data){
			this.data = settings.data;
		}

		if(settings.analytics){
			var category = settings.analytics.category;
			var label = settings.analytics.label;
			if(category && label) this.sendAnalytics(category, label);
		}

		if(settings.submitAnalytics === true) settings.submitAnalytics = {};

		this.currentPage = 0;
		this.render().then(Igloo.delegate(this,function(){

			if(this.$overlay.hasClass('h-auto')){
				this.$window.css('height','auto');
				var windowHeight = this.$window.height();
				this.$window.css('height',windowHeight + "px");
			}

			//Preload other pages
			for (var i = 1; i < this.data.pages.length; i++) {
				var page = this.data.pages[i];
				this.loadTemplate(page)
			}

		}));

	}

	PopupForm.prototype.sendAnalytics = function(category, label, action){
		if(typeof(ga) === "undefined") return false;
		if(!action) action = "click";
		Igloo.log("sending analytics");
		ga('send', 'event', {
			eventCategory: category,
			eventAction: action,
			eventLabel: label
		});
	}

	PopupForm.prototype.open = function(){
		this.savedScrollPosition = jstage.getScroll();
		this.$body.addClass("fixed");
		this.$overlay.show();
	}
	PopupForm.prototype.close = function(){
		this.$body.removeClass("fixed");
		this.$overlay.hide();
		this.$htmlBody.scrollTop(this.savedScrollPosition);
	}
	PopupForm.prototype.nextPage = function(cancelOnComplete){

		if(this.currentPage < this.data.pages.length -1){
			this.currentPage++;
			this.render();
		}else if(cancelOnComplete){
			this.cancel();
		}

	}
	PopupForm.prototype.previousPage = function(){
		if(this.currentPage > 0){
			this.currentPage--;
			this.render();
		}
	}


	PopupForm.prototype.render = function(){

		this.hidePage();

		this.page = this.data.pages[this.currentPage];

		this.page.status = "loading";

		return this.loadTemplate(this.page).then(Igloo.delegate(this,function(template){
			this.page.template = template;
			this.page.status = "loaded"
			this.$content.html(template.html);
			this.page.status = "visible"
			this.initPage();
		}));

	}
	PopupForm.prototype.hidePage = function(){
		if(!this.page) return;

		this.page.status = "off";
		if(this.page.destroy) this.page.destroy();
		this.$content.html("");
	}
	PopupForm.prototype.loadTemplate = function(page){

		var filename 	= page.name;
		var post 		= page.post;

		var uri = this.templateBase + this.name + "/" + filename;

		var template = this.templates[uri];

		if(template && !post){
			return template.promise
		}else{
			var template = {};

			var dfd = jQuery.Deferred();
			var promise = dfd.promise();

			template.uri = uri;
			template.ajax = $.ajax({
				url: uri//,
				//method: post && Object.keys(this.values).length  ? "post" : "get",
				//data: this.values
			}).then(function(html,status,e){
				template.html = html;
				template.status = "loaded";
				dfd.resolve(template);
			},function(e){
				template.html = "error";
				template.status = "error";
				dfd.resolve(template);
			})
			template.promise = promise;
			this.templates[uri] = template;

			return template.promise;
		}


	}
	PopupForm.prototype.initPage = function(e){

		var p = this.page;

		this.page.$submit 	= this.$content.find(".js-submit").on("click",Igloo.$d(this,this.submit))
		this.page.$close 	= this.$content.find(".js-close").on("click",Igloo.$d(this,this.cancel));
		this.page.$back 	= this.$content.find(".js-back").on("click",Igloo.$d(this,this.previousPage));
		this.page.$errors 	= this.$content.find(".js-errors");

		if(this.page.onRender){
			$template = this.$content;
			this.page.onRender($template);
		}

		var updateGeneric = Igloo.delegate(this,function(i){

			i.empty = !i.value || i.value == "";

			var validationFunction = i.validation ? this[i.validation + "Validation"] : false;
			var validObject = validationFunction ? validationFunction(i.value,{required: i.required}) : (!i.required || !i.empty);
			if(validObject === true || validObject === false) validObject = {valid: validObject};

			i.valid = validObject.valid;

			i.error = !!i.edited && !i.valid;
			i.errorMessage = i.valid.msg;
			i.$wrapper.toggleClass( "valid", i.valid );
			i.$wrapper.toggleClass( "error", i.error );
			i.$wrapper.toggleClass( "focus", i.focus );

		});



		var $inputs = this.$content.find("input, select, textarea, .js-buttons");


		var inputs = [];
		$inputs.each(Igloo.$delegate(this,function($this){
			var $input = $this;
			var type = $input.hasClass("js-buttons") ? "buttons" : "input";

			var $wrapper = $input.parents(".js-form-item");
			if(!$wrapper.length) $wrapper = $input;

			var i = {
				$input: $input,
				$wrapper: $wrapper,
				name: $input.attr("name"),
				required: $input.hasClass("required") || $wrapper.hasClass("required"),
				edited: false,
				focus: false,
				value: "",
				type: type,
				inputType: $input.attr("type") ? $input.attr("type") : "text"
			}


			if(type == "input") {

				if($input.hasClass("email") || $wrapper.hasClass("email")){

					i.validation = "email"
				}

				i.update = function(forceChange){

					if(!i.edited && (forceChange)) i.edited = true; //i.value !== $input.val() ||

					i.value = $input.val();
					i.focus = $input.is(":focus");


					updateGeneric(i);

				}

				i.set = function(value){
					i.$input.val(value);
					i.update();
				}

				$input.on("input focus",Igloo.delegate(this,function(e){
					i.update();
				}));
				$input.on("blur",Igloo.delegate(this,function(e){
					i.update(true);
				}));

			}

			if(type == "buttons"){

				i.multiple = $input.hasClass("multiple");

				var $buttons = $input.find("a[name]");
				i.$buttons = $buttons;
				i.update = function(edited) {
					var $selected = $buttons.filter(".selected");
					i.value = $selected.length ? $selected.map(function(){ return $(this).attr("name")}).get().join("; ") : "";
					if (edited) i.edited = true;
					updateGeneric(i);
				}

				i.set = Igloo.delegate(this,function(value, options){

					//Check to reload the buttons in case they changed
					var $buttons = $input.find("a[name]");
					i.$buttons = $buttons;


					if(!options) options = {};
					var manual = options.manual;
					var state = Igloo.defined(options.state) ? options.state : true;

					if(!i.multiple) $buttons.removeClass("selected").removeClass("selected");

					var $button = $buttons.filter("[name='"+value+"']");

					var isSelected = $button.hasClass("selected");
					if(state === true || (state == "toggle" && !isSelected)) $button.addClass("selected").addClass("selected");
					if(state === false || (state == "toggle" && isSelected)) $button.removeClass("selected").addClass("ignore-hover").removeClass("selected").addClass("ignore-hover");


					i.update();
					if(manual && this.page.instant){
						setTimeout(Igloo.delegate(this,function(){
							if(this.checkPage(false)){
								this.submit();
							}
						}),Igloo.isiOS() ? 100 : 0);

					}
				})


				if(Igloo.isiOS()) $buttons.addClass("ignore-hover").addClass("ignore-hover");

				$buttons.on("click",Igloo.$d(this,function($this,e){

					i.set($this.attr("name"), {manual: true, state: "toggle"});

				}));


				$buttons.on("mouseover",Igloo.$d(this,function($this){
					$this.addClass("over").removeClass("ignore-hover").addClass("over").removeClass("ignore-hover");
				}));
				$buttons.on("mouseout",Igloo.$d(this,function($this){
					$this.removeClass("over").removeClass("ignore-hover").removeClass("over").removeClass("ignore-hover");
				}));


			}


			i.update();
			inputs.push(i);

		}))



		this.page.inputs = inputs;

		this.loadDefaults();

		if(this.page.init) this.page.init();

		this.open();

		if(this.page.render) this.page.render();
	}
	PopupForm.prototype.loadDefaults = function(){
		//Load defaults / already set values
		if(!this.page || !this.page.inputs) return;
		for (var i = 0; i < this.page.inputs.length; i++) {
			var input = this.page.inputs[i];
			var value = this.values[input.name];
			if(input.inputType = "hidden" && input.name == "URL") value = nav.getState().nextUrl;
			if(value){
				input.set(value); //We want to trigger moving forwards
			}
		}
		if(this.page.instant && this.checkPage(false)) this.checkPage(true);
	}
	PopupForm.prototype.emailValidation = function(value, settings){

		if(!settings) settings = {};


		var checkEmail = function(value){
			var re = /^([\w-]+(?:[\w-.+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
			return re.test(value);
		}

		var valid = checkEmail(value);

		if(!settings.required && !value) return true;
		else if(valid) return true;
		else return {valid: false, msg: "Email is not valid"}

	}
	PopupForm.prototype.submit = function(e){


		if(this.page.submit) this.page.submit();

		this.checkPage(true);
	}
	PopupForm.prototype.checkPage = function(hard){



		var errors = [];

		for(var i in this.page.inputs){

			var input = this.page.inputs[i];
			input.update(hard);
			var value = input.value;
			if(!input.valid) errors.push({
				input: input,
				msg: input.errorMessage
			})
		}

		if(!hard) return !errors.length;


		if(errors.length){
			var messages = "";
			for (var i = 0; i < errors.length; i++) {
				var e = errors[i];
				if(e.msg) messages += e.msg + "<br/>"
			}
			this.showError(messages);
		}else{

			for(var i in this.page.inputs){
				var input = this.page.inputs[i];
				this.values[input.name] = input.value;
			}

			this.continue();
		}

	}
	PopupForm.prototype.showError = function(msg){

		this.page.$errors.show();

		var $general = this.page.$errors.find(".js-general");
		if(!$general.length) $general = this.page.$errors.filter(".js-general");
		if(!$general.length) $general = this.page.$errors;

		$general.text("There are errors. Please check before continuing.")

		if(msg) this.page.$errors.find(".js-specific").text(msg)
	}
	PopupForm.prototype.hideError = function(){
		this.page.$errors.hide();
		$(this.page.$errors.find(".js-general"),this.page.$errors.filter(".js-general")).text("")
		this.page.$errors.find(".js-specific").text("")
	}
	PopupForm.prototype.continue = function(){
		if(this.page.submissionUrl){
			//Submit and check

			this.send();
			this.hidePage();


		}else{
			this.nextPage(true)
		}

	}
	PopupForm.prototype.send = function(){


		if(this.submitting) return false;
		this.page.$submit.addClass("disabled");

		this.submitting = true;
		$.ajax({
			url: this.page.submissionUrl,
			data: this.values,
			method: "post",
			type: "json"
		}).then(Igloo.delegate(this,function(r){

			this.submitting = false;
			this.page.$submit.removeClass("disabled");

			if(r.success){
				this.submitted = true;
				this.nextPage(true);
				if(this.settings.submitAnalytics) this.sendAnalytics(
					this.settings.submitAnalytics.category ? this.settings.submitAnalytics.category : "Forms",
					this.settings.submitAnalytics.label ? this.settings.submitAnalytics.label : this.name,
					this.settings.submitAnalytics.action ? this.settings.submitAnalytics.action : "send"
				);
			}else{
				this.submitting = false;
				this.page.$submit.removeClass("disabled");
				this.error();
				this.render(); //redraws page
			}


		}),Igloo.delegate(this,function(){
			this.submitting = false;
			this.page.$submit.removeClass("disabled");
			this.error();
		}));

	}
	PopupForm.prototype.error = function(){
		alert("There was a problem submitting your form.")
	}
	PopupForm.prototype.reset = function(){
		this.values = {};
		this.currentPage = 0;
		this.page = false;
		this.submitted = false;
	}
	PopupForm.prototype.resize = function(){

		if(this.page && this.page.resize) this.page.resize();

	}
	PopupForm.prototype.cancel = function(e){
		this.hidePage();
		this.close();
		this.destroy();
	}
	PopupForm.prototype.destroy = function(){
		this.reset();
	}

	PopupForm.prototype.submitHubspot = function(data) {
		return PopupForm.submitHubspot(data);
	}
	PopupForm.submitHubspot = function(data = {}) {


		//send to hubspot
		const hutk 		= Igloo.getCookie("hubspotutk");
		const portalId 	= hubspotAnalyticsId;

		if(!hutk || !portalId || !_hsq) return Promise.resolve(); // _hsq is not defined if they haven't accepted cookies

		let formId 	= "d3ebd84b-18fe-49f8-897e-4df6b3a8d206"; //found on the hubspot form
		if(data.trackingOnly) formId = "791844c8-609c-4743-9b19-0dba3e30b317";
		if(data.formId) formId = data.formId;

		const url 		= `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`

		// best guess if we only collected name and not first/last name
		if(data.name){
			const parts 	= data.name.split(" ");
			const last 		= parts.length > 1 ? parts.pop() : "";
			data.firstname 	= parts.join(" ");
			data.lastname 	= last;
		}

		// Identify them - probably not required but doesn't hurt
		if(data.email) _hsq.push(["identify", { email: data.email }]);

		const fields = {
			firstname: 	"firstname",
			lastname: 	"lastname",
			email:	 	"email",
			enquiry: 	"enquiry_form",
			message: 	"message",
			jobtitle:	"jobtitle",
			company:	"company",
			phone:		"phone",
			quiz:		"quiz",
		}

		const submissionFields = [];
		for(let key in fields){
			const submissionKey = fields[key];
			if(key in data && typeof(data[key]) !== "undefined"){
				const submissionValue = data[key];
				submissionFields.push({
					"objectTypeId": "0-1",
					"name": 		submissionKey,
					"value": 		submissionValue
				})
			}

		}

		const submissionData = {
			"fields": submissionFields,
			"context": {
				"hutk": 	hutk,
				"pageUri": 	document.URL,
				"pageName": document.title
			}
		}
		if(data.agree){
			submissionData.legalConsentOptions = {
				"consent": { // Include this object when GDPR options are enabled
					"consentToProcess": true,
						"text": "I agree to allow Verco to store and process my personal data.",
						"communications": [
						{
							"value": true,
							"subscriptionTypeId": 999,
							"text": "I agree to receive marketing communications from Verco."
						}
					]
				}
			}
		}

		const response = fetch(url, {
				method: 'POST',
				//mode: 'no-ccors', // no-cors, *cors, same-origin
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(submissionData)
			}).then(e => e.json()).then(e => {console.log(e);})

		return response;
	}